import React from 'react';
import Footer from '../../../Footer/Footer';
import IMAGES from '../../../Images/';
import Navbar from '../../../Navbar/Navbar';
import { NavLink } from 'react-router-dom';

export const Vm_proximity = () => {
  return (
    <>
      <Navbar />
      <section className="template">
        <div className="template-container container">
          <h1>Proximity Palcement Group</h1>
          <h2>Accelerated Networking</h2>

          <img src={IMAGES.AWS468} alt="" />
          <h2>How to Setup</h2>
          <p>Search for Proximity Palcement Group...</p>
          <img src={IMAGES.AWS469} alt="" />
          <p>Click on create proximity placement group.</p>
          <img src={IMAGES.AWS470} alt="" />
          <p>Name your group.</p>
          <img src={IMAGES.AWS471} alt="" />
          <p>Review + Create</p>
          <img src={IMAGES.AWS472} alt="" />
          <p>Your deployment is complete.</p>
          <img src={IMAGES.AWS473} alt="" />
          <img src={IMAGES.AWS474} alt="" />
          <h2>Add Virtual Machine</h2>
          <p>
            We have to create a new VM and add it to our group. We have already
            covered many times before how to create VM, so in this case we will
            just show you the option.
          </p>
          <img src={IMAGES.AWS475} alt="" />
          <p>Under advanced tab</p>
          <img src={IMAGES.AWS476} alt="" />
          <p>
            Select your proximity placement group. <br />
            Note : not all Vms support proximity placement group.
          </p>
          <img src={IMAGES.AWS477} alt="" />
          <h2>Add Proximity Placement Group to Existing VM</h2>
          <p>
            You can add proximity placement group to already created vm. First
            we have to de-allocate the vm, from the configurations menu, select
            your proximity.
          </p>
          <img src={IMAGES.AWSE477} alt="" />
          <p>
            Proximity placement group and vm must be in the same region, for
            example uae north.
          </p>
        </div>
        <div className="template-buttons container">
          <NavLink to="/vm_snapshot">
            <button className="template-back">Back</button>
          </NavLink>
          <NavLink to="/vm_ud">
            <button className="template-next">Next</button>
          </NavLink>
        </div>
      </section>
      <Footer />
    </>
  );
};
