import React from 'react'
import Footer from '../../../Footer/Footer'
import IMAGES from '../../../Images/'
import Navbar from '../../../Navbar/Navbar'
import { NavLink } from 'react-router-dom'

export const Vm_addisk = () => {
  return (
    <>
    <Navbar/>
        <section className='template'>
            <div className='template-container container'>
            <h1>Adding Disk</h1>
            <h2>Attach to Existing VM</h2>
            <p>1- Browse to your VM, and on the left panel, click on Disks. <br />
            2- click on + to create and attach a disk. <br />
            3- Select Read only.</p>            
            <img src={IMAGES.AWS363} alt="" />  
            <p>Name your Disk, Custom Disk</p>        
            <img src={IMAGES.AWS364} alt="" />  
            <p>Click on save to save the disk configurations.</p>        
            <img src={IMAGES.AWS365} alt="" /> 
            <p>You will see notification as below.</p>   
            <div className='template-container80rem' >
            <img src={IMAGES.AWS366} alt="" />      
            </div>                  
            <h2>Start VM and Add Disk</h2>    
            <p>Currently we have 2 drives.</p>
            <img src={IMAGES.AWS367} alt="" /> 
            <p>Open disk management, and here you will see below message, click ok to continue.</p>         
            <img src={IMAGES.AWS368} alt="" /> 
            <p>We can see our disk 2 unallocated.</p>         
            <img src={IMAGES.AWS369} alt="" />  
            <p>Format the Disk and give it a label.</p>        
            <img src={IMAGES.AWS370} alt="" />     
            <p>Here we can see, we just created the new disk with the letter E and it's size is 4GB.</p>     
            <img src={IMAGES.AWS371} alt="" />          
            </div>
            <div className='template-buttons container'>
            <NavLink to="/vm_setup"><button className='template-back'>Back</button></NavLink>
            <NavLink to="/vm_cs"><button className='template-next'>Next</button></NavLink>
            </div>
       </section>
       <Footer/>
    </>
    
  )
}
