import React from 'react'
import FooterCenter from '../05/FooterCenter'
import { CenterData } from './CenterData'
import LogoCenter from './LogoCenter'


const CenterIndex = () => {
  return (
    <>
    <section className='centerlogo'>
            <div className='centerlogo-heading container'>
                <h1>Footer with Logo Centered</h1>
            </div>
            <div className='clogo-container container'>
           
               
                {
            CenterData.map((items)=>(
                <LogoCenter
                    cheading={items.cheading}                   
                    item1={items.item1}
                    item2={items.item2}
                    item3={items.item3}
                    item4={items.item4}                                      
                    key= {items.id}
                />
            ))
        }
               
       
        </div>
        </section>
        <FooterCenter/>
        
    </>
  )
}

export default CenterIndex