import React from 'react'
import Footer from '../../../Footer/Footer'
import Navbar from '../../../Navbar/Navbar'
import IMAGES from '../../../Images'


export const Con_autoscaling = () => {
  return (
    <>
        <Navbar/>
        <section className='template'>
            <div className='template-container container'>
            <h1>Load Balancer with AutoScaling</h1>   
            <img src={IMAGES.AWS104} alt="" /> 
            <p>From the EC2 management console, look for Autoscaling, click on autoscaling groups to create one.</p>         
            <div className='template-container60rem' >
            <img src={IMAGES.AWS103} alt="" />          
            </div>                    
            <p>Name your autoscaling group, and click next.</p>
            <img src={IMAGES.AWS105} alt="" />   
            <p>From the launch template, name your template and version description.</p>       
            <img src={IMAGES.AWS106} alt="" />   
            <p>Next you will see launch template contents which is similar to EC2 wizard. Select your AMI.</p>       
            <img src={IMAGES.AWS107} alt="" />          
            <p>Free tier should be fine for this demo</p>
            <img src={IMAGES.AWS108} alt="" /> 
            <p>Under Subnet, choose don't include in this template.</p>         
            <img src={IMAGES.AWS109} alt="" />          
            <img src={IMAGES.AWS110} alt="" />  
            <p>Add user data, this is script to install Apache websever.</p>        
            <img src={IMAGES.AWS111} alt="" />          
            <img src={IMAGES.AWS112} alt="" />  
            <p>Click on view launch templates to view your created template as shown below</p>                   
            <img src={IMAGES.AWS113} alt="" />          
            <h2>Back to Autoscaling Group</h2> 
            <p>Open your template</p>
            <img src={IMAGES.AWS115} alt="" />              
            <p>Select your VPC and Subnets under choose instance launch options.</p>        
            <img src={IMAGES.AWS114} alt="" />          
            <img src={IMAGES.AWS116} alt="" />  
            <p>Configure Advanced Options</p>        
            <img src={IMAGES.AWS117} alt="" />          
            <img src={IMAGES.AWS118} alt="" />  
            <p>Configure Group Size and Scaling Policies</p>        
            <img src={IMAGES.AWS119} alt="" />          
            <img src={IMAGES.AWS120} alt="" />  
            <p>Add Notifications - Optional</p>        
            <img src={IMAGES.AWS121} alt="" />  
            <p>Review and Create. you will see your DemoASG under Autoscaling Groups. </p>        
            <img src={IMAGES.AWS122} alt="" />  
            <p>it will created a new instance, based on the data we have provided in the details, if we change our desired capacity to 2, and maximum capacity to 2, then it will create another instance. </p>        
            <img src={IMAGES.AWS123} alt="" /> 
            <p>Current desired capacity as shown below, we can add more.</p>                
            <img src={IMAGES.AWS124} alt="" /> 
            <p>As we can see, it created a new instance under EC2 instances.</p>         
            <img src={IMAGES.AWS125} alt="" />    
            <p>Enter the public ip address of this instance into your browser to check if our webserver is running or not.</p>      
            <img src={IMAGES.AWS126} alt="" /> 
            <h2>Target Groups Dashboard</h2>
            <img src={IMAGES.AWS127} alt="" />  
            <p>Adding desired capacity to 2</p> 
            <img src={IMAGES.AWS128} alt="" />  
            <p>Click on activity to see it in action, it instantly launching another instance.</p>
            <img src={IMAGES.AWS129} alt="" />    
            <p>Our new instance is successful</p>      
            <img src={IMAGES.AWS130} alt="" />
            <p>Now we have 2 webservers with same configurations.</p>                      
            <img src={IMAGES.AWS132} alt="" />          
            <img src={IMAGES.AWS133} alt="" /> 
            <p>In Target Groups Dashboard we can see two targets now.</p>         
            <img src={IMAGES.AWS134} alt="" />   
            <h2>Changing Desired Capacity</h2>    
            <p>Removing 1 instance from the desired capacity, it will automatically pick one instance and remove it</p><br />
<p>sa we have two instance, lets see which one it will remove</p>
            <img src={IMAGES.AWS135} alt="" /> 
            <div className='template-container60rem' >
            <img src={IMAGES.AWS136} alt="" />  
            </div>         
            
            <p>You can view it in the activity history.</p>        
            <img src={IMAGES.AWS137} alt="" />   
            <p>Looks like its now terminating the instance in southeast-a region</p>       
            <img src={IMAGES.AWS138} alt="" />          
            <img src={IMAGES.AWS139} alt="" /> 
            <p>so it automatically deleted our B instance</p>         
            <img src={IMAGES.AWS140} alt="" />                                         
            
            
            </div>
            <div className='template-buttons container'>
            <a href="/asg_overview"><button className='template-back'>Back</button></a>
            <a href="/asg_policy"><button className='template-next'>Next</button></a>
            </div>
       </section>
        <Footer/>
    </>
    
  )
}
