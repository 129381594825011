import React, { useState } from 'react'
import Sysadmin from './Sysadmin'
import { SysAdminData } from './SysAdminData'

const SysadminIndex = () => {
    const [Data,setData] = useState(SysAdminData)
  return (
    <>
    <section className='sysadmin'>
        <div className='sysadmin-Heading container'>
            <h1>IT Administration</h1>
            <p>Over 8 years of experience in IT Administration, worked on various technologies, that includes Microsoft, Linux, Azure, AWS, Virtualization and many more.
            </p>
        </div>
            <div className='sysadmin-container container'>
        {
            Data.map((items)=>(
                <Sysadmin
                icon={items.icon}
                heading={items.heading}
                item_1={items.item_1}
                item_2={items.item_2}
                item_3={items.item_3}
                item_4={items.item_4}
                item_5={items.item_5}
                item_6={items.item_6}
                key={items.id}
                />
            ))
        }
        </div>
        </section>
    </>
  )
}

export default SysadminIndex