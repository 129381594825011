import React from 'react'
import './Cards01.css'

const Cards01 = ({sysHeading, sysText, sysImage}) => {
  return (
    <>
        
                    
<div className='card-system-text'>
<div className='card-card'>
  <h1>{sysHeading}</h1>
  </div>
  <div className='card-system-para'>
  <p>{sysText}</p>
  </div>
      </div>
      <div className='card-system-image'>
      <img src={sysImage} alt="" />
      </div>                
                  
           
    </>
  )
}

export default Cards01