import React from 'react'
import {BsFillPlayFill} from 'react-icons/bs'
import '../05/Hero05.css'

const Hero05 = () => {
  return (
    <>
    <div className='hero05-heading'>
        <h1>Split Screen with Video</h1>
    </div>
        <section className='hero05'>
            <div className='hero05-container container'>
                <div className='hero05-textarea'>
                <h1>Write Once, Use EveryWhere</h1>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium enim asperiores nulla laborum, ullam laudantium quis, recusandae aliquid cupiditate reiciendis incidunt aliquam, iste libero vel tenetur soluta sit ratione! Reprehenderit!</p>
                <div className='hero05-group'>
                <button className='btn-hero05'>Get Started</button>
                <button className='btn-hero05'><BsFillPlayFill/> How it Works</button>
                </div>
                </div>
                
                <div className='hero05-videoarea'>
                <iframe  
                src="https://www.youtube.com/embed/u31qwQUeGuM" frameborder="0"></iframe>
                </div>
            </div>
        </section>
    </>
  )
}

export default Hero05