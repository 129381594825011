import React, { useState } from 'react'
import { SkillData } from './SkillData'
import Skills from './Skills'

const SkillsIndex = () => {
    const [Data,setData] = useState(SkillData)
  return (
    <>
    <section className='skills'>
        <div className='skills-Heading container'>
            <h1>Front End Development</h1>
            <p>Experience in Front end design i.e user interface and user experience, also worked on couple of projects using React Js and other different technologies.
            </p>
        </div>
            <div className='skills-container container'>
        {
            Data.map((items)=>(
                <Skills
                icon={items.icon}
                heading={items.heading}
                item_1={items.item_1}
                item_2={items.item_2}
                item_3={items.item_3}
                item_4={items.item_4}
                item_5={items.item_5}
                item_6={items.item_6}
                key={items.id}
                />
            ))
        }
        </div>
        </section>
    </>
  )
}

export default SkillsIndex