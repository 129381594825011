export const Pricedata02 = [
    {
        id:0,
        pricing02_heading:'Starter',
        pricing02_item1:'1 lorem ipsum',
        pricing02_item2:'Lorem, ipsum dolor.',
        pricing02_item3:'Monthly Updates',
        pricing02_package:'Free'
    },
    {
        id:1,
        pricing02_heading:'Lorem Plus',
        pricing02_item1:'1 lorem ipsum',
        pricing02_item2:'Lorem, ipsum dolor.',
        pricing02_item3:'Monthly Updates',
        pricing02_package:'$32.00'
    },
    {
        id:2,
        pricing02_heading:'Lorem Gold',
        pricing02_item1:'1 lorem ipsum',
        pricing02_item2:'Lorem, ipsum dolor.',
        pricing02_item3:'Monthly Updates',
        pricing02_package:'$50.00'
    }
]