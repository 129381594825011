import React from 'react'
import { Pricedata } from './Pricedata'
import Pricing01 from './Pricing01'

const PriceIndex = () => {
  return (
    <>
    <section className='Pricing01'>
        <div className='Pricing01-heading'>
        <h1>Pricing</h1>
        <h2>Plans that fit your need</h2>
        <p>Start with 14-day free trial. No Credit card needed. Cancel at anytime</p>
        </div>
        <div className='Pricing01-container container'>
      {
        Pricedata.map((items)=>(
          <Pricing01
           price_heading={items.price_heading} 
           price_package={items.price_package}
           price_item1={items.price_item1}
           price_item2={items.price_item2}
           price_item3={items.price_item3}
           price_item4={items.price_item4}
           price_item5={items.price_item5}
           key={items.id}
          />
        ))
      }
      </div>
        </section>
    </>
  )
}

export default PriceIndex