import React from 'react'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'
import PriceIndex from '../subComponents/Pricing/01/PriceIndex'
import PriceIndex02 from '../subComponents/Pricing/02/PriceIndex02'



const Pricing_ = () => {
  return (
    <>
         <Navbar/>   
         <PriceIndex/>        
         <PriceIndex02/> 
         <Footer/>
    </>
  )
}

export default Pricing_