import React from 'react'
import { NavLink } from 'react-router-dom'
import Footer from '../../../Footer/Footer'
import IMAGES from '../../../Images/'
import Navbar from '../../../Navbar/Navbar'

export const Iam_secTools = () => {
  return (
    <>
    <Navbar/>
        <section className='template'>
            <div className='template-container container'>
            <h1>IAM Security Tools</h1>
            <p>Below is the list of two levels of security</p>
            <img src={IMAGES.AWS69} alt="" />     
            <h2>Credential Report</h2>   
            <div className='template-container60rem' >
            <img src={IMAGES.AWS70} alt="" />          
            </div>            
            <img src={IMAGES.AWS71} alt="" />          
            <p>Report will give you all the information related to your user accounts</p>
            <img src={IMAGES.AWS72} alt="" />    
            <h2>Access Advisor</h2>      
            <p>Access advisor is for the user</p>
            <img src={IMAGES.AWS73} alt="" /> 
            <p>You can also check access advisor under roles</p>         
            <img src={IMAGES.AWS74} alt="" />     
            <p>Also under policies</p>     
            <img src={IMAGES.AWS75} alt="" />  
            <h2>Best Practices</h2> 
            <img src={IMAGES.AWS76} alt="" />           
            <img src={IMAGES.AWS77} alt="" />                      
            </div>
            <div className='template-buttons container'>
          <NavLink to="/iam_policy"><button className='template-back'>Back</button></NavLink>
          <NavLink to="/iam_metadata"><button className='template-next'>Next</button></NavLink>

            
            </div>
       </section>
       <Footer/>
    </>
    
  )
}
