import React from 'react'
import { NavLink } from 'react-router-dom'
import Footer from '../../../Footer/Footer'
import IMAGES from '../../../Images'
import Navbar from '../../../Navbar/Navbar'

export const Aws_clientvpn = () => {
  return (
    <>
    <Navbar/>
        <section className='template'>
            <div className='template-container container'>
            <h1>Client to Site VPN</h1>           
            
            <img src={IMAGES.AWS101} alt="" />                      
            </div>
            <div className='template-buttons container'>

    <NavLink to="/aws_cloudfront"><button className='template-back'>Back</button></NavLink>
    <NavLink to="/aws_sitevpn"><button className='template-next'>Next</button></NavLink>

            {/* <a href="https://ammar.tech/aws_cloudfront"><button className='template-back'>Back</button></a>
            <a href="https://ammar.tech/aws_sitevpn"><button className='template-next'>Next</button></a> */}
            
            </div>
       </section>
       <Footer/>
    </>
    
  )
}
