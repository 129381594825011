import React from 'react'
import '../01/Pricing01.css'
import {TiTickOutline} from 'react-icons/ti'

const Pricing01 = ({price_heading,price_package,price_item1,price_item2,price_item3,price_item4,price_item5}) => {
  return (
    <>
        
        <div className='pricing-items'>
        <div className='pricing-top'>
        <h1>{price_heading}</h1>
        <p>{price_package}</p>
        </div>
        <div className='pricing-bottom'>
        <ul className='pricing-list'>
            <li><TiTickOutline className='price-color'/> {price_item1}</li>
            <li><TiTickOutline className='price-color'/> {price_item2}</li>
            <li><TiTickOutline className='price-color'/> {price_item3}</li>
            <li><TiTickOutline className='price-color'/> {price_item4}</li>
            <li><TiTickOutline className='price-color'/> {price_item5}</li>
        </ul>
        <div className='pricing-button'>
        <button className='btn-pricing'>Start Trial</button>
        </div>
        
        </div>
        
        </div>
       
    </>
  )
}

export default Pricing01