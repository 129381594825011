import React from 'react'
import Footer from '../../../Footer/Footer'
import IMAGES from '../../../Images'
import Navbar from '../../../Navbar/Navbar'
import { NavLink } from 'react-router-dom'

export const Nlb_info = () => {
  return (
    <>
    <Navbar/>
        <section className='template'>
            <div className='template-container container'>
            <h1>Network Load Balancer</h1>
            <img src={IMAGES.AWS284} alt="" />          
            <img src={IMAGES.AWS285} alt="" />          
            <img src={IMAGES.AWS286} alt="" />     
            <h2>Create Network Load Balancer</h2>     
            <div className='template-container60rem' >
            <img src={IMAGES.AWS287} alt="" />          
            </div>            
            <p>Name your Load Balancer</p>
            <img src={IMAGES.AWS288} alt="" />       
            <p>Select your Subnet</p>
            <img src={IMAGES.AWS289} alt="" />  
            <p>Select TCP from Listeners and Routing, note that in application load balancer we have the option to select Http with port 80, that is because application load balancer operates at layer 7 - HTTP, and network load balancer operates at layer 4 - TCP. </p>        
            <img src={IMAGES.AWS290} alt="" />  
            <h2>Create Target Group</h2>
            <p>Select Instance as a target type</p>       
            <img src={IMAGES.AWS291} alt="" />  
            <p>Name your Target</p>        
            <img src={IMAGES.AWS292} alt="" /> 
            <p>Check Health check settings</p>         
            <img src={IMAGES.AWS293} alt="" />    
            <p>Register Targets</p>      
            <img src={IMAGES.AWS294} alt="" /> 
            <p>Select all 3 EC2 instances, which we created before.</p>         
            <img src={IMAGES.AWS295} alt="" />          
            <img src={IMAGES.AWS296} alt="" />   
            <h2>Back to NLB</h2> 
            <p>Select your target group</p>      
            <img src={IMAGES.AWS297} alt="" />   
            <p>Click on create Load Balancer to create one.</p>       
            <img src={IMAGES.AWS298} alt="" />          
            <img src={IMAGES.AWS299} alt="" />  
            <h2>Testing</h2>        
            <img src={IMAGES.AWS300} alt="" />          
            <img src={IMAGES.AWS301} alt="" />       
            <h2>Troubleshooting</h2>   
            <p>in case if it fail to load then modify the security group of ec2 instances, add an tcp entry pointing to 0.0.0.0/0 or pointing to your load balancer.</p>
            <img src={IMAGES.AWS302} alt="" />  
            <h2>Sticky Session</h2>        
            <img src={IMAGES.AWS303} alt="" />  
            <p>From target groups, click on edit attributes</p>
            <img src={IMAGES.AWS304} alt="" />  
            <p>Check Stickiness</p>
            <img src={IMAGES.AWS305} alt="" /> 
            <p>So for one day, user will get the same server</p> 
            <img src={IMAGES.AWS306} alt="" />  
            </div>
            <div className='template-buttons container'>
            <NavLink to="/con_loadbalancer"><button className='template-back'>Back</button></NavLink>
            <NavLink to="/glb_info"><button className='template-next'>Next</button></NavLink>
            </div>
       </section>
       <Footer/>
    </>
    
  )
}
