import React from 'react'
import { NavLink } from 'react-router-dom'


export const Iam_block = () => {
  return (
    <>
    
        <section className='template'>
            <div className='template-container template-block container'>
            <h3>Identity and Access Management</h3>
            <ul>
            <li><NavLink to="/userAccounts">Create User Accounts</NavLink></li>   
            <li><NavLink to="/signinurl">Sign in URL</NavLink></li>   
            <li><NavLink to="/accesskeys">Access Keys</NavLink></li>   
            <li><NavLink to="/iam_roles">Creating Roles</NavLink></li>   
            <li><NavLink to="/iam_policy">Creating Policies</NavLink></li>   
            <li><NavLink to="/iam_sectools">Security Tools</NavLink></li>   
            <li><NavLink to="/iam_metadata">Meta Data</NavLink></li>   
            <li><NavLink to="/iam_sdk">Software Deployment Kit</NavLink></li>   
            <li><NavLink to="/iam_billing">Billing</NavLink></li>   
            </ul>
            
            
            </div>
            
            
            
       </section>
    
    </>
    
  )
}
