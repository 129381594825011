import React from 'react'
import '../02/News02.css'


const News02 = () => {
  return (
    <>
        <section className='news02'>
            <div className='news02-container container'>
                <div className='news02-part01'>
                   
                    <h1>Subscribe to our news letter</h1>
                </div>
                <div className='news02-part02'>
                    <input className='news02-input' type="text" placeholder='Enter your Email' />
                    <button className='btn-news02'>Submit</button>
                </div>
            </div>
        </section>
    </>
  )
}

export default News02