import React from 'react'
import './Pdetails.css'
import {TiTickOutline} from 'react-icons/ti'
import {MdOutlineDeliveryDining} from 'react-icons/md'

const Pdetails = () => {
  return (
    <>
        <section className='pdetails'>
        <div className='pdetails-heading'>
        <h1>Product Details</h1>
        </div>
        
        <div className='pdetails-container container'>
        <div className='pdetails-image'>
        <img src="/pdetails/01.jpg" alt="" />
        </div>
            <div className='pdetails-h1'>
            <h1>Best Perfume in the Market</h1>
            </div>
            <div className='pdetails-price'>
            <h1>$199.00 USD</h1>
            </div>
            <div className='pdetails-details'>
            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore

Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aliquid amet at delectus doloribus dolorum expedita hic, ipsum maxime modi nam officiis porro, quae, quisquam quos reprehenderit velit? Natus, totam.</p>
            </div>
            <div className='pdetails-features'>
            <h1>Features</h1>
            </div>
            
            <ul className='pdetails-list1'>
                <li><TiTickOutline className='price-color'/> Woody and Spicy</li>
                <li> <TiTickOutline className='price-color'/>Exotic Ingredients</li>                
                <li> <TiTickOutline className='price-color'/>Orange Blossom</li>
                <li> <TiTickOutline className='price-color'/>Black Berry</li>
                <li> <TiTickOutline className='price-color'/>Jasmine & Coffee</li>
                <li><TiTickOutline className='price-color'/> bitter Almond</li>
                <li><TiTickOutline className='price-color'/> Strong Essence</li>
            </ul>
            <div className='pdetails-features'>
            <h1>Product Details</h1>
            </div>
           
            <ul className='pdetails-list2'>
                <li><TiTickOutline className='price-color'/> Between lorem : Lorem, ipsum.</li>
                <li><TiTickOutline className='price-color'/> Case : Lorem, ipsum.</li>
                <li><TiTickOutline className='price-color'/> Color: Lorem, ipsum dolor.</li>
                <li><TiTickOutline className='price-color'/> Crystal : Lorem.</li>
                <li><TiTickOutline className='price-color'/> Body : Lorem ipsum dolor sit.</li>
            </ul>
            <button className='btn-pdetails'>Add to Cart</button>
            <div className='pdetails-delivery'>
              <p><MdOutlineDeliveryDining className='pdetails-icon'/> 2-3 business days delivery</p>
            </div>
        </div>
        </section>
    </>
  )
}

export default Pdetails