import React from 'react'
import '../06/FooterInput.css'
import finput from '../03/logo1.svg'
import {FaTwitter} from 'react-icons/fa'
import {FaFacebook} from 'react-icons/fa'
import {FaYoutube} from 'react-icons/fa'
import {AiOutlineMail} from 'react-icons/ai'
const FooterInput = () => {
  return (
  <>
    <section className='footer-input'>
    <div className='footer-input-heading'>
        <h1>Footer with News Letter Form</h1>
    </div>
            <div className='finput-container container'>
                <div className='finput-social-items'>
                <div className='finput-image'>
                <img src={finput} alt="" />
                </div>
                <p>© 2022 Ammar Tech. All rights reserved</p>
                <div className='finput-icons'>
                    <FaTwitter/>
                    <FaFacebook/>
                    <FaYoutube/>
                </div>
                </div>
<div className='finput-links'>
    <div className='company-links'>
    <h1>Company</h1>
    <ul>
        <a href=""><li>About Us</li></a>
        <a href=""><li>Blog</li></a>
        <a href=""><li>Contact Us</li></a>
        <a href=""><li>Pricing</li></a>
        <a href=""><li>Testimonials</li></a>
    </ul>
    </div>
    <div className='product-links'>
    <h1>Products</h1>
    <ul>
        <a href=""><li>Overview</li></a>
        <a href=""><li>Features</li></a>
        <a href=""><li>Tutorials</li></a>
        <a href=""><li>Press</li></a>
        <a href=""><li>Partners</li></a>
    </ul>
    </div>
    
</div>
<div className='finput-textarea'>
    <h1>Stay up to Date</h1>
    <input className='finput-input' type="Email" name="" id="" placeholder='Your Email Address' />
    <button className='btn-finput'><AiOutlineMail/></button>
    
</div>

            </div>
    </section>
  </>
  )
}

export default FooterInput