import React from 'react'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'
import SubServicesIndex from '../subComponents/Features/01/SubServicesIndex'
import Feature02Index from '../subComponents/Features/02/Feature02Index'
import Feature03 from '../subComponents/Features/03/Feature03'



const Features_ = () => {

  return (
   <>
        <Navbar/>  
        <SubServicesIndex/>       
        <Feature02Index/>        
        <Feature03/>
        <Footer/>
   </>
  )
}

export default Features_