import React, { useState } from 'react'
import Web from './Web'
import { WebData } from './WebData'

const WebIndex = () => {
    const [data, setData] = useState(WebData)
  return (
    <>
    <section className='web'>
        <div className='web-heading'>
            <h1>React Front End Designs</h1>
            <h2>Fully Responsive</h2>
        </div>
            <div className='web-container container'>
        {
            data.map((items)=>(
                <Web
                 projectImage={items.projectImage}   
                 projectName={items.projectName}
                 GitHub={items.GitHub}
                 LiveDemo={items.LiveDemo}
                 key={items.id}
                />
            ))
        }
        </div>
        </section>
    </>
  )
}

export default WebIndex