import React from 'react'
import './Web.css'

const Web = ({projectName, projectImage, GitHub, LiveDemo}) => {
  return (
    <>
        
                <div className='web-items'>
                <div className='web-image'>
                <img src={projectImage} alt="" />
                </div>
                    
                    <h1>{projectName}</h1>
                    <div className='btn-cards'>

                    <a href={GitHub} target='_blank'><button className='btn-alpha'>GitHub</button></a>
                    <a href={LiveDemo} target='_blank'><button className='btn-beta'>Live Demo</button></a>
                 
                    </div>
                    
                </div>
            
    </>
  )
}

export default Web