import React from 'react'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'
import News01 from '../subComponents/Newsletter/01/News01'
import News02 from '../subComponents/Newsletter/02/News02'

const News_ = () => {
  return (
    <>
    <Navbar/> 
    <News01/>
    <News02/>
    <Footer/>

    </>
  )
}

export default News_