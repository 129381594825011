import React from 'react'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'
import Testi01index from '../subComponents/Testimonial/01/Testi01index'
import Testi02 from '../subComponents/Testimonial/02/Testi02'



const Testimonials_ = () => {

  return (
   <>
        <Navbar/>  
        <Testi01index/>
        <Testi02/>
        <Footer/>
   </>
  )
}

export default Testimonials_