import React from 'react'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'
import Social from '../subComponents/Social/01/Social'
import Social02 from '../subComponents/Social/02/Social02'

const Social_ = () => {

  return (
   <>
        <Navbar/>  
        <Social/>       
        <Social02/> 
        <Footer/>
   </>
  )
}

export default Social_