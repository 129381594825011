import React from 'react'
import '../03/Auth03.css'

const Auth03 = () => {
  return (
    <>
        
        <section className='auth03'>
        
       
            <div className='auth03-heading'>
            <div className='auth03-h1'>
        <h1>Reset Password Card</h1>
        </div>
                <h1>Password Reset Request</h1>
                {/* <p>to enjoy all of our cool <a href="">features </a>👌 </p>                 */}
            </div>
            <div className='auth03-input'>
            <p className='auth03-p'>Email Address <span className='auth03-star'>*</span></p>
            <input className='auth03-x' type="Email"/>
            <p className='auth03-p'>Password <span className='auth03-star'>*</span></p>
            <input className='auth03-x' type="Password" name="" id="" />
            {/* <div className='auth03-checkbox'>
            <input type="checkbox" name="" id="" />
            <p>Remeber me</p>
            <a href="">Forgot password ?</a>
            </div> */}
            <button className='auth03-btn'>
            Submit
            </button>         

            </div>
        </section>
    </>
  )
}

export default Auth03