export const CenterData = [ 


    {
        id:1,
        cheading : 'Company',
        item1:'About Us',
        item2: 'Blog',
        item3: 'Careers',
        item4 : 'Contact Us',
        item5: 'Partners'
    },
    {
        id:2,
        cheading : 'Support',
        item1:'Help Center',
        item2: 'Safety Center',
        item3: 'Tutorials',
        item4 : 'Releases',
        item5: 'Status'
        
    },
    {
        id:3,
        cheading : 'Legal',
        item1:'Cookies Policy',
        item2: 'Privacy Policy',
        item3: 'Terms of Service',
        item4 : 'Law Enforcement',
        item5: 'Community Guidelines',
    },
    {
        id:4,
        cheading : 'Follow Us',
        item1:'Facebook',
        item2: 'Twitter',
        item3: 'Dribble',
        item4 : 'Instagram',
        item5: 'LinkedIn',
    }
]