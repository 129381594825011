import React from 'react'
import './Hero02.css'

const Hero02 = () => {
  return (
   <>
       <div className='hero02-heading'>
            <h1>Contained Width with Background Image</h1>
       </div>
       <section class="hero02 container">       
        <div class="hero02-container">
            <h1>Drink because you are happy...</h1>
            <p>Lorem, ipsum dolor sit amet consectetur Animi, nesciunt?</p>
                      
           <a href="">
           <button className='btn-hero2'>Order Now</button> 
           </a> 
            
        </div>        
    </section>
   </>
  )
}

export default Hero02