import React from 'react'
import Footer from '../../../Footer/Footer'
import IMAGES from '../../../Images/'
import Navbar from '../../../Navbar/Navbar'
import { NavLink } from 'react-router-dom'

export const Alb_overview = () => {
  return (
    <>
    <Navbar/>
        <section className='template'>
            <div className='template-container container'>
            <h1>Application Load Balancer</h1>
            <h2>Overview</h2>
            <img src={IMAGES.AWS278} alt="" />          
            <img src={IMAGES.AWS279} alt="" />          
            <img src={IMAGES.AWS280} alt="" />          
            <img src={IMAGES.AWS281} alt="" />          
            <img src={IMAGES.AWS282} alt="" />          
            <img src={IMAGES.AWS283} alt="" />          
            </div>
            <div className='template-buttons container'>
            <NavLink to="/clb_info"><button className='template-back'>Back</button></NavLink>
            <NavLink to="/con_loadbalancer"><button className='template-next'>Next</button></NavLink>
            </div>
       </section>
       <Footer/>
    </>
    
  )
}
