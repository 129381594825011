export const SkillData = [
    {
        id:1,
        icon:'fas fa-meteor',
        heading:'Web Technical Skills',
        item_1:'Front End Development',
        item_2:'Wordpress',
        item_3:'BootStrap 5',
        item_4:'HTML / CSS3',
        item_5:'GIT',
        item_6:'Testing & Debugging'
    },
    {
        id:2,
        icon:'fa-solid fa-bolt',
        heading:'Design & Development',
        item_1:'User Experience (UX)',
        item_2:'User Interface (UI)',
        item_3:'Visual Designs',
        item_4:'Flexbox & Grid Layouts',
        item_5:'Attention to Details',
        item_6:'Responsive Designs'
    },
    {
        id:3,
        icon:'fa-solid fa-code',
        heading:'React & JavaScript',
        item_1:'React Hooks & Router',
        item_2:'Context Api',
        item_3:'Fetch & Axios API',
        item_4:'Redux',
        item_5:'React Framer Motion',
        item_6:'React Icons'
    },
    {
        id:4,
        icon:'fa-solid fa-filter',
        heading:'User Interface UI',
        item_1:'Avoid Unnecessary elements',
        item_2:'Consistent Elements',
        item_3:'Visible Language',
        item_4:'Screen Layout',
        item_5:'Navigation',
        item_6:'Simplicity & Clarity'
    },
    {
        id:5,
        icon:'fa-solid fa-microchip',
        heading:'User Experience UX',
        item_1:'Strategy & Concept',
        item_2:'User Interview',
        item_3:'User Surveys',
        item_4:'Documenting & Analyzing',
        item_5:'User Flow & Prototypes',
        item_6:'Testing & Validation'
    },
    {
        id:6,
        icon:'fa-solid fa-gear',
        heading:'Soft Skills',
        item_1:'Communication',
        item_2:'Adaptability',
        item_3:'Visual Thinking',
        item_4:'Creativity',
        item_5:'Collaboration',
        item_6:'Time Management'
    },
]