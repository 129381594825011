import React from 'react'
import './WebComp.css'

const WebComp = ({web_img, web_heading, Live_Demo, View_Demo}) => {
  return (
    <>
      
          <div className='webcomp-items'>
          <div className='webcomp-image-container'>
          <img src={web_img} alt="" />
          <div className='webcomp-image-content'>
            <a href={View_Demo}>
            <button className='btn-primary' >View Demo</button>
            </a>
            {/* <button className='btn-primary'>View Demo</button> */}

          </div>        
          </div>         
          <h1>{web_heading}</h1>   
          
          <a href={Live_Demo} target='_blank' className='btn-responsive'>Live Demo</a>
          </div>
        
    </>
  )
}

export default WebComp