import React from 'react'
import '../01/Auth01.css'

const Auth01 = () => {
  return (
    <>
        
        <section className='auth01'>
        
       
            <div className='auth01-heading'>
            <div className='auth01-h1'>
        <h1>Simple Login Card</h1>
        </div>
                <h1>Sign in to your account</h1>
                <p>to enjoy all of our cool <a href="">features </a>👌 </p>                
            </div>
            <div className='auth01-input'>
            <p className='auth01-p'>Email Address</p>
            <input className='auth01-x' type="Email"/>
            <p className='auth01-p'>Password</p>
            <input className='auth01-x' type="Password" name="" id="" />
            <div className='auth01-checkbox'>
            <input type="checkbox" name="" id="" />
            <p>Remeber me</p>
            <a href="">Forgot password ?</a>
            </div>
            <button className='auth01-btn'>
            Sign in
            </button>         

            </div>
        </section>
    </>
  )
}

export default Auth01