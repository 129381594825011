import React from 'react'
import AboutIndex from '../About/AboutIndex'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'

const About_ = () => {
  return (
   <>
    <Navbar/>
    <AboutIndex/>
    <Footer/>
   </>
  )
}

export default About_