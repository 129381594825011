import React from 'react'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'
import Auth01 from '../subComponents/Authentication/01/Auth01'
import Auth02 from '../subComponents/Authentication/02/Auth02'
import Auth03 from '../subComponents/Authentication/03/Auth03'
import Auth04 from '../subComponents/Authentication/04/Auth04'
import Auth05 from '../subComponents/Authentication/05/Auth05'

const Auth_ = () => {
  return (
   <>
        <Navbar/>   
        <Auth01/>
        <Auth02/>
        <Auth03/>
        <Auth04/>
        <Auth05/>
        <Footer/>
   </>
  )
}

export default Auth_