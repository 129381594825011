import React from 'react'
import Footer from '../../../Footer/Footer'
import Navbar from '../../../Navbar/Navbar'
import { Iam_block } from '../../content/IAM/Iam_block'
import { Elb_block } from '../../content/loadbalancer/Elb_block'




import AwsSolutionsIndex from '../02/AwsSolutionsIndex'

const Aws = () => {
  return (
    <>
     
<Navbar/>

<Iam_block/>
<Elb_block/>
<AwsSolutionsIndex/>
<Footer/>



        </>
  )
}

export default Aws