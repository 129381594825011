import React, { useState } from 'react'
import WebComp from './WebComp'
import { WebCompData } from './WebCompData'

const WebCompIndex = () => {
    const [data,setData] = useState(WebCompData)
  return (
    <>
    <section className='webcomp'>
      <div className='webcomp-heading'>
        <h1>React Components</h1>
      </div>
        <div className='webcomp-container container'>
        {
            data.map((items)=>(
                <WebComp
                    web_img = {items.web_img}
                    web_heading = {items.web_heading}
                    Live_Demo = {items.Live_Demo}
                    View_Demo = {items.View_Demo}
                    key = {items.id}

                />
            ))
        }
        </div>
      </section>
    </>
  )
}

export default WebCompIndex