import React from 'react'
import { useRef } from 'react';
import './Contact.css'
import {HiOutlineMail} from 'react-icons/hi'
import {BsTelephone} from 'react-icons/bs'
import emailjs from 'emailjs-com'
import { useState } from 'react';
import {FaGithub} from 'react-icons/fa'
import {FaLinkedinIn} from 'react-icons/fa'


const Contact = () => {
  
  const [userData, setUserData] = useState({
    name:'',
    email : '',    
    message : ''

  })
   // Getting user Data, we need name and value
   let name, value
   const postUserData = (event) =>{
     name = event.target.name     // get the name
     value = event.target.value   // get the value
 
     setUserData({...userData, [name]:value})
   }

   

  const form = useRef();

  const sendEmail = (e) => {
    const {name,email,message} = userData
    if(name && email && message){  
     
    e.preventDefault();



    const resetform = emailjs.sendForm('service_tgtle6r', 'template_skkasi9', form.current, 'YrBsb-UC12tpMxrHL')
    
    // e.target.reset(res)


    if(resetform){
      setUserData({
        name:'',
        email:'',
        message:''
      })
      alert('Your Message has been sent')
    }
      
      
      //e.target.reset() }      
    }
      else{
        alert('Please fill the complete form')
        e.preventDefault();
      }
     
      
  };
  


  return (
   <>
       <section className='contact'>
            <div className='contact-container container'>
            <div className='contact-left'>
                <h1>Contact Me</h1>
                <p><BsTelephone/> Mobile: 0420883693</p>
                <p><HiOutlineMail/> Email : contact@ammar.tech</p>   

                <section>
        <div className="contact-social">
            <div className="contact-icons">
            <a href="https://github.com/ammarr100/" target='_blank'>
                <i><FaGithub/></i>
            </a>
        </div>
        <div className="contact-icons">
            <a href="https://www.linkedin.com/in/ammarrashid/" target='_blank'>
                <i><FaLinkedinIn/></i>
            </a>
        </div>
        </div>
        </section>
                     
            </div>            
            <div className='contact-right'>
        <form ref={form} onSubmit={sendEmail}>
        <input className='contact-input' type="text" name='name' placeholder='Enter Name' value={userData.name} onChange={postUserData} />  
        <input className='contact-input' type="email" name='email' placeholder='Enter Email' value={userData.email} onChange={postUserData} />
          <textarea className='contact-textmsgbox' name="message" id="" rows="10" placeholder='Enter Message' value={userData.message} onChange={postUserData}></textarea>
          <button className='btn-primary'>Submit</button>
          </form>
        </div>
             
            </div>
       </section>

       
   </>
  )
}

export default Contact