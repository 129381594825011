import React from 'react'
import BlogIndex from '../../Blog/BlogIndex'
import Footer from '../../Footer/Footer'
import Navbar from '../../Navbar/Navbar'

const Blog_ = () => {
  return (
    <>
    <Navbar/>
    <BlogIndex/>
    <Footer/>
    </>
  )
}

export default Blog_