import React from 'react'
import { NavLink } from 'react-router-dom'
import Footer from '../../../Footer/Footer'
import IMAGES from '../../../Images/'
import Navbar from '../../../Navbar/Navbar'

export const UserAccounts = () => {
  return (
    <>
    <Navbar/>
        <section className='template'>
            <div className='template-container container'>
            <h1>Identity and Access Management - IAM</h1>
            <h2>IAM Users and Group</h2>
            <img src={IMAGES.AWS19} alt="" />
            <p>Below is the overview of IAM permissions</p>
            <img src={IMAGES.AWS20} alt="" />
            <p>IAM Policy Structre</p>
            <img src={IMAGES.AWS21} alt="" />
            <p>Lets see how to setup, user, groups, permissions and policies.</p><br /><br />
            <h2>Creating User Account</h2>            
            <img src={IMAGES.AWS01} alt="" />
            <img src={IMAGES.AWS02} alt="" />
            <img src={IMAGES.AWS03} alt="" />
            <img src={IMAGES.AWS04} alt="" />
            <img src={IMAGES.AWS05} alt="" />
            <h2>Creating Group</h2>
            <img src={IMAGES.AWS06} alt="" />
            <img src={IMAGES.AWS07} alt="" />
            <img src={IMAGES.AWS08} alt="" />
            <img src={IMAGES.AWS09} alt="" />
            <img src={IMAGES.AWS10} alt="" />
            <h2>Add User to Group</h2>
            <img src={IMAGES.AWS11} alt="" />
            <img src={IMAGES.AWS12} alt="" />
            <p>we can remove administrator access from attached directly option, because we have just define administrator access from group, so permissions are now coming from group.
            </p>            
            <img src={IMAGES.AWS13} alt="" />
            <img src={IMAGES.AWS14} alt="" />
            <img src={IMAGES.AWS15} alt="" />
            <h2>Change Password Policy</h2>
            <img src={IMAGES.AWS16} alt="" />
            <img src={IMAGES.AWS17} alt="" />
            <img src={IMAGES.AWS18} alt="" />
            </div>
            <div className='template-buttons container'>
            <NavLink to="/aws"><button className='template-back'>Back</button></NavLink>
            <NavLink to="/signinurl"><button className='template-next'>Next</button></NavLink>
            
            {/* <a href="https://ammar.tech/aws"><button className='template-back'>Back</button></a>
            <a href="https://ammar.tech/signinurl"><button className='template-next'>Next</button></a> */}
            </div>
       </section>
       <Footer/>
    </>
    
  )
}
