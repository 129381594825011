import React from 'react'
import './Feature02.css'

const Feature02 = ({featureIcon,featureHeading,featureText}) => {
  return (
    <>
        
            <div className='feature02-items'>
          <div className='feature02-image'>
          <img src={featureIcon} alt="" />            
          </div>
           <div className='feature02-h1'>
           <h1>{featureHeading}</h1>
           </div>
                <div className='feature02-text'>
                <p>{featureText}</p>
                </div>
                
            </div>
       
    </>
  )
}

export default Feature02