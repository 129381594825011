import React from 'react'
import '../01/Contact01.css'
import {TiTick} from 'react-icons/ti'

const Contact01 = () => {
  return (
    <>
        <section className='sub01-contact'>
        <div className='sub01-contact-container container'>
    <div className='sub01-contact-box'>
        <input className='sub01-input-text' type="text" placeholder='Enter your Name' />
        <input className='sub01-input-text' type="email" placeholder='Enter your Email' />
        <input className='sub01-input-text' type="phone" placeholder='Enter your Phone' />
        <textarea className='sub01-textarea' name="" id="" cols="30" rows="10"></textarea>
        <button type='sub01-sub-submit' className='sub01-btn-contact'>Submit</button>
    </div>
    <div className='sub01-contact-info'>
        <div className='sub01-contact-call'>
        <h1 className='sub01-contact-heading'>Call us</h1>
        <p>+61 0416466039</p><br />
        <p>ammarr@msn.com</p>
        </div>
        <div className='sub01-contact-location'>
        <h1 className='sub01-contact-heading'>Location</h1>
        <p>123 New South Wales Sydney,
        Australia AU 92103-9000</p>
        </div>
        <div className='sub01-contact-top'>
        <h1 className='sub01-contact-heading'>Top Services</h1>
        <ul>
            <li><TiTick/> Pre Design</li>
            <li><TiTick/> Schematic Design</li>
            <li><TiTick/> Design Development</li>
            <li><TiTick/> Engineering Consultants</li>
        </ul>
        </div>
    </div>
    <div className='sub01-contact-image'>
    <img src="contact/01.jpg" alt="" />
    </div>

        </div>
        </section>
    </>
  )
}

export default Contact01