import React from 'react';

const Rdata = [
    {
        repairHeading : 'Painting',
        repairText : 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt'
    },

    {
        repairHeading : 'Plumbing',
        repairText : 'Sample text. Click to select the text box. Click again or double click to start editing the text.'
    },

    {
        repairHeading : 'Remodeling',
        repairText : 'Sample text. Click to select the text box. Click again or double click to start editing the text.'
    },

    {
        repairHeading : 'Flooring',
        repairText : 'Sample text. Click to select the text box. Click again or double click to start editing the text.'
    },

    {
        repairHeading : 'Home Cleaning',
        repairText : 'Sample text. Click to select the text box. Click again or double click to start editing the text.'
    },

    {
        repairHeading : 'Smart Home',
        repairText : 'Sample text. Click to select the text box. Click again or double click to start editing the text.'
    }
]

export default Rdata;
