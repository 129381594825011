import React from 'react'
import { NavLink } from 'react-router-dom'
import Footer from '../../../Footer/Footer'
import IMAGES from '../../../Images/'
import Navbar from '../../../Navbar/Navbar'

export const Iam_policy = () => {
  return (
    <>
    <Navbar/>
        <section className='template'>
            <div className='template-container container'>
            <h1>Creating IAM Policy</h1>
            
            <p>From the IAM, click on policy, and then create policy</p>
            <img src={IMAGES.AWS54} alt="" />          
            <p>Slect the S3 Service, or search for S3, once that is open then a new page will open as shown below, click on expand all </p>
            <img src={IMAGES.AWS55} alt="" />    
            <p>from the read options, select GetObject</p>      
            <img src={IMAGES.AWS56} alt="" />  
            <p>Next you have to specify your ARN, click on Add ARN</p>        
            <img src={IMAGES.AWS57} alt="" />         
            <p>Name you S3 bucket here, and click on Add</p> 
            <img src={IMAGES.AWS58} alt="" />          
            <img src={IMAGES.AWS59} alt="" />    
            <p>Review your policy and then click on create policy</p>      
            <img src={IMAGES.AWS60} alt="" />     
            <p>Go back to policies, and here you can see your created policy</p>     
            <img src={IMAGES.AWS61} alt="" />   
            <p>below is the Json format of your policy</p>       
            <img src={IMAGES.AWS62} alt="" />    
            <h2>AWS Policy Generator</h2>     
            <p>You can also use another visual editor by the name of aws policy generator</p> 
            <img src={IMAGES.AWS63} alt="" />   
            <h2>Policy Simulator</h2>   
            <p>Policy simulator is a tool to check permissions on your policy</p>    
            <a href="https://policysim.aws.amazon.com/
            ">Policy Simulator</a>
            <img src={IMAGES.AWS64} alt="" />   
            
            <p>Here we have 3 options, users, group, and roles.</p>       
            <div className='template-container60rem' >
            <img src={IMAGES.AWS65} alt="" />    
            </div>                        
            <p>In this scenario, we will select user, and our policy is AmazonS3ReadOnlyAccess, note that it will pull policy from your aws account. Next from the policy simulator, choose s3, and check get object from the actions.</p>      
            <img src={IMAGES.AWS66} alt="" />      
            <p>Click on Run Simulation to test</p>    
            <img src={IMAGES.AWS67} alt="" /> 
            <p>Same way you can deny by choosing the different action, here we have selected DeleteObjectVersion</p>         
            <img src={IMAGES.AWS68} alt="" />    
            <p>As it is readonly, so we cannot make anychanges including delete</p>      
            </div>
            <div className='template-buttons container'>

      <NavLink to="/iam_roles"><button className='template-back'>Back</button></NavLink>
      <NavLink to="/iam_sectools"><button className='template-next'>Next</button></NavLink>
       
            </div>
       </section>
       <Footer/>
    </>
    
  )
}
