import React from 'react'
import '../05/FooterCenter.css'
import fcenter from '../03/logo1.svg'

const FooterCenter = () => {
  return (
    <>
        <section className='footer-center'>
            <div className='fcenter-container container'>
                <div className='fcenter-image'>
                    <img src={fcenter} alt="" />
                </div>
                <div className='fcenter-text'>
                    <p>© 2022 Ammar Tech. All rights reserved</p>
                </div>
            </div>
        </section>
    </>
  )
}

export default FooterCenter