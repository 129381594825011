import React from 'react'
import './Hero01.css'

const Hero01 = () => {
  return (
   <>
      
      
       <section class="hero01">    
       <div className='hero01-heading'>
            <h1>Full Width with Background Image</h1>
            </div>
       
      
        <div class="hero01-container">
            <h1>Best Food in the Market...</h1>
            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Animi, nesciunt?</p>
                      
           <a href="">
           <button className='btn-hero1'>Shop</button> 
           </a> 
            
        </div>        
    </section>
    
    
   </>
  )
}

export default Hero01