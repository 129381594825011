import React from 'react'
import './Skills.css'
import {TiTickOutline} from 'react-icons/ti'

const Skills = ({icon,heading,item_1,item_2,item_3,item_4,item_5,item_6}) => {
  return (
    <>
        
                <div className='skill-items'>
                             
               
                    <i class = {`icon-colors ${icon}`}></i>
                    <h1>{heading}</h1>
                    <ul>
                        <li><TiTickOutline/> {item_1}</li>
                        <li><TiTickOutline/> {item_2}</li>
                        <li><TiTickOutline/> {item_3}</li>
                        <li><TiTickOutline/> {item_4}</li>
                        <li><TiTickOutline/> {item_5}</li>
                        <li><TiTickOutline/> {item_6}</li>
                    </ul>
                </div>
            
    </>
  )
}

export default Skills