import React from 'react'
import Footer from '../../../Footer/Footer'
import Navbar from '../../../Navbar/Navbar'
import IMAGES from '../../../Images'


export const Con_ssl = () => {
  return (
    <>
        <Navbar/>
        <section className='template'>
            <div className='template-container container'>
            <h1>Setting SSL Certificate</h1>
            <h2>Current Lab Settings</h2>
            <p>We already have load balancer setup</p>
            <img src={IMAGES.AWS148} alt="" />  
            <p>Two webservers are running</p> 
            <img src={IMAGES.AWS149} alt="" />          
            <img src={IMAGES.AWS150} alt="" /> 
            <p>Connect Domain using Route53</p>         
            <img src={IMAGES.AWS151} alt="" />   
            <p>Create Alias Record : Alias to Application & Classic load balancer</p>       
            <img src={IMAGES.AWS152} alt="" />   
            <p>Our A record is now available</p>       
            <img src={IMAGES.AWS153} alt="" />      
            <p>Test URL, enter address : http://denim-district.com , else it will not work</p><br />
            <p>as you can see domain is connected with our load balancer, now next is to secure the website</p>    
            <img src={IMAGES.AWS154} alt="" />  
            <h2>AWS Certificate Manager</h2>        
            <img src={IMAGES.AWS155} alt="" />   
            <p>Request Certificate</p>              
            <img src={IMAGES.AWS156} alt="" />    
            <p>Enter Domain name, and select DNS as validation method.</p>      
            <img src={IMAGES.AWS157} alt="" />      
            <p>Your Certificate is now generated</p>    
            <img src={IMAGES.AWS158} alt="" />  
            <h2>Validate Certificate</h2>        
            <p>open the certificate and then click on create records in Route53</p>
            <img src={IMAGES.AWS159} alt="" /> 
            <p>if your domain is hosted on AWS DNS, then it will automatically verified, if its hosted on some other DNS, then you have to create DNS entries in your hosted zone.</p>         
            <img src={IMAGES.AWS160} alt="" />   
            <p>Domain is verified and certificate is now issued, as shown below.</p>       
            <img src={IMAGES.AWS161} alt="" />  
            <h2>Configure Certificate in ELB</h2>     
            <p>Open your load balancer and then click on listeners</p><br />
            <p><p>Click on Add listener </p>  </p>
            <img src={IMAGES.AWS162} alt="" />
                  
            <img src={IMAGES.AWS163} alt="" />          
            <p>Under default SSL/TLS certificate, select your certificate from ACM</p>
            <img src={IMAGES.AWS164} alt="" />          
            <img src={IMAGES.AWS165} alt="" /> 
            <h2>Configure Security Group</h2>      
            <p>Add HTTPs to your load balancer security group</p>   
            <img src={IMAGES.AWS166} alt="" />   
            <p>Test URL</p>       
            <img src={IMAGES.AWS167} alt="" />          
            <img src={IMAGES.AWS168} alt="" /> 
            <div className='template-container60rem' >
            <img src={IMAGES.AWS169} alt="" />          
            </div>   
            <p>ACM certificate status : when the certificate was not in use, the status for renewal eligibility was ineligible, now the status is issued</p>          
            <img src={IMAGES.AWS170} alt="" />   
            <h2>http to https redirection</h2>
            <p>if we type www.denim-district.com, it will go to unsecurewebsite</p>
            <img src={IMAGES.AWS171} alt="" />  
            <p>we need to redirect http to https.</p><br />
            <p>open load balancer and then click on listeners, edit the port 80 listener.</p>
            
            <img src={IMAGES.AWS172} alt="" />    
            <p>Current settings is set to Forward</p>
            <img src={IMAGES.AWS173} alt="" />       
            <p>Remove the Forward and add redirect to 443</p>   
            <img src={IMAGES.AWS174} alt="" />  
            <p>Test URL</p>                     
            <img src={IMAGES.AWS175} alt="" />          
            <img src={IMAGES.AWS176} alt="" />  
            <p>you can add cname www in dns, its for - Optional Settings</p>        
            <img src={IMAGES.AWS177} alt="" />   
            <h2>Tighten Security</h2>      
            <p>you can directly access ec2 instance via ip address, we need to stop this. We will remove the http and https from the security group of ec2 instance and then add http and https pointing to security group of load balancer, this way traffic will always be from the load balancer not the ec2 instance.</p>
            <img src={IMAGES.AWS178} alt="" />    
            <p>now if you try access from the ip address, it will not work
</p>      
            <img src={IMAGES.AWS179} alt="" />   
            <p>But it will work via Load Balancer.</p>       
            <img src={IMAGES.AWS180} alt="" />          
            <h2>More on SSL / TLS</h2>
            <img src={IMAGES.AWS141} alt="" />          
            <img src={IMAGES.AWS142} alt="" />          
            <img src={IMAGES.AWS143} alt="" />          
            <img src={IMAGES.AWS144} alt="" />          
            <img src={IMAGES.AWS145} alt="" />          
            <img src={IMAGES.AWS146} alt="" />          
            <img src={IMAGES.AWS147} alt="" />                      
            </div>
            <div className='template-buttons container'>
            <a href="/asg_policy"><button className='template-back'>Back</button></a>
            <a href="/con_addcloudfront"><button className='template-next'>Next</button></a>
            </div>
       </section>       
       <Footer/>
       
    </>
    
  )
}
