import React, { useState } from 'react'
import { AboutData } from './AboutData'
import About from './About'

const AboutIndex = () => {

  const [data, setData] = useState(AboutData)
  return (
    <>
     <section className='about'>
        <div className='about-heading'>
            <h1>Achievements</h1>
            
        </div>
        <div className='about-expand container'>        
        <p>Click + to expand</p><br /><br />
        </div>

        <div className='about-container container'>
        
        
        {
    data.map((items)=>(
        <About
         questions = {items.questions}
          answers = {items.answers}         
         key={items.id}
        />
    ))
}    
     </div>
     </section>
    </>
  )
}

export default AboutIndex

