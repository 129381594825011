import React from 'react';
import { NavLink } from 'react-router-dom';
import '../04/Contact04.css'

function Contact04() {
  return(
      <>
          <section className='sub04-contact'>
          <div className='sub04-contact-container container'>
                <div className='sub04-contact-items'>
                    <div className='sub04-contact-textbox1'>
                        <h1> Call Us</h1>
                        <p>+61 416466039</p>
                        <h1>LOCATION</h1>
                        <p>123 New South Wales Sydney Australia, 2000</p>
                        <h1>BUSINESS HOURS</h1>
                        <p>Mon - Fri …… 10 am - 8 pm, Sat, Sun ....… Closed</p>
    </div>
    <div className='sub04-contact-textbox2'>
        <input className='sub04-input' type="text" name="" id="" placeholder='Enter your Name' />
        <input className='sub04-input' type="Email" name="" id="" placeholder='Enter a Valid Email Address' />
        <textarea className='sub04-textarea' name="" id="" cols="30" rows="10"></textarea>
        <NavLink to="/contacts" className='sub04-btn-contact'>Submit</NavLink>
    </div>
                </div>
          </div>

          </section>
      </>
  )
}

export default Contact04;
