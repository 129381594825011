import React from 'react'
import '../01/Testi01.css'

const Testi01 = ({testi01Para,testi01Name,testi01Image}) => {
  return (
   <>
       
        <div className='testi01-items'>
                <div className='testi01-star'>
                <span className="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                </div>
                <div className='testi01-text'>
                    <p>{testi01Para}</p>
                </div>
                <div className='testi01-image'>
                    <img src={testi01Image} alt="" />
                </div>
                <div className='testi01-name'>
                    <p>{testi01Name}</p>    
                </div>
        </div>
            
       
   </>
  )
}

export default Testi01