import React from 'react'
import { NavLink } from 'react-router-dom'
import Footer from '../../../Footer/Footer'
import IMAGES from '../../../Images'
import Navbar from '../../../Navbar/Navbar'

export const Aws_3tier = () => {
  return (
    <>
    <Navbar/>
        <section className='template'>
            <div className='template-container container'>
            <h1>Three Tier Architecture</h1>           
            <h2>VPC Network</h2>            
            <img src={IMAGES.AWS96} alt="" />          
            <h2>Three Tier Network</h2>
            <img src={IMAGES.AWS97} alt="" />          
            </div>
            <div className='template-buttons container'>

            <NavLink to="/aws_directory"><button className='template-back'>Back</button></NavLink>

            <NavLink to="/aws_loadbalancer"><button className='template-next'>Next</button></NavLink>

            


            {/* <a href="https://ammar.tech/aws_directory"><button className='template-back'>Back</button></a>
            <a href="https://ammar.tech/aws_loadbalancer"><button className='template-next'>Next</button></a> */}
            </div>
       </section>
       <Footer/>
    </>
    
  )
}
