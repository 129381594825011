import React from 'react'
import '../04/Auth04.css'

const Auth04 = () => {
  return (
    <>
        
        <section className='auth04'>
        
       
            <div className='auth04-heading'>
            <div className='auth04-h1'>
        <h1>Forgot Password Card</h1>
        </div>
               
            </div>
            <div className='auth04-input'>
            <h1>Forgot your Password ?</h1>
                <p>You will get an email with a reset link</p>
         
            <input className='auth04-x' placeholder='your-email@example.com' type="Email"/>
          
            <button className='auth04-btn'>
            Request Reset
            </button>         

            </div>
        </section>
    </>
  )
}

export default Auth04