const IMAGES = {
    AWS01 : require('./aws/iam/IAM (1).png'),
    AWS02 : require('./aws/iam/IAM (2).png'),
    AWS03 : require('./aws/iam/IAM (3).png'),
    AWS04 : require('./aws/iam/IAM (4).png'),
    AWS05 : require('./aws/iam/IAM (5).png'),
    AWS06 : require('./aws/iam/IAM (6).png'),
    AWS07 : require('./aws/iam/IAM (7).png'),
    AWS08 : require('./aws/iam/IAM (8).png'),
    AWS09 : require('./aws/iam/IAM (9).png'),
    AWS10 : require('./aws/iam/IAM (10).png'),
    AWS11 : require('./aws/iam/IAM (11).png'),
    AWS12 : require('./aws/iam/IAM (12).png'),
    AWS13 : require('./aws/iam/IAM (13).png'),
    AWS14 : require('./aws/iam/IAM (14).png'),
    AWS15 : require('./aws/iam/IAM (15).png'),
    AWS16 : require('./aws/iam/IAM (16).png'),
    AWS17 : require('./aws/iam/IAM (17).png'),
    AWS18 : require('./aws/iam/IAM (18).png'),
    AWS19 : require('./aws/iam/IAM (19).png'),
    AWS20 : require('./aws/iam/IAM (20).png'),
    AWS21 : require('./aws/iam/IAM (21).png'),
    AWS22 : require('./aws/iam/signinURL (1).png'),
    AWS23 : require('./aws/iam/signinURL (2).png'),
    AWS24 : require('./aws/iam/signinURL (3).png'),
    AWS25 : require('./aws/iam/signinURL (4).png'),
    AWS26 : require('./aws/iam/signinURL (5).png'),
    AWS27 : require('./aws/iam/signinURL (6).png'),
    AWS28 : require('./aws/iam/AK (1).png'),
    AWS29 : require('./aws/iam/AK (2).png'),
    AWS30 : require('./aws/iam/AK (3).png'),
    AWS31 : require('./aws/iam/AK (4).png'),
    AWS32 : require('./aws/iam/AK (5).png'),
    AWS33 : require('./aws/iam/AK (6).png'),
    AWS34 : require('./aws/iam/AK (7).png'),
    AWS35 : require('./aws/iam/AK (8).png'),
    AWS36 : require('./aws/iam/AK (9).png'),
    AWS37 : require('./aws/iam/AK (10).png'),
    AWS38 : require('./aws/iam/AK (11).png'),
    AWS39 : require('./aws/iam/AK (12).png'),
    AWS40 : require('./aws/iam/AK (13).png'),
    AWS41 : require('./aws/iam/AK (14).png'),
    AWS42 : require('./aws/iam/AK (15).png'),
    AWS43 : require('./aws/iam/AK (16).png'),
    AWS44 : require('./aws/iam/AK (17).png'),
    AWS45 : require('./aws/iam/AK (18).png'),
    AWS46 : require('./aws/iam/AK (19).png'),
    AWS47 : require('./aws/iam/AK (20).png'),
    AWS48 : require('./aws/iam/IAM_roles (1).png'),
    AWS49 : require('./aws/iam/IAM_roles (2).png'),
    AWS50 : require('./aws/iam/IAM_roles (3).png'),
    AWS51 : require('./aws/iam/IAM_roles (4).png'),
    AWS52 : require('./aws/iam/IAM_roles (5).png'),
    AWS53 : require('./aws/iam/IAM_roles (6).png'),
    AWS54 : require('./aws/iam/iam_policy (1).png'),
    AWS55 : require('./aws/iam/iam_policy (2).png'),
    AWS56 : require('./aws/iam/iam_policy (3).png'),
    AWS57 : require('./aws/iam/iam_policy (4).png'),
    AWS58 : require('./aws/iam/iam_policy (5).png'),
    AWS59 : require('./aws/iam/iam_policy (6).png'),
    AWS60 : require('./aws/iam/iam_policy (7).png'),
    AWS61 : require('./aws/iam/iam_policy (8).png'),
    AWS62 : require('./aws/iam/iam_policy (9).png'),
    AWS63 : require('./aws/iam/iam_policy (10).png'),
    AWS64 : require('./aws/iam/iam_policy (11).png'),
    AWS65 : require('./aws/iam/iam_policy (12).png'),
    AWS66 : require('./aws/iam/iam_policy (13).png'),
    AWS67 : require('./aws/iam/iam_policy (14).png'),
    AWS68 : require('./aws/iam/iam_policy (15).png'),
    AWS69 : require('./aws/iam/IAM_sec (1).png'),
    AWS70 : require('./aws/iam/IAM_sec (2).png'),
    AWS71 : require('./aws/iam/IAM_sec (3).png'),
    AWS72 : require('./aws/iam/IAM_sec (4).png'),
    AWS73 : require('./aws/iam/IAM_sec (5).png'),
    AWS74 : require('./aws/iam/IAM_sec (6).png'),
    AWS75 : require('./aws/iam/IAM_sec (7).png'),
    AWS76 : require('./aws/iam/IAM_sec (8).png'),
    AWS77 : require('./aws/iam/IAM_sec (9).png'),
    AWS78 : require('./aws/iam/IAM_sec (10).png'),
    AWS79 : require('./aws/iam/iam_meta (1).png'),
    AWS80 : require('./aws/iam/iam_meta (2).png'),
    AWS81 : require('./aws/iam/iam_meta (3).png'),
    AWS82 : require('./aws/iam/iam_meta (4).png'),
    AWS83 : require('./aws/iam/iam_meta (5).png'),
    AWS84 : require('./aws/iam/iam_meta (6).png'),
    AWS85 : require('./aws/iam/iam_sdk (1).png'),
    AWS86 : require('./aws/iam/iam_sdk (2).png'),
    AWS87 : require('./aws/iam/iam_billing (1).png'),
    AWS88 : require('./aws/iam/iam_billing (2).png'),
    AWS89 : require('./aws/iam/iam_billing (3).png'),
    AWS90 : require('./aws/iam/iam_billing (4).png'),
    AWS91 : require('./aws/iam/iam_billing (5).png'),
    AWS92 : require('./aws/iam/iam_billing (6).png'),
    AWS93 : require('./aws/iam/iam_billing (7).png'),
    AWS94 : require('./aws/iam/iam_billing (8).png'),
// AWS Solutions
    AWS95 : require('./aws/solutions/ad.png'),
    AWS96 : require('./aws/solutions/threetier-vpc.png'),
    AWS97 : require('./aws/solutions/threetier.png'),
    AWS98 : require('./aws/solutions/loadbalancer.png'),
    AWS99 : require('./aws/solutions/loadbalancer2.png'),
    AWS100 : require('./aws/solutions/cloudfront.png'),
    AWS101 : require('./aws/solutions/clienttosite.png'),
    AWS102 : require('./aws/solutions/sitetosite.png'), 
// Autoscaling with Load Balancer
    AWS103 : require('./aws/solutions/aws_autoscaling (1).png'), 
    AWS104 : require('./aws/solutions/aws_autoscaling (2).png'), 
    AWS105 : require('./aws/solutions/aws_autoscaling (3).png'), 
    AWS106 : require('./aws/solutions/aws_autoscaling (4).png'), 
    AWS107 : require('./aws/solutions/aws_autoscaling (5).png'), 
    AWS108 : require('./aws/solutions/aws_autoscaling (6).png'), 
    AWS109 : require('./aws/solutions/aws_autoscaling (7).png'), 
    AWS110 : require('./aws/solutions/aws_autoscaling (8).png'), 
    AWS111 : require('./aws/solutions/aws_autoscaling (9).png'), 
    AWS112 : require('./aws/solutions/aws_autoscaling (10).png'), 
    AWS113 : require('./aws/solutions/aws_autoscaling (11).png'), 
    AWS114 : require('./aws/solutions/aws_autoscaling (12).png'), 
    AWS115 : require('./aws/solutions/aws_autoscaling (13).png'), 
    AWS116 : require('./aws/solutions/aws_autoscaling (14).png'), 
    AWS117 : require('./aws/solutions/aws_autoscaling (15).png'), 
    AWS118 : require('./aws/solutions/aws_autoscaling (16).png'), 
    AWS119 : require('./aws/solutions/aws_autoscaling (17).png'), 
    AWS120 : require('./aws/solutions/aws_autoscaling (18).png'), 
    AWS121 : require('./aws/solutions/aws_autoscaling (19).png'), 
    AWS122 : require('./aws/solutions/aws_autoscaling (20).png'), 
    AWS123 : require('./aws/solutions/aws_autoscaling (21).png'), 
    AWS124 : require('./aws/solutions/aws_autoscaling (22).png'), 
    AWS125 : require('./aws/solutions/aws_autoscaling (23).png'), 
    AWS126 : require('./aws/solutions/aws_autoscaling (24).png'), 
    AWS127 : require('./aws/solutions/aws_autoscaling (25).png'), 
    AWS128 : require('./aws/solutions/aws_autoscaling (26).png'), 
    AWS129 : require('./aws/solutions/aws_autoscaling (27).png'), 
    AWS130 : require('./aws/solutions/aws_autoscaling (28).png'), 
    AWS131 : require('./aws/solutions/aws_autoscaling (29).png'), 
    AWS132 : require('./aws/solutions/aws_autoscaling (30).png'), 
    AWS133 : require('./aws/solutions/aws_autoscaling (31).png'), 
    AWS134 : require('./aws/solutions/aws_autoscaling (32).png'), 
    AWS135 : require('./aws/solutions/aws_autoscaling (33).png'), 
    AWS136 : require('./aws/solutions/aws_autoscaling (34).png'), 
    AWS137 : require('./aws/solutions/aws_autoscaling (35).png'), 
    AWS138 : require('./aws/solutions/aws_autoscaling (36).png'), 
    AWS139 : require('./aws/solutions/aws_autoscaling (37).png'), 
    AWS140 : require('./aws/solutions/aws_autoscaling (38).png'),     
    // AWS SSL Setup
    AWS141 : require('./aws/solutions/aws_ssl (1).png'),  
    AWS142 : require('./aws/solutions/aws_ssl (2).png'),  
    AWS143 : require('./aws/solutions/aws_ssl (3).png'),  
    AWS144 : require('./aws/solutions/aws_ssl (4).png'),  
    AWS145 : require('./aws/solutions/aws_ssl (5).png'),  
    AWS146 : require('./aws/solutions/aws_ssl (6).png'),  
    AWS147 : require('./aws/solutions/aws_ssl (7).png'),  
    AWS148 : require('./aws/solutions/aws_ssl (8).png'),  
    AWS149 : require('./aws/solutions/aws_ssl (9).png'),  
    AWS150 : require('./aws/solutions/aws_ssl (10).png'),  
    AWS151 : require('./aws/solutions/aws_ssl (11).png'),  
    AWS152 : require('./aws/solutions/aws_ssl (12).png'),  
    AWS153 : require('./aws/solutions/aws_ssl (13).png'),  
    AWS154 : require('./aws/solutions/aws_ssl (14).png'),  
    AWS155 : require('./aws/solutions/aws_ssl (15).png'),  
    AWS156 : require('./aws/solutions/aws_ssl (16).png'),  
    AWS157 : require('./aws/solutions/aws_ssl (17).png'),  
    AWS158 : require('./aws/solutions/aws_ssl (18).png'),  
    AWS159 : require('./aws/solutions/aws_ssl (19).png'),  
    AWS160 : require('./aws/solutions/aws_ssl (20).png'),  
    AWS161 : require('./aws/solutions/aws_ssl (21).png'),  
    AWS162 : require('./aws/solutions/aws_ssl (22).png'),     
    AWS163 : require('./aws/solutions/aws_ssl (23).png'),  
    AWS164 : require('./aws/solutions/aws_ssl (24).png'),  
    AWS165 : require('./aws/solutions/aws_ssl (25).png'),  
    AWS166 : require('./aws/solutions/aws_ssl (26).png'),  
    AWS167 : require('./aws/solutions/aws_ssl (27).png'),  
    AWS168 : require('./aws/solutions/aws_ssl (28).png'),  
    AWS169 : require('./aws/solutions/aws_ssl (29).png'),  
    AWS170 : require('./aws/solutions/aws_ssl (30).png'),  
    AWS171 : require('./aws/solutions/aws_ssl (31).png'),  
    AWS172 : require('./aws/solutions/aws_ssl (32).png'),  
    AWS173 : require('./aws/solutions/aws_ssl (33).png'),  
    AWS174 : require('./aws/solutions/aws_ssl (34).png'),  
    AWS175 : require('./aws/solutions/aws_ssl (35).png'),  
    AWS176 : require('./aws/solutions/aws_ssl (36).png'),  
    AWS177 : require('./aws/solutions/aws_ssl (37).png'),  
    AWS178 : require('./aws/solutions/aws_ssl (38).png'),  
    AWS179 : require('./aws/solutions/aws_ssl (39).png'),  
    AWS180 : require('./aws/solutions/aws_ssl (40).png'),  
//Application Load Balancer
    AWS181 : require('./aws/solutions/alb (1).png'),  
    AWS182 : require('./aws/solutions/alb (2).png'),  
    AWS183 : require('./aws/solutions/alb (3).png'),  
    AWS184 : require('./aws/solutions/alb (4).png'),  
    AWS185 : require('./aws/solutions/alb (5).png'),  
    AWS186 : require('./aws/solutions/alb (6).png'),  
    AWS187 : require('./aws/solutions/alb (7).png'),  
    AWS188 : require('./aws/solutions/alb (8).png'),  
    AWS189 : require('./aws/solutions/alb (9).png'),  
    AWS190 : require('./aws/solutions/alb (10).png'),  
    AWS191 : require('./aws/solutions/alb (11).png'),  
    AWS192 : require('./aws/solutions/alb (12).png'),  
    AWS193 : require('./aws/solutions/alb (13).png'),  
    AWS194 : require('./aws/solutions/alb (14).png'),  
    AWS195 : require('./aws/solutions/alb (15).png'),  
    AWS196 : require('./aws/solutions/alb (16).png'),  
    AWS197 : require('./aws/solutions/alb (17).png'),  
    AWS198 : require('./aws/solutions/alb (18).png'),  
    AWS199 : require('./aws/solutions/alb (19).png'),  
    AWS200 : require('./aws/solutions/alb (20).png'),  
    AWS201 : require('./aws/solutions/alb (21).png'),  
    AWS202 : require('./aws/solutions/alb (22).png'),  
    AWS203 : require('./aws/solutions/alb (23).png'),  
    AWS204 : require('./aws/solutions/alb (24).png'),  
    AWS205 : require('./aws/solutions/alb (25).png'),  
    AWS206 : require('./aws/solutions/alb (26).png'),  
    AWS207 : require('./aws/solutions/alb (27).png'),  
    AWS208 : require('./aws/solutions/alb (28).png'),  
    AWS209 : require('./aws/solutions/alb (29).png'),  
    AWS210 : require('./aws/solutions/alb (30).png'),  
// Connnect Load Balancer with Cloudfront
    AWS211 : require('./aws/solutions/con_cloudfront (1).png'),  
    AWS212 : require('./aws/solutions/con_cloudfront (2).png'),  
    AWS213 : require('./aws/solutions/con_cloudfront (3).png'),  
    AWS214 : require('./aws/solutions/con_cloudfront (4).png'),  
    AWS215 : require('./aws/solutions/con_cloudfront (5).png'),  
    AWS216 : require('./aws/solutions/con_cloudfront (6).png'),  
    AWS217 : require('./aws/solutions/con_cloudfront (7).png'),  
    AWS218 : require('./aws/solutions/con_cloudfront (8).png'),  
    AWS219 : require('./aws/solutions/con_cloudfront (9).png'),  
    AWS220 : require('./aws/solutions/con_cloudfront (10).png'),  
    AWS221 : require('./aws/solutions/con_cloudfront (11).png'),  
    AWS222 : require('./aws/solutions/con_cloudfront (12).png'),  
    AWS223 : require('./aws/solutions/con_cloudfront (13).png'),  
    AWS224 : require('./aws/solutions/con_cloudfront (14).png'),  
    AWS225 : require('./aws/solutions/con_cloudfront (15).png'),  
    AWS226 : require('./aws/solutions/con_cloudfront (16).png'),  
    AWS227 : require('./aws/solutions/con_cloudfront (17).png'),  
    AWS228 : require('./aws/solutions/con_cloudfront (18).png'),  
    AWS229 : require('./aws/solutions/con_cloudfront (19).png'),  
    AWS230 : require('./aws/solutions/con_cloudfront (20).png'),  
    // High Availability  hs (1)
    AWS231 : require('./aws/solutions/hs (1).png'),  
    AWS232 : require('./aws/solutions/hs (2).png'),  
    AWS233 : require('./aws/solutions/hs (3).png'),  
    AWS234 : require('./aws/solutions/hs (4).png'),  
    AWS235 : require('./aws/solutions/hs (5).png'), 
    // Elb 
    AWS236 : require('./aws/solutions/elb (1).png'),  
    AWS237 : require('./aws/solutions/elb (2).png'),  
    AWS238 : require('./aws/solutions/elb (3).png'),  
    AWS239 : require('./aws/solutions/elb (4).png'),  
    AWS240 : require('./aws/solutions/elb (5).png'),  
    AWS241 : require('./aws/solutions/elb (6).png'),  
    AWS242 : require('./aws/solutions/elb (7).png'),  
    AWS243 : require('./aws/solutions/elb (8).png'),  
    AWS244 : require('./aws/solutions/elb (9).png'),  
    AWS245 : require('./aws/solutions/elb (10).png'),  
    AWS246 : require('./aws/solutions/elb (11).png'),  
    AWS247 : require('./aws/solutions/elb (12).png'),  
    AWS248 : require('./aws/solutions/elb (13).png'),  
    AWS249 : require('./aws/solutions/elb (14).png'),  
    AWS250 : require('./aws/solutions/elb (15).png'),  
    AWS251 : require('./aws/solutions/elb (16).png'),  
    AWS252 : require('./aws/solutions/elb (17).png'), 
    // CLB
    AWS253 : require('./aws/solutions/clb (1).png'),  
    AWS254 : require('./aws/solutions/clb (2).png'),  
    AWS255 : require('./aws/solutions/clb (3).png'),  
    AWS256 : require('./aws/solutions/clb (4).png'),  
    AWS257 : require('./aws/solutions/clb (5).png'),  
    AWS258 : require('./aws/solutions/clb (6).png'),  
    AWS259 : require('./aws/solutions/clb (7).png'),  
    AWS260 : require('./aws/solutions/clb (8).png'),  
    AWS261 : require('./aws/solutions/clb (9).png'),  
    AWS262 : require('./aws/solutions/clb (10).png'),  
    AWS263 : require('./aws/solutions/clb (11).png'),  
    AWS264 : require('./aws/solutions/clb (12).png'),  
    AWS265 : require('./aws/solutions/clb (13).png'),  
    AWS266 : require('./aws/solutions/clb (14).png'),  
    AWS267 : require('./aws/solutions/clb (15).png'),  
    AWS268 : require('./aws/solutions/clb (16).png'),  
    AWS269 : require('./aws/solutions/clb (17).png'),  
    AWS270 : require('./aws/solutions/clb (18).png'),  
    AWS271 : require('./aws/solutions/clb (19).png'),  
    AWS272 : require('./aws/solutions/clb (20).png'),  
    AWS273 : require('./aws/solutions/clb (21).png'),  
    AWS274 : require('./aws/solutions/clb (22).png'),  
    AWS275 : require('./aws/solutions/clb (23).png'),  
    AWS276 : require('./aws/solutions/clb (24).png'),  
    AWS277 : require('./aws/solutions/clb (25).png'),  
    //Alb Overview
    AWS278 : require('./aws/solutions/albOverview (1).png'),  
    AWS279 : require('./aws/solutions/albOverview (2).png'),  
    AWS280 : require('./aws/solutions/albOverview (3).png'),  
    AWS281 : require('./aws/solutions/albOverview (4).png'),  
    AWS282 : require('./aws/solutions/albOverview (5).png'),  
    AWS283 : require('./aws/solutions/albOverview (6).png'),  
    // NLB
    AWS284 : require('./aws/solutions/nlb (1).png'),  
    AWS285 : require('./aws/solutions/nlb (2).png'),  
    AWS286 : require('./aws/solutions/nlb (3).png'),  
    AWS287 : require('./aws/solutions/nlb (4).png'),  
    AWS288 : require('./aws/solutions/nlb (5).png'),  
    AWS289 : require('./aws/solutions/nlb (6).png'),  
    AWS290 : require('./aws/solutions/nlb (7).png'),  
    AWS291 : require('./aws/solutions/nlb (8).png'),  
    AWS292 : require('./aws/solutions/nlb (9).png'),  
    AWS293 : require('./aws/solutions/nlb (10).png'),  
    AWS294 : require('./aws/solutions/nlb (11).png'),  
    AWS295 : require('./aws/solutions/nlb (12).png'),  
    AWS296 : require('./aws/solutions/nlb (13).png'),  
    AWS297 : require('./aws/solutions/nlb (14).png'),  
    AWS298 : require('./aws/solutions/nlb (15).png'),  
    AWS299 : require('./aws/solutions/nlb (16).png'),  
    AWS300 : require('./aws/solutions/nlb (17).png'),  
    AWS301 : require('./aws/solutions/nlb (18).png'),  
    AWS302 : require('./aws/solutions/nlb (19).png'),
    AWS303 : require('./aws/solutions/sticky (1).png'),
    AWS304 : require('./aws/solutions/sticky (2).png'),
    AWS305 : require('./aws/solutions/sticky (3).png'),
    AWS306 : require('./aws/solutions/sticky (4).png'), 
    //GW load balancer
    AWS307 : require('./aws/solutions/GWLB (1).png'), 
    AWS308 : require('./aws/solutions/GWLB (2).png'),    
    // Autoscaling Overview
    AWS309 : require('./aws/solutions/asg_o (1).png'),    
    AWS310 : require('./aws/solutions/asg_o (2).png'),    
    AWS311 : require('./aws/solutions/asg_o (3).png'),    
    AWS312 : require('./aws/solutions/asg_o (4).png'),    
    AWS313 : require('./aws/solutions/asg_o (5).png'),    
    AWS314 : require('./aws/solutions/asg_o (6).png'),    
    AWS315 : require('./aws/solutions/asg_o (7).png'),    
    AWS316 : require('./aws/solutions/asg_o (8).png'),  
    // ASG Policy
    AWS317 : require('./aws/solutions/asg_policy (1).png'),  
    AWS318 : require('./aws/solutions/asg_policy (2).png'),  
    AWS319 : require('./aws/solutions/asg_policy (3).png'),  
    AWS320 : require('./aws/solutions/asg_policy (4).png'),  
    AWS321 : require('./aws/solutions/asg_policy (5).png'),  
    AWS322 : require('./aws/solutions/asg_policy (6).png'),  
    AWS323 : require('./aws/solutions/asg_policy (7).png'),  
    AWS324 : require('./aws/solutions/asg_policy (8).png'),  
    AWS325 : require('./aws/solutions/asg_policy (9).png'),  
    AWS326 : require('./aws/solutions/asg_policy (10).png'),  
    AWS327 : require('./aws/solutions/asg_policy (11).png'),  
    AWS328 : require('./aws/solutions/asg_policy (12).png'),  
    AWS329 : require('./aws/solutions/asg_policy (13).png'),  
    AWS330 : require('./aws/solutions/asg_policy (14).png'),  
    AWS331 : require('./aws/solutions/asg_policy (15).png'),  
    AWS332 : require('./aws/solutions/asg_policy (16).png'),  
    AWS333 : require('./aws/solutions/asg_policy (17).png'),  
    AWS334 : require('./aws/solutions/asg_policy (18).png'),  
    AWS335 : require('./aws/solutions/asg_policy (19).png'),  
    AWS336 : require('./aws/solutions/asg_policy (20).png'),  
    AWS337 : require('./aws/solutions/asg_policy (21).png'),  
    //Azure Virtual Machine
    AWS338 : require('./Azure/vm/vm_setup (1).jpg'),  
    AWS339 : require('./Azure/vm/vm_setup (2).jpg'),  
    AWS340 : require('./Azure/vm/vm_setup (3).jpg'),  
    AWS341 : require('./Azure/vm/vm_setup (4).jpg'),  
    AWS342 : require('./Azure/vm/vm_setup (5).jpg'),  
    AWS343 : require('./Azure/vm/vm_setup (6).jpg'),  
    AWS344 : require('./Azure/vm/vm_setup (7).jpg'),  
    AWS345 : require('./Azure/vm/vm_setup (8).jpg'),  
    AWS346 : require('./Azure/vm/vm_setup (9).jpg'),  
    AWS347 : require('./Azure/vm/vm_setup (10).jpg'),  
    AWS348 : require('./Azure/vm/vm_setup (11).jpg'),  
    AWS349 : require('./Azure/vm/vm_setup (12).jpg'),  
    AWS350 : require('./Azure/vm/vm_setup (13).jpg'),  
    AWS351 : require('./Azure/vm/vm_setup (14).jpg'),  
    AWS352 : require('./Azure/vm/vm_setup (15).jpg'),  
    AWS353 : require('./Azure/vm/vm_setup (16).jpg'),  
    AWS354 : require('./Azure/vm/vm_setup (17).jpg'),  
    AWS355 : require('./Azure/vm/vm_setup (18).jpg'),  
    AWS356 : require('./Azure/vm/vm_setup (19).jpg'),  
    AWS357 : require('./Azure/vm/vm_setup (20).jpg'),  
    AWS358 : require('./Azure/vm/vm_setup (21).jpg'),  
    AWS359 : require('./Azure/vm/vm_setup (22).jpg'),  
    AWS360 : require('./Azure/vm/vm_setup (23).jpg'),  
    AWS361 : require('./Azure/vm/vm_setup (24).jpg'),  
    AWS362 : require('./Azure/vm/vm_setup (25).jpg'),  
    // Azure VM Adding Disk
    AWS363 : require('./Azure/vm/vm_addisk (1).jpg'),  
    AWS364 : require('./Azure/vm/vm_addisk (2).jpg'),  
    AWS365 : require('./Azure/vm/vm_addisk (3).jpg'),  
    AWS366 : require('./Azure/vm/vm_addisk (4).jpg'),  
    AWS367 : require('./Azure/vm/vm_addisk (5).jpg'),  
    AWS368 : require('./Azure/vm/vm_addisk (6).jpg'),  
    AWS369 : require('./Azure/vm/vm_addisk (7).jpg'),  
    AWS370 : require('./Azure/vm/vm_addisk (8).jpg'),  
    AWS371 : require('./Azure/vm/vm_addisk (9).jpg'),  
    // Azure Custom Script
    AWS372 : require('./Azure/vm/vm_cs (1).jpg'),  
    AWS373 : require('./Azure/vm/vm_cs (2).jpg'),  
    AWS374 : require('./Azure/vm/vm_cs (3).jpg'),  
    AWS375 : require('./Azure/vm/vm_cs (4).jpg'),  
    AWS376 : require('./Azure/vm/vm_cs (5).jpg'),  
    AWS377 : require('./Azure/vm/vm_cs (6).jpg'),  
    AWS378 : require('./Azure/vm/vm_cs (7).jpg'),  
    AWS379 : require('./Azure/vm/vm_cs (8).jpg'),  
    AWS380 : require('./Azure/vm/vm_cs (9).jpg'),  
    AWS381 : require('./Azure/vm/vm_cs (10).jpg'),  
    AWS382 : require('./Azure/vm/vm_cs (11).jpg'),  
    AWS383 : require('./Azure/vm/vm_cs (12).jpg'),  
    AWS384 : require('./Azure/vm/vm_cs (13).jpg'),  
    AWS385 : require('./Azure/vm/vm_cs (14).jpg'),  
    AWS386 : require('./Azure/vm/vm_cs (15).jpg'),  
    AWS387 : require('./Azure/vm/vm_cs (16).jpg'),  
    AWS388 : require('./Azure/vm/vm_cs (17).jpg'),   
    // Azure Capture Image
    AWS389 : require('./Azure/vm/vm_ci (1).jpg'),  
    AWS390 : require('./Azure/vm/vm_ci (2).jpg'),   
    AWS391 : require('./Azure/vm/vm_ci (3).jpg'),   
    AWS392 : require('./Azure/vm/vm_ci (4).jpg'),   
    AWS393 : require('./Azure/vm/vm_ci (5).jpg'),   
    AWS394 : require('./Azure/vm/vm_ci (6).jpg'),   
    AWS395 : require('./Azure/vm/vm_ci (7).jpg'),   
    AWS396 : require('./Azure/vm/vm_ci (8).jpg'),   
    AWS397 : require('./Azure/vm/vm_ci (9).jpg'),   
    AWS398 : require('./Azure/vm/vm_ci (10).jpg'),   
    AWS399 : require('./Azure/vm/vm_ci (11).jpg'),   
    AWS400 : require('./Azure/vm/vm_ci (12).jpg'),   
    AWS401 : require('./Azure/vm/vm_ci (13).jpg'),    
    AWS402 : require('./Azure/vm/vm_ci (14).jpg'),   
    AWS403 : require('./Azure/vm/vm_ci (15).jpg'),   
    AWS404 : require('./Azure/vm/vm_ci (16).jpg'),   
    AWS405 : require('./Azure/vm/vm_ci (17).jpg'),   
    AWS406 : require('./Azure/vm/vm_ci (18).jpg'),   
    AWS407 : require('./Azure/vm/vm_ci (19).jpg'),   
    AWS408 : require('./Azure/vm/vm_ci (20).jpg'),   
    AWS409 : require('./Azure/vm/vm_ci (21).jpg'),   
    AWS410 : require('./Azure/vm/vm_ci (22).jpg'),   
    AWS411 : require('./Azure/vm/vm_ci (23).jpg'),   
    AWS412 : require('./Azure/vm/vm_ci (24).jpg'),   
    AWS413 : require('./Azure/vm/vm_ci (25).jpg'),   
    AWS414 : require('./Azure/vm/vm_ci (26).jpg'),   
    AWS415 : require('./Azure/vm/vm_ci (27).jpg'),   
    AWS416 : require('./Azure/vm/vm_ci (28).jpg'),   
    AWS417 : require('./Azure/vm/vm_ci (29).jpg'),   
    AWS418 : require('./Azure/vm/vm_ci (30).jpg'),   
    AWS419 : require('./Azure/vm/vm_ci (31).jpg'),   
    AWS420 : require('./Azure/vm/vm_ci (32).jpg'),   
    //Azure VM ip addressing
    AWS421 : require('./Azure/vm/vm_ip (1).jpg'),   
    AWS422 : require('./Azure/vm/vm_ip (2).jpg'),   
    AWS423 : require('./Azure/vm/vm_ip (3).jpg'),   
    AWS424 : require('./Azure/vm/vm_ip (4).jpg'),   
    AWS425 : require('./Azure/vm/vm_ip (5).jpg'),   
    AWS426 : require('./Azure/vm/vm_ip (6).jpg'),   
    AWS427 : require('./Azure/vm/vm_ip (7).jpg'),   
    AWS428 : require('./Azure/vm/vm_ip (8).jpg'),   
    AWS429 : require('./Azure/vm/vm_ip (9).jpg'),   
    AWS430 : require('./Azure/vm/vm_ip (10).jpg'),   
    AWS431 : require('./Azure/vm/vm_ip (11).jpg'),   
    AWS432 : require('./Azure/vm/vm_ip (12).jpg'),   
    AWS433 : require('./Azure/vm/vm_ip (13).jpg'),   
    AWS434 : require('./Azure/vm/vm_ip (14).jpg'),   
    AWS435 : require('./Azure/vm/vm_ip (15).jpg'),   
    AWS436 : require('./Azure/vm/vm_ip (16).jpg'),  
    //Azure vm snapshot
    AWS437 : require('./Azure/vm/vm_snapshot (1).jpg'),  
    AWS438 : require('./Azure/vm/vm_snapshot (2).jpg'),  
    AWS439 : require('./Azure/vm/vm_snapshot (3).jpg'),  
    AWS440 : require('./Azure/vm/vm_snapshot (4).jpg'),  
    AWS441 : require('./Azure/vm/vm_snapshot (5).jpg'),  
    AWS442 : require('./Azure/vm/vm_snapshot (6).jpg'),  
    AWS443 : require('./Azure/vm/vm_snapshot (7).jpg'),  
    AWS444 : require('./Azure/vm/vm_snapshot (8).jpg'),  
    AWS445 : require('./Azure/vm/vm_snapshot (9).jpg'),  
    AWS446 : require('./Azure/vm/vm_snapshot (10).jpg'),  
    AWS447 : require('./Azure/vm/vm_snapshot (11).jpg'),  
    AWS448 : require('./Azure/vm/vm_snapshot (12).jpg'),  
    AWS449 : require('./Azure/vm/vm_snapshot (13).jpg'),  
    AWS450 : require('./Azure/vm/vm_snapshot (14).jpg'),  
    AWS451 : require('./Azure/vm/vm_snapshot (15).jpg'),  
    AWS452 : require('./Azure/vm/vm_snapshot (16).jpg'),  
    AWS453 : require('./Azure/vm/vm_snapshot (17).jpg'),  
    AWS454 : require('./Azure/vm/vm_snapshot (18).jpg'),  
    AWS455 : require('./Azure/vm/vm_snapshot (19).jpg'),  
    AWS456 : require('./Azure/vm/vm_snapshot (20).jpg'),  
    AWS457 : require('./Azure/vm/vm_snapshot (21).jpg'),  
    AWS458 : require('./Azure/vm/vm_snapshot (22).jpg'),  
    AWS459 : require('./Azure/vm/vm_snapshot (23).jpg'),  
    AWS460 : require('./Azure/vm/vm_snapshot (24).jpg'),  
    AWS461 : require('./Azure/vm/vm_snapshot (25).jpg'),  
    AWS462 : require('./Azure/vm/vm_snapshot (26).jpg'),  
    AWS463 : require('./Azure/vm/vm_snapshot (27).jpg'),  
    AWS464 : require('./Azure/vm/vm_snapshot (28).jpg'),  
    AWS465 : require('./Azure/vm/vm_snapshot (29).jpg'),  
    AWS466 : require('./Azure/vm/vm_snapshot (30).jpg'),  
    AWS467 : require('./Azure/vm/vm_snapshot (31).jpg'),  
    // Azure VM Proximity
    AWS468 : require('./Azure/vm/vm_proximity (1).jpg'),  
    AWS469 : require('./Azure/vm/vm_proximity (2).jpg'),  
    AWS470 : require('./Azure/vm/vm_proximity (3).jpg'),  
    AWS471 : require('./Azure/vm/vm_proximity (4).jpg'),  
    AWS472 : require('./Azure/vm/vm_proximity (5).jpg'),  
    AWS473 : require('./Azure/vm/vm_proximity (6).jpg'),  
    AWS474 : require('./Azure/vm/vm_proximity (7).jpg'),  
    AWS475 : require('./Azure/vm/vm_proximity (8).jpg'),  
    AWS476 : require('./Azure/vm/vm_proximity (9).jpg'),  
    AWS477 : require('./Azure/vm/vm_proximity (10).jpg'),
    AWSE477 : require('./Azure/vm/vm_proximity (11).jpg'),  
    // Azure Unamanged disks
    AWS478 : require('./Azure/vm/vm_ud (1).jpg'),  
    AWS479 : require('./Azure/vm/vm_ud (2).jpg'),  
    AWS480 : require('./Azure/vm/vm_ud (3).jpg'),  
    AWS481 : require('./Azure/vm/vm_ud (4).jpg'),  
    AWS482 : require('./Azure/vm/vm_ud (5).jpg'),  
    AWS483 : require('./Azure/vm/vm_ud (6).jpg'),  
    AWS484 : require('./Azure/vm/vm_ud (7).jpg'),  
    AWS485 : require('./Azure/vm/vm_ud (8).jpg'),  
    AWS486 : require('./Azure/vm/vm_ud (9).jpg'),  
    AWS487 : require('./Azure/vm/vm_ud (10).jpg'),  
    AWS488 : require('./Azure/vm/vm_ud (11).jpg'),  
    AWS489 : require('./Azure/vm/vm_ud (12).jpg'),  
    AWS490 : require('./Azure/vm/vm_ud (13).jpg'),  
    AWS491 : require('./Azure/vm/vm_ud (14).jpg'),  
    AWS492 : require('./Azure/vm/vm_ud (15).jpg'),  
    AWS493 : require('./Azure/vm/vm_ud (16).jpg'),  
    AWS494 : require('./Azure/vm/vm_ud (17).jpg'),  
    AWS495 : require('./Azure/vm/vm_ud (18).jpg'),  
    AWS496 : require('./Azure/vm/vm_ud (19).jpg'),  
    AWS497 : require('./Azure/vm/vm_ud (20).jpg'),  
    AWS498 : require('./Azure/vm/vm_ud (21).jpg'),  
    // Azure Boot Diagnostics
    AWSE498 : require('./Azure/vm/vm_bd (1).jpg'),  
    AWS499 : require('./Azure/vm/vm_bd (2).jpg'),  
    AWS500 : require('./Azure/vm/vm_bd (3).jpg'),  
    AWS501 : require('./Azure/vm/vm_bd (4).jpg'),  
    AWS502 : require('./Azure/vm/vm_bd (5).jpg'),  
    AWS503 : require('./Azure/vm/vm_bd (6).jpg'),  
    AWS504 : require('./Azure/vm/vm_bd (7).jpg'),  
    AWS505 : require('./Azure/vm/vm_bd (8).jpg'),  
    AWS506 : require('./Azure/vm/vm_bd (9).jpg'),  
    AWS507 : require('./Azure/vm/vm_bd (10).jpg'),  
    AWS508 : require('./Azure/vm/vm_bd (11).jpg'),  
    AWS509 : require('./Azure/vm/vm_bd (12).jpg'),  
    AWS510 : require('./Azure/vm/vm_bd (13).jpg'),  
    AWS511 : require('./Azure/vm/vm_bd (14).jpg'),  
    AWS512 : require('./Azure/vm/vm_bd (15).jpg'),  
    AWS513 : require('./Azure/vm/vm_bd (16).jpg'),  
    AWS514 : require('./Azure/vm/vm_bd (17).jpg'),  
    AWS515 : require('./Azure/vm/vm_bd (18).jpg'),  
    AWS516 : require('./Azure/vm/vm_bd (19).jpg'),  
    AWS517 : require('./Azure/vm/vm_bd (20).jpg'),  
    AWS518 : require('./Azure/vm/vm_bd (21).jpg'),  
    AWS519 : require('./Azure/vm/vm_bd (22).jpg'),  
    AWS520 : require('./Azure/vm/vm_bd (23).jpg'),  
    // Azure VM Disk Encryption
    AWS521 : require('./Azure/vm/vm_diske (1).jpg'),  
    AWS522 : require('./Azure/vm/vm_diske (2).jpg'),  
    AWS523 : require('./Azure/vm/vm_diske (3).jpg'),  
    AWS524 : require('./Azure/vm/vm_diske (4).jpg'),  
    AWS525 : require('./Azure/vm/vm_diske (5).jpg'),  
    AWS526 : require('./Azure/vm/vm_diske (6).jpg'),  
    AWS527 : require('./Azure/vm/vm_diske (7).jpg'),  
    AWS528 : require('./Azure/vm/vm_diske (8).jpg'),  
    AWS529 : require('./Azure/vm/vm_diske (9).jpg'),  
    AWS530 : require('./Azure/vm/vm_diske (10).jpg'),  
    AWS531 : require('./Azure/vm/vm_diske (11).jpg'),  
    AWS532 : require('./Azure/vm/vm_diske (12).jpg'),  
    AWS533 : require('./Azure/vm/vm_diske (13).jpg'),  
    AWS534 : require('./Azure/vm/vm_diske (14).jpg'),  
    AWS535 : require('./Azure/vm/vm_diske (15).jpg'),  
    AWS536 : require('./Azure/vm/vm_diske (16).jpg'),  
    AWS537 : require('./Azure/vm/vm_diske (17).jpg'),  
    AWS538 : require('./Azure/vm/vm_diske (18).jpg'),  
    AWS539 : require('./Azure/vm/vm_diske (19).jpg'),  
    AWS540 : require('./Azure/vm/vm_diske (20).jpg'),  
    AWS541 : require('./Azure/vm/vm_diske (21).jpg'),  
}
export default IMAGES;