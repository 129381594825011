import React from 'react'
import '../03/Contact03.css'
const Contact03 = () => {
  return (
    <>
        <section className='sub03-contact'>
        <div className='sub03-contact-heading'>
            <h1>Contact Us</h1>
        </div>
            <div className='sub03-contact-container'>
                <div className='sub03-contact-image'>
                    <img src="/contact/03.png" alt="" />
                </div>
                <div className='sub03-contact-text'>
                    <input className='sub03-input' type="text" name="" id="" placeholder='Enter your Name' />
                    <input className='sub03-input' type="Email" name="" id="" placeholder='Enter your Email' />
                    <textarea className='sub03-textarea' name="" id="" cols="30" rows="10" placeholder='Enter your Message'></textarea>
                    <button className='sub03-contact-btn'>Submit</button>
                </div>
            </div>
        </section>
    </>
  )
}

export default Contact03