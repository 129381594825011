export const Testi01Data = [
    {
        id:0,
        testi01Para:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis animi saepe itaque? Molestias, ex reiciendis.',
        testi01Image:'/testimonial/01.jpg',
        testi01Name:'John Smith'
    },
    {
        id:1,
        testi01Para:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis animi saepe itaque? Molestias, ex reiciendis.',
        testi01Image:'/testimonial/02.jpg',
        testi01Name:'Bake Campbell'
    },
    {
        id:2,
        testi01Para:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis animi saepe itaque? Molestias, ex reiciendis.',
        testi01Image:'/testimonial/03.jpg',
        testi01Name:'Peter Pan'
    }
]