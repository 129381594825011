import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import './Blog.css'

const Blog = ({projectName, projectImage, ReadMore}) => {
  return (
    <>
        
                <div className='blog-items'>
                <div className='blog-image'>
                <img src={projectImage} alt="" />              

                
                </div>
                    
                    <h1>{projectName}</h1>
                    <div className='btn-cards'>
                    
                    <NavLink to={ReadMore}><button className='btn-alpha'>View</button></NavLink>
                    {/* <a href={ReadMore}><button className='btn-alpha'>View</button></a>                    */}
                 
                    </div>
                    
                </div>
            
    </>
  )
}

export default Blog