import React, { useState } from 'react'
import Blog from './Blog'
import { BlogData } from './BlogData'


const BlogIndex = () => {
    const [data, setData] = useState(BlogData)
  return (
    <>
    <section className='blog'>
        <div className='blog-heading'>
            <h1>Browse by Domain</h1>
            {/* <h2>AWS Solutions</h2> */}
        </div>
            <div className='blog-container container'>
        {
            data.map((items)=>(
                <Blog
                 projectImage={items.projectImage}   
                 projectName={items.projectName}
                 ReadMore={items.ReadMore}                
                 key={items.id}
                />
            ))
        }
        </div>
        </section>
    </>
  )
}

export default BlogIndex