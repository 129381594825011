import React from 'react'
import Footer from '../../../Footer/Footer'
import IMAGES from '../../../Images/'
import Navbar from '../../../Navbar/Navbar'

export const Con_addcloudfront = () => {
  return (
    <>
    <Navbar/>
        <section className='template'>
            <div className='template-container container'>
            <h1>Connect LoadBalancer with Cloudfront</h1>
            <p>we need load balancer, target group attached to load balancer, we will change few settings from the previous tutorial, remove the domain name and turn redirect off, delete the https rule from listener and leave the autoscaling as it is.
</p><br />
          <p>Delete the 443 Listener, we will get the certificate from cloudfront</p>  
            <img src={IMAGES.AWS211} alt="" /> 
            <p>Edit the port 80 to default settings, change it back to forwarder</p>        
            <img src={IMAGES.AWS212} alt="" />          
            <img src={IMAGES.AWS213} alt="" />    
            <p>Current Target Group settings</p>      
            <img src={IMAGES.AWS214} alt="" />      
            <p>Test the settings, we should be back to default settings</p>    
            <img src={IMAGES.AWS215} alt="" />  
            <h2>Create Cloudfront Distribution</h2>             
            <img src={IMAGES.AWS216} alt="" />          
            <p>Select your loadbalancer from the origin</p>       
            <img src={IMAGES.AWS217} alt="" />    
            <p>leave the default settings as it is</p>      
            <img src={IMAGES.AWS218} alt="" />   
            <p>From the viewer protocol policy, select Redirect http to https</p>       
            <img src={IMAGES.AWS219} alt="" />  
            <p>leave all the settings as it is</p>        
            <img src={IMAGES.AWS220} alt="" />  
            <p>Select the first Distribution</p>        
            <img src={IMAGES.AWS221} alt="" />          
            <p>Open the distribution and copy Distribution domain name</p>
            <img src={IMAGES.AWS222} alt="" /> 
            <p>Paste the DNS address in your browser, as we can see our webservers are now coming from cloudfront</p>          
            <img src={IMAGES.AWS223} alt="" />    
            <h2>Connect Domain and Custom SSL</h2>   
            <p>click on edit</p>   
            <img src={IMAGES.AWS224} alt="" /> 
            <p>Under edit settings, type your domain name in Alternate domain name box and select your custom SSL certificate</p>         
            <div className='template-container80rem' >
            <img src={IMAGES.AWS225} alt="" /> 
            </div>
            
            <p>your domain and ssl is connected</p>         
            <img src={IMAGES.AWS226} alt="" />  
            <h2>Add Alias Record</h2>        
            <p>open route53 and add an alias record</p>
            <img src={IMAGES.AWS227} alt="" /> 
            <p>Create new record</p>         
            <img src={IMAGES.AWS228} alt="" />
            <p>A record is listed as below</p>          
            <img src={IMAGES.AWS229} alt="" />   
            <p>Coming from the cloudfront and our domain is now replaced with cloudfront url
</p>       
            <img src={IMAGES.AWS230} alt="" />          
            
            </div>
            <div className='template-buttons container'>
            <a href="/con_ssl"><button className='template-back'>Back</button></a>
            <a href="/aws"><button className='template-next'>Next</button></a>
            </div>
       </section>
       <Footer/>
    </>
    
  )
}
