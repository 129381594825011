import React from 'react'
import FooterComp from '../01/FooterComp'
import { FooterData } from '../01/FooterData'
import FooterSocial from '../02/FooterSocial'

const FooterCompIndex = () => {
  return (
    <>
    <section className='footercomp'>
            <div className='footercomp-heading container'>
                <h1>With Apps and Social Links</h1>
            </div>
            <div className='fcomp-container container'>
        {
            FooterData.map((items)=>(
                <FooterComp
                    fheading={items.fheading}
                    item1={items.item1}
                    item2={items.item2}
                    item3={items.item3}
                    item4={items.item4}
                    item5={items.item5}
                    item6={items.item6}
                    key= {items.id}
                />
            ))
        }
        </div>
        </section>
        <FooterSocial/>
    </>
  )
}

export default FooterCompIndex