import React from 'react'
import { NavLink } from 'react-router-dom'
import Footer from '../../../Footer/Footer'
import IMAGES from '../../../Images/'
import Navbar from '../../../Navbar/Navbar'

export const SigninUrl = () => {
  return (
    <>
    <Navbar/>
        <section className='template'>
            <div className='template-container container'>
            <h1>Customizing URL</h1>
            <img src={IMAGES.AWS22} alt="" />
            <p>https://ammarpk.signin.aws.amazon.com/console</p> 
            <img src={IMAGES.AWS23} alt="" />
            <p>Login to AWS account using your IAM user</p>
            <div className='template-container60rem' >            
            <img src={IMAGES.AWS24} alt="" />            
            <img src={IMAGES.AWS25} alt="" />
            </div>
            
            
            <p>It will ask you to change your Password</p>
            <img src={IMAGES.AWS26} alt="" />
            <p>Once login you will see your account</p>            
            <img src={IMAGES.AWS27} alt="" />            
            
            </div>
            <div className='template-buttons container'>

            <NavLink to="/useraccounts"><button className='template-back'>Back</button></NavLink>
            <NavLink to="/accesskeys"><button className='template-next'>Next</button></NavLink>
            
{/* 
            <a href="https://ammar.tech/useraccounts"><button className='template-back'>Back</button></a>
            <a href="https://ammar.tech/accesskeys"><button className='template-next'>Next</button></a> */}
            </div>
       </section>
       <Footer/>
    </>
    
  )
}
