import React from 'react'
import './Header.css'
import portfolio from '../assets/header/portfolio.png'
import CV from '../assets/cv/cv.pdf'
import { NavLink } from 'react-router-dom'




const Header = () => {
  return (
    <>
        <section className='header'>
            <div className='header-container container'>
              <div className='header-image-items'>
                
                <div className='header-image'>
                <img src={portfolio} alt="" />
                </div>           
                
                  
                
              </div>
              
              <div className='header-text'>
              <h1>Ammar Rashid</h1>
                <p>To Secure a responsible career opportunity to fully utilize my training and skills, while making a significant contribution to the success of the company.</p>
               
                
              </div>
              
             
            </div>
        </section>
        <section>
            <div className='button-container container'>
            <div className='btn-group01'>
            <NavLink to={CV} target='_blank'><button className='btn-primary'>Download CV</button></NavLink>
            

                {/* <a href={CV} target='_blank'><button className='btn-primary'>Download CV</button></a> */}
                <NavLink to='/contact'><button className='btn-primary'>Let's Talk</button></NavLink>

                {/* <a href="https://ammar.tech/contact"><button className='btn-primary'>Lets Talk</button></a> */}
                </div>  

                <div className='btn-group02'>
                
      <NavLink to='/about'><button className='btn-primary'>Achievements</button></NavLink>

                {/* <a href="https://ammar.tech/about"><button className='btn-primary'>Achievements</button></a> */}
      <NavLink to='/portfolio'><button className='btn-primary'>PortFolio</button></NavLink>

                {/* <a href="https://ammar.tech/portfolio"><button className='btn-primary'>PortFolio</button></a> */}
                </div>
            </div>
        </section>

    </>
  )
}

export default Header