export const SubServicesApi = [
    {
        heading:'Over 300 Mobile Products',
        text:'Lorem ipsum dolor sit amet, consectetur adipiscing elit nullam nunc justo sagittis suscipit ultrices.',
        icon: 'fa-solid fa-mobile'
    },
    {
        heading:'Built in House Arrangement',
        text:'Lorem ipsum dolor sit amet, consectetur adipiscing elit nullam nunc justo sagittis suscipit ultrices.',
        icon: 'fa-solid fa-crutch'
    },
    {
        heading:'Icreased Market Rate Overall',
        text:'Lorem ipsum dolor sit amet, consectetur adipiscing elit nullam nunc justo sagittis suscipit ultrices.',
        icon: 'fa-solid fa-signal',
    }
]