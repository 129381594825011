import React from 'react'
import Footer from '../../../Footer/Footer'
import IMAGES from '../../../Images/'
import Navbar from '../../../Navbar/Navbar'
import { NavLink } from 'react-router-dom'

export const Clb_info = () => {
  return (
    <>
    <Navbar/>
        <section className='template'>
            <div className='template-container container'>
            <h1>Classic Load Balancer</h1>
            <img src={IMAGES.AWS253} alt="" /> 
            <h2>Setup EC2 instance</h2>    
            <p>Setup EC2 Instance first with webserver user data</p><br />
            <p>
            #!/bin/bash	<br />			
# install httpd (Linux 2 version)				<br />
yum update -y				<br />
yum install -y httpd.x86_64		<br />		
systemctl start httpd.service		<br />		
systemctl enable httpd.service			<br />	
echo "Hello world from $(hostname -f)" {'>'} /var/www/html/index.html				<br />
  
            </p>
            
            <img src={IMAGES.AWS254} alt="" />   
            <h2>Setup Load Balancer</h2>
            <p>Select Classic Load Balancer from the list of Menu
</p> 
    
            <img src={IMAGES.AWS255} alt="" />  
            <p>Name your Load Balancer</p>      
            
            <img src={IMAGES.AWS256} alt="" />          
            <p>Create Security Group</p>        
            <img src={IMAGES.AWS257} alt="" /> 
            <p>Configure Security Settings, if needed</p>         
            <img src={IMAGES.AWS258} alt="" />  
            <p>Configure Health Check</p>        
            <img src={IMAGES.AWS259} alt="" /> 
            <p>Add EC2 instances</p>         
            <img src={IMAGES.AWS260} alt="" />   
            <p>Review and Create</p>      
            <img src={IMAGES.AWS261} alt="" />          
            <img src={IMAGES.AWS262} alt="" /> 
            <h2>Testing</h2>         
            <img src={IMAGES.AWS263} alt="" />  
            <p>from the description copy the address and paste in browser
</p>        
            <img src={IMAGES.AWS264} alt="" />       
            <p>you will get the same response, as our EC2 is now connected with CLB</p>   
            <img src={IMAGES.AWS265} alt="" /> 
            <h2>Tighten The Security
</h2>         
<p>browse to your VM webserver security group</p>
            <img src={IMAGES.AWS266} alt="" />    
            <p>delete the http rule and create a new rule with the source begin the CLB security group</p>     
            <img src={IMAGES.AWS267} alt="" /> 
            <h2>Review and Test</h2>         
            <p>we don’t want our users to directly access the website, it will be accessed via load balancer. So we have removed the rule, and now it is not accessable via EC2 directly.</p>
            <img src={IMAGES.AWS268} alt="" />
            <p>but it is working via load balancer</p>          
            <img src={IMAGES.AWS269} alt="" /> 
            <h2>Create Two More Instances</h2>    
            <p>Use the below user data</p>     
            <p>
            #!/bin/bash	<br />			
# install httpd (Linux 2 version)				<br />
yum update -y				<br />
yum install -y httpd.x86_64		<br />		
systemctl start httpd.service		<br />		
systemctl enable httpd.service			<br />	
echo "Hello world from $(hostname -f)" {'>'} /var/www/html/index.html<br />
  
            </p>
            <img src={IMAGES.AWS270} alt="" /> 
            <p>Add into Load Balancer</p>         
            <img src={IMAGES.AWS271} alt="" />          
            <img src={IMAGES.AWS272} alt="" />  
            <p>wait for inservice message</p>        
            <img src={IMAGES.AWS273} alt="" />          
            <img src={IMAGES.AWS274} alt="" /> 
            <h2>Testing</h2>
            <p>Each time you refresh the page, it will give you different EC2 instance, which shows it is working.</p>         
            <img src={IMAGES.AWS275} alt="" />          
            <img src={IMAGES.AWS276} alt="" />          
            <img src={IMAGES.AWS277} alt="" />          
            </div>
            <div className='template-buttons container'>
            <NavLink to="/elb_info"><button className='template-back'>Back</button></NavLink>
            <NavLink to="/alb_overview"><button className='template-next'>Next</button></NavLink>
            </div>
       </section>
       <Footer/>
    </>
    
  )
}
