export const BlogData = [
    {
        id:1,
        projectImage:'assets/Blog/aws.png',
        projectName:'AWS Cloud',
        // ReadMore:'https://ammar.tech/aws',
        ReadMore:'/aws',

        // Route to AWS Page
        

    },
    {
        id:2,
        projectImage:'assets/Blog/azure.png',
        projectName:'Azure Cloud',
        // ReadMore:'https://ammar.tech/comingsoon',
        ReadMore:'/azure',
        

    },
    {
        id:3,
        projectImage:'assets/Blog/linux01.png',
        
        projectName:'Linux',
        // ReadMore:'https://ammar.tech/comingsoon',
        ReadMore:'/comingsoon',
        

    },
    {
        id:4,
        projectImage:'assets/Blog/windows01.png',
        projectName:'Windows',
        // ReadMore:'https://ammar.tech/comingsoon',
        ReadMore:'/comingsoon',
        

    },
    {
        id:5,
        projectImage:'assets/Blog/web.png',
        projectName:'Web',
        // ReadMore:'https://ammar.tech/comingsoon',
        ReadMore:'/comingsoon',
        

    },
    {
        id:6,
        projectImage:'assets/Blog/vm.png',
        projectName:'Virtualization',
        // ReadMore:'https://ammar.tech/comingsoon',
        ReadMore:'/comingsoon',

    },
]