import React from 'react'
import Cards01 from './Cards01'
import CardsData from './CardsData'




const CardsIndex = () => {
  return (
            <>
            <section className='card-system'>
            <div className='card-system-container container'>
            <div className='card-system-heading'>
            <h1>Best Plumbing Services</h1>
            <p>The leading supplier of new and used plumbing equipment, combining the qualities and expertise, you should expect from an industry supplier.</p>
            </div>

            <div className='card-system-items'>
            {
               CardsData.map((item, index)=>
                <Cards01
                    sysHeading={item.heading}
                    sysText={item.text}
                    sysImage={item.image}
                    key = {item.heading + index}
                />             
                )     
            }
            </div>
           
            </div>
        </section>
            </>
  )
}

export default CardsIndex