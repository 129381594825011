import React from 'react';
import Footer from '../../../Footer/Footer';
import IMAGES from '../../../Images/';
import Navbar from '../../../Navbar/Navbar';
import { NavLink } from 'react-router-dom';

export const Vm_cs = () => {
  return (
    <>
      <Navbar />
      <section className="template">
        <div className="template-container container">
          <h1>Custom Script</h1>
          <p>
            We will be installing webserver and print server using custom
            script.
          </p>

          <img src={IMAGES.AWS372} alt="" />
          <p>
            Create a powershell script on your local computer and save the below
            commands in it.
          </p>
          <p>
            Install-WindowsFeature -name Web-Server -IncludeManagementTools
            <br />
            Install-WindowsFeature -name Print-Server -IncludeManagementTools
          </p>
          <img src={IMAGES.AWS373} alt="" />
          <h2>Adding Extension</h2>
          <p>Select your VM, then click on extensions and then add.</p>
          <img src={IMAGES.AWS374} alt="" />
          <p>Select Custom Script Extension.</p>
          <img src={IMAGES.AWS375} alt="" />
          <p>Click on create</p>
          <img src={IMAGES.AWS376} alt="" />
          <p>
            When you click on browse it will look for storage account, currently
            we don't have any storage account, so we have to create one.
          </p>
          <img src={IMAGES.AWS377} alt="" />
          <h2>Setting up Storage Account</h2>
          <p>Name your storage account, and choose default options</p>
          <img src={IMAGES.AWS378} alt="" />
          <p>Your storage account is now created</p>
          <img src={IMAGES.AWS379} alt="" />
          <h2>Setting Container</h2>
          <p>
            To upload a file, you need a container, create a container and name
            it test.
          </p>
          <img src={IMAGES.AWS380} alt="" />
          <p>Upload your script into that container</p>
          <img src={IMAGES.AWS381} alt="" />
          <p>Select the tools.ps1 file which you created initially.</p>
          <img src={IMAGES.AWS382} alt="" />
          <img src={IMAGES.AWS383} alt="" />
          <p>Once uploaded, press ok to start deployment.</p>
          <div className="template-container80rem">
            <img src={IMAGES.AWS384} alt="" />
          </div>
          <p>
            Under extensions, you can see your custom extension, and it's status
            is provision succeeded.
          </p>
          <img src={IMAGES.AWS385} alt="" />
          <p>Here we can see print and web server are now installed </p>
          <img src={IMAGES.AWS386} alt="" />
          <p>Before Installation.</p>
          <img src={IMAGES.AWS387} alt="" />
          <p>Location of Downloaded Custom Script on VM.</p>
          <img src={IMAGES.AWS388} alt="" />
        </div>
        <div className="template-buttons container">
          <NavLink to="/vm_addisk">
            <button className="template-back">Back</button>
          </NavLink>
          <NavLink to="/vm_ci">
            <button className="template-next">Next</button>
          </NavLink>
        </div>
      </section>
      <Footer />
    </>
  );
};
