import React from 'react';
import Footer from '../../../Footer/Footer';
import IMAGES from '../../../Images/';
import Navbar from '../../../Navbar/Navbar';
import { NavLink } from 'react-router-dom';

export const Vm_snapshot = () => {
  return (
    <>
      <Navbar />
      <section className="template">
        <div className="template-container container">
          <h1>VM Snapshot</h1>
          <h2>Step 1- Create a snapshot from disk</h2>
          <p>
            Usage Scenario : if we want to install a print server, and something
            happened like drivers are corrupted which effect our other programs,
            so in that case we will create a snapshot of our VM before
            installing print server.
          </p>
          <p>
            Snapshots we can create from disks, browse to below and click on
            disks, select your disk on which you want to create a snapshot, then
            click on create snapshot.
          </p>
          <img src={IMAGES.AWS437} alt="" />
          <p>Name your snapshot</p>
          <img src={IMAGES.AWS438} alt="" />
          <img src={IMAGES.AWS439} alt="" />
          <img src={IMAGES.AWS440} alt="" />
          <p>Review + Create</p>
          <img src={IMAGES.AWS441} alt="" />
          <img src={IMAGES.AWS442} alt="" />
          <p>Once deployment is completed, click on goto resource.</p>
          <img src={IMAGES.AWS443} alt="" />

          <img src={IMAGES.AWS444} alt="" />
          <p>you can also view it from resources.</p>
          <img src={IMAGES.AWS445} alt="" />
          <p>Also from snapshot menu</p>
          <img src={IMAGES.AWS446} alt="" />
          <h2>Step 2- Create a Disk from Snapshot</h2>
          <p>
            In order to use the snapshot you have to create a disk from it,
            browse to your snaphot, and click on create disk.
          </p>
          <img src={IMAGES.AWS447} alt="" />
          <img src={IMAGES.AWS448} alt="" />
          <img src={IMAGES.AWS449} alt="" />
          <img src={IMAGES.AWS450} alt="" />
          <p>Review + Create</p>
          <div className="template-container80rem">
            <img src={IMAGES.AWS451} alt="" />
          </div>
          <p>Your Deployment is complete.</p>
          <img src={IMAGES.AWS452} alt="" />
          <p>it will show under Managed Disks.</p>
          <img src={IMAGES.AWS453} alt="" />
          <img src={IMAGES.AWS454} alt="" />
          <p>You can also view it under disks.</p>
          <img src={IMAGES.AWS455} alt="" />
          <h2>Step 3- Create a VM from that Disk</h2>
          <p>In order to use that disk you have to create a VM from it.</p>
          <img src={IMAGES.AWS456} alt="" />
          <p>From the disks, select your disk.</p>
          <img src={IMAGES.AWS457} alt="" />
          <p>Click on create VM.</p>
          <img src={IMAGES.AWS458} alt="" />
          <img src={IMAGES.AWS459} alt="" />
          <img src={IMAGES.AWS460} alt="" />
          <img src={IMAGES.AWS461} alt="" />
          <img src={IMAGES.AWS462} alt="" />
          <img src={IMAGES.AWS463} alt="" />
          <p>Your deployment is complete.</p>
          <img src={IMAGES.AWS464} alt="" />
          <p>Here you can see first VM, and it's source is disk.</p>
          <img src={IMAGES.AWS465} alt="" />
          <div className="template-container60rem">
            <img src={IMAGES.AWS466} alt="" />
          </div>
          <p>
            Note under source we have 3 options. <br />
            - Disk means we have created VM from Snapshot <br />
            - Image means we have created VM from capture Image <br />-
            MarketPlace means we have create VM which Azure provided us when we
            first created VM
          </p>
          <p>Here we can see our VM is running.</p>
          <img src={IMAGES.AWS467} alt="" />
          <h2>Attaching Second Disk</h2>
          <p>
            After the creation of VM, any second disk attached is removed, so
            you have to attach it again, first remove it from the original vm,
            and then attach it to your newly created vm from snapshot.
          </p>
          <h3>Difference between Managed vs Unmanaged Disk</h3>
          <p>
            Manage disk exists in Disks - options <br />
            Unmanaged disk exist in storage accounts. <br />
          </p>
          <h2>Summary</h2>
          <p>
            Create SnapShot from original Disk <br />
            Create Disk from Snapshot <br />
            Create VM from that Disk <br />
          </p>
        </div>
        <div className="template-buttons container">
          <NavLink to="/vm_ip">
            <button className="template-back">Back</button>
          </NavLink>
          <NavLink to="/vm_proximity">
            <button className="template-next">Next</button>
          </NavLink>
        </div>
      </section>
      <Footer />
    </>
  );
};
