import React from 'react'
import Footer from '../../../Footer/Footer'
import IMAGES from '../../../Images/'
import Navbar from '../../../Navbar/Navbar'
import { NavLink } from 'react-router-dom'

export const Glb_info = () => {
  return (
    <>
    <Navbar/>
        <section className='template'>
            <div className='template-container container'>
            <h1>Gateway Load Balancer</h1>
            <img src={IMAGES.AWS307} alt="" />          
            <img src={IMAGES.AWS308} alt="" />     
            <p>More content will be available soon</p>     
            
            </div>
            <div className='template-buttons container'>
            <NavLink to="/nlb_info"><button className='template-back'>Back</button></NavLink>
            <NavLink to="/asg_overview"><button className='template-next'>Next</button></NavLink>
            </div>
       </section>
       <Footer/>
    </>
    
  )
}
