import React from 'react'
import Feature02 from './Feature02'
import { Feature02Data } from './Feature02Data'

const Feature02Index = () => {
  return (
    <>
    <section className='feature02'>
        <div className='feature02-heading'>
            <h1>Three Column Grid</h1>
        </div>
        <div className='feature02-container container'>
      {
        Feature02Data.map((items)=>(
          <Feature02
            featureIcon={items.featureIcon}
            featureHeading={items.featureHeading}
            featureText={items.featureText}
            key={items.id}
          />
        ))
      }
      </div>
        </section>
    </>
  )
}

export default Feature02Index