import React from 'react'
import Pricing02 from './Pricing02'
import { Pricedata02 } from '../02/Pricedata02'

const PriceIndex02 = () => {
  return (
   <>
    <section className='pricing02'>
        <div className='pricing02-heading'>
        <h1>3 Tiers with emphasized tier horizontal</h1>
        </div>
        <div className='pricing-topbar container'>
                <h1>The Right Plan for <span className='pricing02-span'>your business</span></h1>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate suscipit inventore id totam maioresupiditate suscipit inventore id totam maiores voluptate alias quam error, quia et.</p>
                </div>
            <div className='pricing02-container container'>
                
                
       {
        Pricedata02.map((items)=>(
            <Pricing02
                pricing02_heading={items.pricing02_heading}
                pricing02_item1={items.pricing02_item1}
                pricing02_item2={items.pricing02_item2}
                pricing02_item3={items.pricing02_item3}
                pricing02_package={items.pricing02_package}
                key={items.id}
            />
        ))
           
           
       }
       </div>
        </section>
   </>
  )
}

export default PriceIndex02