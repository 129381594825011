import React from 'react';
import Footer from '../../../Footer/Footer';
import IMAGES from '../../../Images/';
import Navbar from '../../../Navbar/Navbar';
import { NavLink } from 'react-router-dom';

export const Vm_diske = () => {
  return (
    <>
      <Navbar />
      <section className="template">
        <div className="template-container container">
          <h1>Disk Encryption</h1>

          <img src={IMAGES.AWS521} alt="" />
          <h2>Setting up Boot Encryption</h2>
          <img src={IMAGES.AWS522} alt="" />

          <img src={IMAGES.AWS524} alt="" />
          <p>
            Currently we have only OS disk, so we will select OS option, it will
            not work on temorary storage.
          </p>
          <img src={IMAGES.AWS523} alt="" />
          <p>Encryption settings, we need key vault.</p>
          <img src={IMAGES.AWS525} alt="" />
          <h2>Setting up Key Vault</h2>
          <img src={IMAGES.AWS526} alt="" />
          <img src={IMAGES.AWS527} alt="" />
          <img src={IMAGES.AWS528} alt="" />
          <img src={IMAGES.AWS529} alt="" />
          <img src={IMAGES.AWS530} alt="" />
          <img src={IMAGES.AWS531} alt="" />
          <p>Review + Create.</p>
          <img src={IMAGES.AWS532} alt="" />
          <p>
            Back to disk settings, and select your key vault you just created.
            Next you have to create a key.
          </p>
          <img src={IMAGES.AWS533} alt="" />
          {/* <img src={IMAGES.AWS534} alt="" /> */}
          <h2>Create a Key</h2>
          <img src={IMAGES.AWS535} alt="" />
          <img src={IMAGES.AWS536} alt="" />
          <p>Save your disk settings.</p>
          <img src={IMAGES.AWS537} alt="" />
          <p>Note under encryption we have now the option for ADE.</p>
          <img src={IMAGES.AWS538} alt="" />
          <h2>Verify Disk Encryption</h2>
          <p>Login to your VM, note now we have a lock on our C drive.</p>
          <img src={IMAGES.AWS539} alt="" />
          <p>
            Note : (Limitation) if customer ask to restore files and folders
            from the disk, in that case we cannot do that we first have to
            de-attach disk or copy disk and then connect with another virtual
            machine and then we can restore files from it
          </p>
          <h2>Key Vault Information</h2>
          <p>Your keys are stored under keyvaults.</p>
          <img src={IMAGES.AWS540} alt="" />
          <img src={IMAGES.AWS541} alt="" />
        </div>
        <div className="template-buttons container">
          <NavLink to="/vm_bootd">
            <button className="template-back">Back</button>
          </NavLink>
          <NavLink to="/azure">
            <button className="template-next">Next</button>
          </NavLink>
        </div>
      </section>
      <Footer />
    </>
  );
};
