import React from 'react'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'
import Hero01 from '../subComponents/Hero/01/Hero01'
import Hero02 from '../subComponents/Hero/02/Hero02'
import Hero03 from '../subComponents/Hero/03/Hero03'
import Hero04 from '../subComponents/Hero/04/Hero04'
import Hero05 from '../subComponents/Hero/05/Hero05'
import Hero06 from '../subComponents/Hero/06/Hero06'
const Hero_ = () => {
  return (
  <>
<Navbar/> 
<Hero01/>
<Hero02/>
<Hero03/>
<Hero04/>
<Hero05/>
<Hero06/>
<Footer/>
  </>
  )
}

export default Hero_