import React, { useState } from 'react'
import AwsSolutions from './AwsSolutions'
import { AwsSolutionsData } from './AwsSolutionsData'



const AwsSolutionsIndex = () => {
    const [data, setData] = useState(AwsSolutionsData)
  return (
    <>
    <section className='aws-solutions'>
        <div className='aws-solutions-heading'>
            <h1>AWS Solutions</h1>
            {/* <h2>AWS Solutions</h2> */}
        </div>
            <div className='aws-solutions-container container'>
        {
            data.map((items)=>(
                <AwsSolutions
                 projectImage={items.projectImage}   
                 projectName={items.projectName}
                 View={items.View}                
                 key={items.id}
                />
            ))
        }
        </div>
        </section>
        
    </>
  )
}

export default AwsSolutionsIndex