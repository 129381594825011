import React from 'react'
import { NavLink } from 'react-router-dom'
import './WithLogo.css'

const WithLogo = ({wheading, wlogo, tag, item1, item2, item3, item4, item5}) => {
  return (
    <>
        
            <div className='wlogo-items'>
            <div className='wlogo-heading'>
                <h1>{wheading}</h1>                
                </div> 
                <div className='wlogo-image'>
                <img src={wlogo} alt="" />
                <p className='tag'>{tag}</p>
                </div>               
                <div className='wlogo-list'>
                    <ul>
    <NavLink to="">
    <li>{item1}</li>
    </NavLink>
    <NavLink to="">
    <li>{item2}</li>
    </NavLink>
    <NavLink to="">
    <li>{item3}</li>
    </NavLink>
    <NavLink to="">
    <li>{item4}</li>
    </NavLink>
    <NavLink to="">
    <li>{item5}</li>
    </NavLink>  
    </ul>                             
    
    
</div>
    
    
    
              
                                      
                                            
        
            </div>
            
    
    </>
  )
}

export default WithLogo