export const SysAdminData = [
    {
        id:1,
        icon:'fas fa-meteor',
        heading:'AWS Cloud',
        item_1:'EC2 and S3',
        item_2:'Systems Manager',
        item_3:'CloudFront',
        item_4:'Route 53',
        item_5:'Directory Services',
        item_6:'VPC'
    },
    {
        id:2,
        icon:'fa-solid fa-bolt',
        heading:'Azure Cloud',
        item_1:'Virtual Machine',
        item_2:'Azure Storage',
        item_3:'Load Balancer',
        item_4:'Azure App Services',
        item_5:'Azure Active Directory',
        item_6:'Azure DNS'
    },
    {
        id:3,
        icon:'fa-solid fa-code',
        heading:'Microsoft Workload',
        item_1:'Active Directory',
        item_2:'Group Policy',
        item_3:'File Server',
        item_4:'SCCM / WSUS',
        item_5:'Security Groups',
        item_6:'Storage & Backup'
    },
    {
        id:4,
        icon:'fa-solid fa-filter',
        heading:'Linux & Web Administration',
        item_1:'Apache Server Setup',
        item_2:'Nginx Server Setup',
        item_3:'Wordpress Setup',
        item_4:'Database Migration',
        item_5:'Domain, DNS, SSL',
        item_6:'Cpanel'
    },
    {
        id:5,
        icon:'fa-solid fa-microchip',
        heading:'Virtualization',
        item_1:'Vcenter',
        item_2:'Vmware Workstation',
        item_3:'Virtual Box',
        item_4:'Hyper V',
        item_5:'Docker',
        item_6:'Testing & Validation'
    },
    {
        id:6,
        icon:'fa-solid fa-gear',
        heading:'System Tools',
        item_1:'Putty',
        item_2:'Visual Studio',
        item_3:'Filezilla',
        item_4:'Wireguard / OpenVPN',
        item_5:'Remote Desktop',
        item_6:'Tight VNC'
    },
]