import React from 'react'
import '../02/Testi02.css'

const Testi02 = () => {
  return (
    <>
        <section className='testi02'>
        <div className='testi02-heading'>
            <h1>With Large Quote</h1>
        </div>
        <div className='testi02-container container'>
        
            <div className='testi02-para'>
            <p>"Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium officia nulla dolor commodi fugit itaque enim maxime, ipsam perferendis quas labore, adipisicing elit. Accusantium officia nulla dolor commodi fugit itaque enim maxime, ipsam perferendis quas labore, quo at veniam tempora. Quam deleniti maiores adipisci quos!"</p>
            </div>
            <div className='testi02-image'>
            <img src='/testimonial/03.jpg' alt="" />
            </div>
            <div className='testi02-name'>
            <p>Peter Pan</p>
            </div>
            <div className='testi02-position'>
            <p>Vice President</p>
            </div>
        </div>
        </section>
    </>
  )
}

export default Testi02