import React from 'react'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import SkillsIndex from '../Skills/SkillsIndex'
import SysadminIndex from '../Skills/01/SysadminIndex'

const Skills_ = () => {
  return (
   <>
     <Navbar/>
     
     <SysadminIndex/>
     <SkillsIndex/>  
      <Footer/>
   </>
  )
}

export default Skills_