import React from 'react'
import { NavLink } from 'react-router-dom'


export const Elb_block = () => {
  return (
    <>
    
        <section className='block-index'>
            <div className='template-container template-block container'>
            <h3>Elastic Load Balancer & Autoscaling</h3>
            <ul>
            <li><NavLink to="/elb_overview">High Availability & Scalibility</NavLink></li>   
            <li><NavLink to="/elb_info">Elastic Load Balancer</NavLink></li>   
            <li><NavLink to="/clb_info">Classic Load Balancer</NavLink></li>   
            <li><NavLink to="/alb_overview">Application Load Balancer Overview</NavLink></li>   
            <li><NavLink to="/con_loadbalancer">Application Load Balancer Lab</NavLink></li>                
            <li><NavLink to="/nlb_info">Network Load Balancer</NavLink></li>   
            <li><NavLink to="/glb_info">Gateway Load Balancer</NavLink></li>               
            <li><NavLink to="/asg_overview">Autoscaling Group Overview</NavLink></li>   
            <li><NavLink to="/con_autoscaling">Autoscaling Group Lab</NavLink></li> 
            <li><NavLink to="/asg_policy">Autoscaling Group Policies</NavLink></li>     
            <li><NavLink to="/con_ssl">SSL and Domain Setup</NavLink></li>   
            <li><NavLink to="/con_addcloudfront">Connect Loadbalancer with Cloudfront</NavLink></li>   
            
            
            </ul>
            
            
            </div>
            
            
            
       </section>
    
    </>
    
  )
}
