export const WebData = [
    {
        id:1,
        projectImage:'assets/web/renovate.jpg',
        projectName:'Home Renovation',
        GitHub:'https://github.com/ammarr100/renovate.git',
        LiveDemo:'https://renovate.ammar.tech'

    },
    {
        id:2,
        projectImage:'assets/web/wood.jpg',
        projectName:'Wood Truss System',
        GitHub:'https://github.com/ammarr100/wood-Truss',
        LiveDemo:'https://truss.ammar.tech'

    },
    {
        id:3,
        projectImage:'assets/web/studio.jpeg',
        projectName:'Photo Studio',
        GitHub:'https://github.com/ammarr100/photostudio',
        LiveDemo:'https://photostudio.ammar.tech'

    },
    {
        id:4,
        projectImage:'assets/web/mockup.jpg',
        projectName:'Mockup',
        GitHub:'https://github.com/ammarr100/mockup',
        LiveDemo:'https://mockup.ammar.tech'

    },
    {
        id:5,
        projectImage:'assets/web/mobile4.jpg',
        projectName:'Mobile Design',
        GitHub:'https://github.com/ammarr100/mobile',
        LiveDemo:'https://mobileapp.ammar.tech'

    },
    {
        id:6,
        projectImage:'assets/web/Toyota.png',
        projectName:'Toyota Concept',
        GitHub:'https://github.com/ammarr100/toyota',
        LiveDemo:'https://toyota.ammar.tech'

    },
]