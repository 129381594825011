import React from 'react'
import Footer from '../../../Footer/Footer'
import IMAGES from '../../../Images/'
import Navbar from '../../../Navbar/Navbar'
import { NavLink } from 'react-router-dom'

export const Vm_setup = () => {
  return (
    <>
    <Navbar/>
        <section className='template'>
            <div className='template-container container'>
            <h1>Virtual Machine Setup</h1>
            <h2>Creating Resource Group</h2>  
            <p>First we have to create resource group.</p>          
            <img src={IMAGES.AWS338} alt="" />        
            <p>Name you resource group</p>  
            <img src={IMAGES.AWS339} alt="" />  
            <p>Add Tags if needed</p>        
            <img src={IMAGES.AWS340} alt="" /> 
            <p>Review + Create</p>
            <div className='template-container60rem' >
            <img src={IMAGES.AWS341} alt="" />          
            </div>
            <p>Your resource group is now created.</p>
            <img src={IMAGES.AWS342} alt="" />    
            <h2>Setup Virutal Machine</h2>  
            <h3>Virtual Machine components</h3>
<ul>
<li>Operating System</li>
<li>Disk</li>
<li>Network Interface Card</li>
</ul>                                            
<h3>Pre-Req
</h3> <ul>
<li>Resource Group</li>
<li>Azure virtual Network</li>
</ul>
    
            <p>click on add to create virutal machine</p> 
            <img src={IMAGES.AWS343} alt="" />  
            <p>Enter all the basic details</p>  
            {/* <div className='template-container80rem' > */}
            <img src={IMAGES.AWS344} alt="" />          
            {/* </div>                   */}
            <p>Select Managed disks</p>
            <img src={IMAGES.AWS345} alt="" />  
            <p>Leave the default settings as it is.</p>        
            <img src={IMAGES.AWS346} alt="" />          
            <img src={IMAGES.AWS347} alt="" />  
            <p>Leave the default settings as it is.</p>        
            <img src={IMAGES.AWS348} alt="" />  
            <p>Select No proximity placement group, we will see that in detail.</p>        
            <img src={IMAGES.AWS349} alt="" />  
            <p>Enter Tags</p>        
            <img src={IMAGES.AWS350} alt="" />  
            <p>Review and Create</p>        
            <img src={IMAGES.AWS351} alt="" />          
            <img src={IMAGES.AWS352} alt="" />          
            <img src={IMAGES.AWS353} alt="" />   
            <p>You will see deployment in progress as shown below</p>       
            <img src={IMAGES.AWS354} alt="" />  
            <p>Your deployment is complete, click on go to resource to see VM dashboard.</p>        
            <img src={IMAGES.AWS355} alt="" />          
            <p>Below is the dashboard of your VM.</p>
            <img src={IMAGES.AWS356} alt="" />
            <h2>Connect VM</h2>         
            <p>Copy the Public ip address, and open remotes desktop on your local computer. Click on conenct, it will ask you to enter username and password, these are credentials which you put in step 1 basics.</p> 
            <img src={IMAGES.AWS357} alt="" />          
            
            <img src={IMAGES.AWS358} alt="" /> 
            <p>You are now logged in to your VM</p>         
            <img src={IMAGES.AWS359} alt="" /> 
            <h2>Preset Configurations</h2>
            <p>Another mehtod of creating VM is selecting the option start with preset configuration.Here Azure will give us the option to select VM which is already configured for different purpose. </p>
            <p>Click on Add, and you will see second option, start with preset configurations.</p>
             <div className='template-container80rem' >
             <img src={IMAGES.AWS360} alt="" />          
             </div>         
            <p>lets click on genral purpose</p>
            <img src={IMAGES.AWS361} alt="" />    
            <p>Rest of the steps are same as before.</p>      
            <img src={IMAGES.AWS362} alt="" />          
            </div>
            <div className='template-buttons container'>
            <NavLink to="/azure"><button className='template-back'>Back</button></NavLink>
            <NavLink to="/vm_cs"><button className='template-next'>Next</button></NavLink>
            </div>
       </section>
       <Footer/>
    </>
    
  )
}
