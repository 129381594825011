export const AboutData = [
    {
        id : '1',
        questions : 'AWS Certified Solution Architect Associate',        
        answers : 'assets/achievements/aws.png',
        // answers : 'assets/web/studio.jpeg',
        
    },
    {
        id : '2',
        questions : 'Server 2019 Udemy Certificate',
        answers : 'assets/achievements/server2019.png',
    },
    {
        id : '3',
        questions : 'Cisco Certified Network Associate - CCNA',
        answers : 'assets/achievements/ccna.png',
    },
    {
        id : '4',
        questions : 'Cisco Certified Network Professional - CCNP',
        answers : 'assets/achievements/ccnp.png',
    },
    {
        id : '5',
        questions : 'Microsoft Certified Professional',
        answers : 'assets/achievements/mcp.png',
    },
    {
        id : '6',
        questions : 'ACS Certified Technologist',
        answers : 'assets/achievements/acs-certified.png',
    },
    {
        id : '7',
        questions : 'Australian Computer Society Member',
        answers : 'assets/achievements/acs.png',
    },
    {
        id : '8',
        questions : 'Swinburne University of Technology',
        answers : 'assets/achievements/swinburne.png',
    },
    {
        id : '9',
        questions : 'Australian National University',
        answers : 'assets/achievements/anu.jpeg',
    },
]