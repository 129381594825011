import React from 'react'
import '../02/Auth02.css'

const Auth02 = () => {
  return (
    <>
        
        <section className='auth02'>
        
       
            <div className='auth02-heading'>
            <div className='auth02-h1'>
        <h1>Simple Signup Card</h1>
        </div>
                <h1>Sign up to your account</h1>
                <p>to enjoy all of our cool <a href="">features </a>👌 </p>                
            </div>

            <div className='auth02-input'>
            <div className='auth02-name'>
            <p className='auth02-name-p'>First Name <span className='auth02-star'>*</span></p>
            <p className='auth02-name-p'>Last Name <span className='auth02-star'>*</span></p>
            </div>

            <div className='auth02-name-input'>
            <input className='auth02-firstname' type="text" />            
            <input className='auth02-lastname' type="text" name="" id="" />
            </div>
            
            
            <p className='auth02-p'>Email Address <span className='auth02-star'>*</span></p>
            <input className='auth02-x' type="Email"/>
            <p className='auth02-p'>Password <span className='auth02-star'>*</span></p>
            <input className='auth02-x' type="Password" name="" id="" />
            <div className='auth02-checkbox'>
            <input type="checkbox" name="" id="" />
            <p>Remeber me</p>
            <a href="">Forgot password ?</a>
            </div>
            <button className='auth02-btn'>
            Sign in
            </button>         

            </div>
        </section>
    </>
  )
}

export default Auth02