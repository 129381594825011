import React from 'react'
import './Wordpress.css'
const Wordpress = () => {
  return (
    <>
        <section className='wordpress'>
        <div className='wordpress-heading container'>
            <h1>Created with Wordpress</h1>
        </div>
          <div className='wordpress-container container'>
            {/* <div className='wordpress-left'>
                <img className='wordpress-image' src="/assets/webcomponents/abs17.jpg" alt="" />
                <h1 className='image-heading'>AlQuran</h1>
                <p className='wordpress-para'>It was very difficult to fullfill customer requirement using only plugins, it was a challenging project and customer is very happy about it.</p>
                
                <a href="https://alquran.one" target='_blank' className='btn-primary'>Visit</a>               
                
            </div> */}
            <div className='wordpress-right'>
                <img className='wordpress-image' src="/assets/webcomponents/abs18.jpg" alt="" />
                <h1 className='image-heading'>Bijoux</h1>
                <p className='wordpress-para'>A Jewellery store setup with wo-commerce with fully functional payment system, providing customer support on monthly basis.</p>
          <a href="https://bijoux.pk" target='_blank' rel="noreferrer" className='btn-primary'>Visit</a>               
            
            </div>
          </div>  
        </section>
    </>
  )
}

export default Wordpress