import React from 'react'
import { NavLink } from 'react-router-dom'
import Footer from '../../../Footer/Footer'
import IMAGES from '../../../Images/'
import Navbar from '../../../Navbar/Navbar'

export const Roles = () => {
  return (
    <>
    <Navbar/>
        <section className='template'>
            <div className='template-container container'>
            <h1>IAM Roles</h1>
            <img src={IMAGES.AWS48} alt="" />         
            <p>click on create roles</p> 
            <img src={IMAGES.AWS49} alt="" />      
            <p>select AWS service, and select EC2 for the use case</p>    
            <img src={IMAGES.AWS50} alt="" />   
            <p>search for IAM read only access, and click next</p>       
            <img src={IMAGES.AWS51} alt="" />  
            <p>Name your role and click on create</p>        
            <img src={IMAGES.AWS52} alt="" />          
            <p>As you can see, you can find your role under Roles section</p>
            <img src={IMAGES.AWS53} alt="" />               

            </div>
            <div className='template-buttons container'>
            <NavLink to="/accesskeys"><button className='template-back'>Back</button></NavLink>
            <NavLink to="/iam_policy"><button className='template-next'>Next</button></NavLink>
            
            
            </div>
       </section>
       <Footer/>
    </>
    
  )
}
