import React from 'react'
import {FaTwitter} from 'react-icons/fa'
import {FaFacebook} from 'react-icons/fa'
import {FaYoutube} from 'react-icons/fa'
import '../02/FooterSocial.css'


const FooterSocial = () => {
  return (
    <>
        <section className='footersocial'>
            <div className='fsocial-container container'>
                <div className='fsocial-heading'>
                    <p>© 2022 Ammar Tech. All rights reserved</p>
                </div>
                <div className='fsocial-icons'>
                    <FaTwitter/>
                    <FaFacebook/>
                    <FaYoutube/>
                </div>      
            </div>
        </section>
    </>
  )
}

export default FooterSocial