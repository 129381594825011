import React from 'react'
import '../05/Contact05.css'

const Contact05 = () => {
  return (
    <section className='sub05-contact'>
        <div className='sub05-contact-heading container'>
            <h1>Get in Touch</h1>
        </div>
        <div className='sub05-contact-items container'>
            <input className='sub05-contact-input' type="text" name="" id="" placeholder='Enter Full Name' />
            <input className='sub05-contact-input' type="email" name="" id="" placeholder='Enter a valid Email Address' />
            <textarea className='sub05-textarea' name="" id="" cols="30" rows="10" placeholder='Enter your Message'></textarea>
            <button className='sub05-btn-contact'>Submit</button>
        </div>
    </section>
  )
}

export default Contact05