import React from 'react'
import { NavLink } from 'react-router-dom'
import './FooterComp.css'

const FooterComp = ({fheading, item1, item2, item3, item4, item5, item6}) => {
  return (
    <>
        
            <div className='fcomp-items'>
            <div className='fcomp-heading'>
                <h1>{fheading}</h1>                
                </div>
                <div className='fcomp-list'>
                    <ul>
    <NavLink to="">
    <li>{item1}</li>
    </NavLink>
    <NavLink to="">
    <li>{item2}</li>
    </NavLink>
    <NavLink to="">
    <li>{item3}</li>
    </NavLink>
    <NavLink to="">
    <li>{item4}</li>
    </NavLink>
    
    
    </ul>                             
                </div>
                <div className='fcomp-images'>
                    <img src={item5} alt="" />
                    <img src={item6} alt="" />
                </div>
                                      
                                            
            
            </div>
            
        
    </>
  )
}

export default FooterComp