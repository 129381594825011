import React from 'react'
import { NavLink } from 'react-router-dom'
import Footer from '../../../Footer/Footer'
import IMAGES from '../../../Images/'
import Navbar from '../../../Navbar/Navbar'

export const Iam_sdk = () => {
  return (
    <>
    <Navbar/>
        <section className='template'>
            <div className='template-container container'>
            <h1>Sofware Deployment Kit</h1>           
            
            <img src={IMAGES.AWS85} alt="" />          
            <p>More information on AWS website : <a href="https://aws.amazon.com/developer/tools/" target='_blank'>SDK</a></p>                    
            </div>
            <div className='template-buttons container'>

          <NavLink to="/iam_metadata"><button className='template-back'>Back</button></NavLink>
          <NavLink to="/iam_billing"><button className='template-next'>Next</button></NavLink>
          
          
            </div>
       </section>
       <Footer/>
    </>
    
  )
}
