import React from 'react'
import Footer from '../../../Footer/Footer'
import IMAGES from '../../../Images/'
import Navbar from '../../../Navbar/Navbar'
import { NavLink } from 'react-router-dom'

export const Asg_policy = () => {
  return (
    <>
    <Navbar/>
        <section className='template'>
            <div className='template-container container'>
            <h1>Auto Scaling Group Policies</h1>
            <img src={IMAGES.AWS317} alt="" />          
            <img src={IMAGES.AWS318} alt="" />          
            <img src={IMAGES.AWS319} alt="" />          
            <img src={IMAGES.AWS320} alt="" />          
            <img src={IMAGES.AWS321} alt="" />          
            <img src={IMAGES.AWS322} alt="" />          
            <img src={IMAGES.AWS323} alt="" /> 
            <h2>Auto Scaling Group Lab</h2> 
            <p>we have 3 options for automatic scaling</p>        
            <img src={IMAGES.AWS324} alt="" />  
            <h2>Dynamic Scaling Policy</h2>        
            <img src={IMAGES.AWS325} alt="" />          
            <img src={IMAGES.AWS326} alt="" /> 
            <h2>Install Stress Test on EC2 Instance</h2>
            <a href="https://gist.github.com/mikepfeiffer/d27f5c478bef92e8aff4241154b77e54
" target='_blank'>Download Link</a>         
            <img src={IMAGES.AWS327} alt="" /> 
            <p>stress command : stress -c 4</p>  <br />
            <p>as you can see it will make the cpu utilization high.</p>
            <div className='template-container60rem' >
            
            <img src={IMAGES.AWS328} alt="" />          
            </div>       
            <p>now our cpu is more than 40%, it will now create another instance</p>
            <img src={IMAGES.AWS329} alt="" />   
            <p>stop the cpu utilization</p>       
            <img src={IMAGES.AWS330} alt="" />    
            <p>you can also view alarm status in cloudwatch, alarm created by cloudwatch</p>      
            <img src={IMAGES.AWS331} alt="" />   
            <p>Desired capacity, as shown below</p>       
            <img src={IMAGES.AWS332} alt="" />  
            <p>now our cpu utilization is back to normal it will automatically remove the added instance</p>        
            <img src={IMAGES.AWS333} alt="" />       
            <p>View in autoscaling groups</p>   
            <img src={IMAGES.AWS334} alt="" />          
            <img src={IMAGES.AWS335} alt="" />   
            <p>You can also check in Instance Management Tab.</p>       
            <img src={IMAGES.AWS336} alt="" />          
            <img src={IMAGES.AWS337} alt="" />          
            
            </div>
            <div className='template-buttons container'>
            <NavLink to="/con_autoscaling"><button className='template-back'>Back</button></NavLink>
            <NavLink to="/con_ssl"><button className='template-next'>Next</button></NavLink>
            </div>
       </section>
       <Footer/>
    </>
    
  )
}
