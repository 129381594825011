import React from 'react'
import Footer from '../../../Footer/Footer'
import Navbar from '../../../Navbar/Navbar'
import { Vm_block } from '../../content/Vm/Vm_block'

const Azure = () => {
  return (
    <>
     
<Navbar/>
<Vm_block/>

<Footer/>



        </>
  )
}

export default Azure