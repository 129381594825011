import React from 'react'
import '../02/Pricing02.css'
import {TiTickOutline} from 'react-icons/ti'
const Pricing02 = ({pricing02_heading,pricing02_item1,pricing02_item2,pricing02_item3,pricing02_package}) => {
  return (
    <>
       
                <div className='pricing02-items'>
                <h1>{pricing02_heading}</h1>
                <ul className='pricing02-list'>
                    <li><TiTickOutline className='price-color'/> {pricing02_item1}</li>
                    <li><TiTickOutline className='price-color'/> {pricing02_item2}</li>
                    <li><TiTickOutline className='price-color'/> {pricing02_item3}</li>
                </ul>
                <h2>{pricing02_package}</h2>
        
                <div>
                <button className='btn-pricing02'>Get Started</button>
                </div>

                </div>
            
    </>
  )
}

export default Pricing02