import React from 'react'
import headerImage from '../04/hero04.png'
import '../04/Hero04.css'
const Hero04 = () => {
  return (
    <>
          <div className='hero4-heading'>
            <h1>Split Screen With Image</h1>
         </div>
         <section className='hero04'>
        
            <div className='hero04-container container'>
                <div className='hero04-textarea'>
                    <h1>Web Software <br />Developers</h1>
                    <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum</p>
                    <a href="" className='btn-hero04'>Read More</a>
                </div>
                <div className='hero04-imagearea'>
                    <img src={headerImage} alt="" />
                </div>
            </div>
        </section>
    </>
  )
}

export default Hero04