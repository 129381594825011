import React from 'react'
import { NavLink } from 'react-router-dom'
import Footer from '../../../Footer/Footer'
import IMAGES from '../../../Images'
import Navbar from '../../../Navbar/Navbar'
import { Elb_block } from '../../content/loadbalancer/Elb_block'

export const Aws_Loadbalancer = () => {
return (
<>
<Navbar/>
  <section className='template'>
      <div className='template-container container'>
      <h1>Load Balancer with AutoScaling</h1>        

      

      
      
      <img src={IMAGES.AWS98} alt="" />          
      

      <h1>Load Balancer with Cloud Front</h1>          

      
      <img src={IMAGES.AWS99} alt="" />          
      
      </div>
      <div className='template-buttons container'>

<NavLink to="/aws_3tier"><button className='template-back'>Back</button></NavLink>
<NavLink to="/aws_cloudfront"><button className='template-next'>Next</button></NavLink>            



      
      </div>
      <div className='template-container container'>
      <h2>Read More</h2>           
      </div>
      <Elb_block/>            
  </section>
  <Footer/>
</>

)
}
