import React from 'react'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'
import CardsIndex from '../subComponents/Cards/01/CardsIndex'
import RepairIndex from '../subComponents/Cards/02/RepairIndex'
import DesignIndex from '../subComponents/Cards/03/DesignIndex'


const Cards_ = () => {

  return (
   <>
        <Navbar/>  
         <CardsIndex/>
        <RepairIndex/>
        <DesignIndex/>        
        <Footer/>
   </>
  )
}

export default Cards_