import React from 'react'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'
import cs from '../assets/cs/cs.png'

export const Comingsoon = () => {
  return (
    <>
    <Navbar/>
        <section className='template'>
            <div className='template-container container'>
            
            <h1>Work in Progress</h1>
            <img src={cs} alt="" />
            <p>Please check back later</p>

            </div>            
       </section>
        <Footer/>
    </>
    
  )
}
