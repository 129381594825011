import React, { useState } from 'react'
import './About.css'

const About = ({questions, answers}) => {

    const [show, setShow] = useState(false)
    

  return (
   
        
            <div className='about-items container60rem'>
            <div className='questions'>
                
                <h1>{questions}</h1>
               
                <p onClick={()=>setShow(!show)}>
                {show ? '➖' : '➕'}
                </p>
            </div>
            <div className='answers'>
            {
                show && 
                <div className='about-image'>
                <img src={answers} alt="" />
                </div>
                
                
            }
                
            </div>
            </div>
            
        
    
  )
}

export default About