import React, { useState } from 'react'
import Desktop from './Desktop'
import Mobile from './Mobile'
import {FiMenu} from 'react-icons/fi'
import {CgClose} from 'react-icons/cg'
import logo from '../assets/logo/main-logo.png'
import './Navbar.css'
import { NavLink } from 'react-router-dom'

const Navbar = () => {

    const [toggleMenu, setToggleMenu] = useState(false)

    const openHam = <FiMenu onClick={()=>setToggleMenu(!toggleMenu)}/>
    const closeHam = <CgClose onClick={()=>setToggleMenu(!toggleMenu)}/>
  return (
    <>
    <section className='navbar'>
    <section className='navbar-desktop'>
            <div className='desktop-container container'>
            <div className='logo'>
                <a href="https://ammar.tech"><img src={logo} alt="" /></a>
               {/* <NavLink to="/">A</NavLink> */}
            </div>
                <div className='desktop-menulinks'>
                    <Desktop/>
                </div>
                <div className='ham-icon'>
        {
            toggleMenu ? closeHam : openHam
        }
        
        </div>
 
            </div>
        </section>

        <section className='navbar-mobile'>
        <div className='mobile-container'>
        {/* <div className='ham-icon'>
        {
            toggleMenu ? closeHam : openHam
        }
        
        </div> */}
        <div className='mobile-menulinks'>

{
    toggleMenu && <Mobile/>
}    
    
</div>
           
        </div>

        </section>
    </section>
        
    </>
  )
}

export default Navbar