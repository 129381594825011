export const WebCompData = [

    {        
        id : 1,     
        web_img : '/assets/webcomponents/abs11.jpg',
        web_heading : 'Authentication',
        Live_Demo : 'https://ammar.tech/auth',
        View_Demo : 'https://ammar.tech/auth'
    },
   
    {
        id : 2,     
        web_img : '/assets/webcomponents/abs02.jpg',
        web_heading : 'Hero',
        Live_Demo : 'https://ammar.tech/hero',
        View_Demo : 'https://ammar.tech/hero'
    },
    {   
        id : 3,          
        web_img : '/assets/webcomponents/abs03.jpg',
        web_heading : 'Cards',
        Live_Demo : 'https://ammar.tech/cards',
        View_Demo : 'https://ammar.tech/cards'
    },
    {        
        id : 4,     
        web_img : '/assets/webcomponents/abs04.jpg',
        web_heading : 'Footer',
        Live_Demo : 'https://ammar.tech/footer',
        View_Demo : 'https://ammar.tech/footer'
    },
    {        
        id : 5,     
        web_img : '/assets/webcomponents/abs05.jpg',
        web_heading : 'Testimonials',
        Live_Demo : 'https://ammar.tech/testimonials',
        View_Demo : 'https://ammar.tech/testimonials'
    },
    {        
        id : 6,     
        web_img : '/assets/webcomponents/abs06.jpg',
        web_heading : 'Pricing',
        Live_Demo : 'https://ammar.tech/pricing',
        View_Demo : 'https://ammar.tech/pricing'
    },
    {        
        id : 7,     
        web_img : '/assets/webcomponents/abs07.jpg',
        web_heading : 'Carousels',
    },
    {        
        id : 8,     
        web_img : '/assets/webcomponents/abs08.jpg',
        web_heading : 'Accordion',
    },
    {        
        id : 9,     
        web_img : '/assets/webcomponents/abs09.jpg',
        web_heading : 'Product Details',
        Live_Demo : 'https://ammar.tech/pdetails',
        View_Demo : 'https://ammar.tech/pdetails'
    },
    {        
        id : 10,     
        web_img : '/assets/webcomponents/abs10.jpg',
        web_heading : 'Contact Forms',
        Live_Demo : 'https://ammar.tech/contacts',
        View_Demo : 'https://ammar.tech/contacts'
    },
    {   
        id : 11,     
        web_img : '/assets/webcomponents/abs01.jpg',
        web_heading : 'Navigation',
        Live_Demo : '',
    },
  
    {        
        id : 12,     
        web_img : '/assets/webcomponents/abs12.jpg',
        web_heading : 'Newsletter',
        Live_Demo : 'https://ammar.tech/news',
        View_Demo : 'https://ammar.tech/news'
    },
    {        
        id : 13,     
        web_img : '/assets/webcomponents/abs13.jpg',
        web_heading : 'Contact',
        Live_Demo : 'https://ammar.tech/contacts',
        View_Demo : 'https://ammar.tech/contacts'
    },
    {        
        id : 14,     
        web_img : '/assets/webcomponents/abs14.jpg',
        web_heading : 'Features',
        Live_Demo : 'https://ammar.tech/features',
        View_Demo : 'https://ammar.tech/features'
    },
    {        
        id : 15,     
        web_img : '/assets/webcomponents/abs15.jpg',
        web_heading : 'Buttons',
    },
    {        
        id : 16,     
        web_img : '/assets/webcomponents/abs16.jpg',
        web_heading : 'Social Media',
        Live_Demo : 'https://ammar.tech/social',
        View_Demo : 'https://ammar.tech/social'
    },
]