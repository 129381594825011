import React from 'react'
import '../02/Social02.css'
import {BsFacebook} from 'react-icons/bs'
import {FcGoogle} from 'react-icons/fc'
import {BsLinkedin} from 'react-icons/bs'
import {BsMessenger} from 'react-icons/bs'

const Social02 = () => {
  return (
    <>
      <section className='social02'>
      <div className='social02-heading'>
      <h1>Sign in with Social account</h1>
      </div>
      
        <div className='social02-container container'>
       
        <div className='social02-form'>
 
            <div className='social02-input'>
            <h1>Sign in to your account</h1>
            <p className='social02-p'>Email Address</p>
            <input className='social02-x' type="Email"/>
            <p className='social02-p'>Password</p>
            <input className='social02-x' type="Password" name="" id="" />
            <div className='social02-checkbox'>
            <input type="checkbox" name="" id="" />
            <p>Remeber me</p>
            <a href="">Forgot password ?</a>
            </div>
            <button className='social02-btn'>
            Sign in
            </button>         

            </div>
                </div>
                <div className='social02-buttons-container'>
              
                <div className='social02-buttons'>
          
          <button className='social02-facebook'><BsFacebook/> Continue with Facebook</button>
          <button className='social02-google'><FcGoogle/> Sign in with Google</button>
          <button className='social02-linkedin'><BsLinkedin/> Sign in with Linkedin</button>
          <button className='social02-messenger'><BsMessenger/> Sign in with Messenger</button>

          </div>
                </div>

         
        </div>
      </section>
    </>
    
    
  )
}

export default Social02