export const Feature02Data = [
    {
        id : 0,
        featureIcon : '/Features/01.png',
        featureHeading : 'Feature Text 1',
        featureText:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore...'
    },
    {   id : 1,
        featureIcon : '/Features/02.png',
        featureHeading : 'Feature Text 2',
        featureText:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore...'
    },
    {   id : 2,
        featureIcon : '/Features/03.png',
        featureHeading : 'Feature Text 3',
        featureText:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore...'
    },
]