import React from 'react'
import '../03/Feature03.css'
import {GiAbstract030, GiAbstract059, GiAbstract072} from 'react-icons/gi'


const Feature03 = () => {
  return (
    <>
        <section className='feature03'>
        <div className='feature03-heading'>
            <h1>2 Column with Image</h1>            
        </div>
        <div className='feature03-container container'>
        <div className='feature03-text'>
        <h2>A digital Product Design Agency</h2>
        <p>
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore  
        </p>
        <h1><GiAbstract030/> Business Planning</h1>
        <h1><GiAbstract059/> Financial Planning</h1>
        <h1><GiAbstract072/> Market Analysis</h1>
        </div>
        <div className='feature03-image'>
        <img src="/Features/04.jpg" alt="" />
        </div>
        </div>
        </section>
    </>
  )
}

export default Feature03