import React from 'react'
import SubServices from './SubServices'
import { SubServicesApi } from './SubServicesApi'

const SubServicesIndex = () => {
  return (
   <>
   <section className='services-wrapper'>

   
<section className='services-flex container'>


<div className='services01'>
            <div className='services-container container'>
                <div className='services-text'>
                    <h1>All your Features in One Place, right across your finger tip </h1>
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Incidunt delectus autem consequatur, ratione quia odit nemo amet dicta soluta doloribus.</p>
                    <button className='btn-services'>Learn More</button>
                </div>
            </div>
            </div>
       
   <div className='services02'>
        <div className='services-container02 container'>
       {
        SubServicesApi.map((items, index)=>(
            <SubServices
             icon={items.icon}
             heading={items.heading}
             text={items.text}
            />
        )

        ) 
       }
       
       

       </div>
            
        </div>
</section>  
</section>     
   </>
  )
}

export default SubServicesIndex