import React from 'react'
import Footer from '../../../Footer/Footer'
import IMAGES from '../../../Images/'
import Navbar from '../../../Navbar/Navbar'
import { NavLink } from 'react-router-dom'

export const Vm_ip = () => {
  return (
    <>
    <Navbar/>
        <section className='template'>
            <div className='template-container container'>
            <h1>IP Configuration</h1>           
            <h2>Associate Public IP Address</h2>
            <p>if you have removed your public ip address then you can associate public ip again
</p>
            <p>From the resources, select your public ip address</p>        
            <img src={IMAGES.AWS421} alt="" /> 
            <p>Once selected, click on associate. </p> 
            <img src={IMAGES.AWS422} alt="" />         
            <p>Select your network interface.</p>
            <div className='template-container80rem' >
            <img src={IMAGES.AWS423} alt="" />  
            </div>           
            <div className='template-container80rem' >
            <img src={IMAGES.AWS424} alt="" />          
            </div>        
            <h2>Start VM</h2>
            <p>Here you can see your public ip address.</p>
            <img src={IMAGES.AWS425} alt="" />   
            <h2>Changing Public IP to Static</h2> 
            <p>Click on configuration and change it to static</p>      
            <img src={IMAGES.AWS426} alt="" />        
            <div className='template-container80rem' >
            <img src={IMAGES.AWS427} alt="" />          
            </div>  
            <h2>Change Private ip to Static</h2>            
            <p>Select your network interface card</p>                       
            <img src={IMAGES.AWS429} alt="" />  
            <p>click on ipconfig1</p>             
            <img src={IMAGES.AWS430} alt="" /> 
            <p>Change dynamic to static</p>     
            <img src={IMAGES.AWS431} alt="" />          
            <h2>Changing DNS Server</h2>   
            <p>click on DNS Server and change it to 8.8.8.8</p>    
            <p>here you can add private ip address, 8.8.8.8 is public dns and will not work.</p> 
            <img src={IMAGES.AWS432} alt="" />   
            <h2>RDP on your VM</h2>       
            <div className='template-container80rem' >
            <img src={IMAGES.AWS433} alt="" />        
            <img src={IMAGES.AWS434} alt="" />        
            
            </div>
            <img src={IMAGES.AWS435} alt="" />          
            <img src={IMAGES.AWS436} alt="" />     
            <h2>Ip Address Allocation</h2>
            <p>Private ip addresses assigned from microsoft will always start with .4 ip
            </p>
            <ul>
<li>10.0.1.1	Reserved by Microsoft</li>            
<li>10.0.1.2	Reserved by Microsoft	</li>
<li>10.0.1.3	Reserved by Microsoft			</li>
<li>10.0.1.255	Reserved by Microsoft (Last ip)</li>				
<p>Starting from 10.0.1.4 and so on.	</p>

            </ul>
            </div>
            
            <div className='template-buttons container'>
            <NavLink to="/vm_ci"><button className='template-back'>Back</button></NavLink>
            <NavLink to="/vm_snapshot"><button className='template-next'>Next</button></NavLink>
            </div>
       </section>
       <Footer/>
    </>
    
  )
}
