import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './Home'
import About_ from './Pages/About_';
import Auth_ from './Pages/Auth_';
import Contact_ from './Pages/Contact_';
import Contacts_ from './Pages/Contacts_';
import Footer_ from './Pages/Footer_';
import Hero_ from './Pages/Hero_';
import News_ from './Pages/News_';
import Portfolio_ from './Pages/Portfolio_';
import Skills_ from './Pages/Skills_';
import Social_ from './Pages/Social_';
import Cards_ from './Pages/Cards_';
import Features_ from './Pages/Features_';
import Testimonials_ from './Pages/Testimonials_';
import Pricing_ from './Pages/Pricing_';
import Pdetails_ from './Pages/Pdetails_';
import Blog_ from './Pages/Blog/Blog_';
import { AccessKeys } from './Aws/content/IAM/AccessKeys';
import { UserAccounts } from './Aws/content/IAM/UserAccounts';
import { SigninUrl} from './Aws/content/IAM/SigninUrl';
import { Roles } from './Aws/content/IAM/Roles';
import { Iam_policy } from './Aws/content/IAM/Iam_policy';
import { Iam_secTools } from './Aws/content/IAM/Iam_secTools';
import { Iam_metaData } from './Aws/content/IAM/Iam_metaData';
import { Iam_sdk } from './Aws/content/IAM/Iam_sdk';
import { Iam_billing } from './Aws/content/IAM/Iam_billing';
import { Aws_directory } from './Aws/content/Solutions/Aws_directory';
import { Aws_3tier } from './Aws/content/Solutions/Aws_3tier';
import { Aws_Loadbalancer } from './Aws/content/Solutions/Aws_Loadbalancer';
import { Aws_cloudfront } from './Aws/content/Solutions/Aws_cloudfront';
import { Aws_clientvpn } from './Aws/content/Solutions/Aws_clientvpn';
import { Aws_sitevpn } from './Aws/content/Solutions/Aws_sitevpn';
import { Comingsoon } from './Blog/Comingsoon';
import { Con_autoscaling} from './Aws/content/loadbalancer/Con_autoscaling';
import { Con_ssl } from './Aws/content/loadbalancer/Con_ssl';
import { Con_loadbalancer } from './Aws/content/loadbalancer/Con_loadbalancer';
import { Con_addcloudfront } from './Aws/content/loadbalancer/Con_addcloudfront';
import { Con_overview } from './Aws/content/loadbalancer/Con_overview';
import { Iam_block } from './Aws/content/IAM/Iam_block';
import Aws from './Aws/index/01/Aws';
import { Elb_block } from './Aws/content/loadbalancer/Elb_block';
import { Elb_overview } from './Aws/content/loadbalancer/Elb_overview';
import { Elb_info } from './Aws/content/loadbalancer/Elb_info';
import { Clb_info } from './Aws/content/loadbalancer/Clb_info';
import { Alb_overview } from './Aws/content/loadbalancer/Alb_overview';
import { Nlb_info } from './Aws/content/loadbalancer/Nlb_info';
import { Glb_info } from './Aws/content/loadbalancer/Glb_info';
import { Asg_overview } from './Aws/content/loadbalancer/Asg_overview';
import { Asg_policy } from './Aws/content/loadbalancer/Asg_policy';
import { Vm_setup } from './Azure/content/Vm/Vm_setup';
import { Vm_block } from './Azure/content/Vm/Vm_block';
import Azure from './Azure/index/01/Azure';
import { Vm_cs } from './Azure/content/Vm/Vm_cs';
import { Vm_ci } from './Azure/content/Vm/Vm_ci';
import { Vm_ip } from './Azure/content/Vm/Vm_ip';
import { Vm_snapshot } from './Azure/content/Vm/Vm_snapshot';
import { Vm_proximity } from './Azure/content/Vm/Vm_proximity';
import { Vm_ud } from './Azure/content/Vm/Vm_ud';
import { Vm_bootd } from './Azure/content/Vm/Vm_bootd';
import { Vm_diske } from './Azure/content/Vm/Vm_diske';
import { Vm_addisk } from './Azure/content/Vm/Vm_addisk';

const App = () => {
  return (
   <>
   <Routes>
          <Route exact path = '/' element = {<Home/>}></Route>          
          <Route path = 'portfolio' element = {<Portfolio_/>}></Route>
          <Route path = '/skills' element = {<Skills_/>}></Route>
          <Route path = '/about' element = {<About_/>}></Route>
          <Route path = '/contact' element = {<Contact_/>}></Route>
          <Route path = '/footer' element = {<Footer_/>}></Route>
          <Route path = '/hero' element = {<Hero_/>}></Route>
          <Route path = '/auth' element = {<Auth_/>}></Route>
          <Route path = '/social' element = {<Social_/>}></Route>
          <Route path = '/news' element = {<News_/>}></Route>
          <Route path = '/contacts' element = {<Contacts_/>}></Route>
          <Route path = '/cards' element = {<Cards_/>}></Route>
          <Route path = '/features' element = {<Features_/>}></Route>
          <Route path = '/testimonials' element = {<Testimonials_/>}></Route>
          <Route path = '/pricing' element = {<Pricing_/>}></Route>
          <Route path = '/pdetails' element = {<Pdetails_/>}></Route>          
          <Route path = '/aws' element = {<Aws/>}></Route>
          <Route path = '/blog' element = {<Blog_/>}></Route>          
          <Route path = '/userAccounts' element = {<UserAccounts/>}></Route>          
          <Route path = '/signinurl' element = {<SigninUrl/>}></Route>
          <Route path = '/accesskeys' element = {<AccessKeys/>}></Route>                    
          <Route path = '/iam_roles' element = {<Roles/>}></Route>   
          <Route path = '/iam_policy' element = {<Iam_policy/>}></Route>  
          <Route path = '/iam_sectools' element = {<Iam_secTools/>}></Route>                    
          <Route path = '/iam_metadata' element = {<Iam_metaData/>}></Route>                    
          <Route path = '/iam_sdk' element = {<Iam_sdk/>}></Route>                    
          <Route path = '/iam_billing' element = {<Iam_billing/>}></Route>                   
          <Route path = '/iam_block' element = {<Iam_block/>}></Route> 
          <Route path = '/aws_directory' element = {<Aws_directory/>}></Route>  
          <Route path = '/aws_3tier' element = {<Aws_3tier/>}></Route>             
          <Route path = '/aws_cloudfront' element = {<Aws_cloudfront/>}></Route>   
          <Route path = '/aws_clientvpn' element = {<Aws_clientvpn/>}></Route> 
          <Route path = '/aws_sitevpn' element = {<Aws_sitevpn/>}></Route>  
          <Route path = '/comingsoon' element = {<Comingsoon/>}></Route>   
          <Route path = '/con_autoscaling' element = {<Con_autoscaling/>}></Route>  
          <Route path = '/con_ssl' element = {<Con_ssl/>}></Route>  
          <Route path = '/con_loadbalancer' element = {<Con_loadbalancer/>}></Route>  
          <Route path = '/con_addcloudfront' element = {<Con_addcloudfront/>}></Route>   
          <Route path = '/aws_loadbalancer' element = {<Aws_Loadbalancer/>}></Route>                     
          <Route path = '/elb_overview' element = {<Elb_overview/>}></Route>  
          <Route path = '/elb_info' element = {<Elb_info/>}></Route>   
          <Route path = '/clb_info' element = {<Clb_info/>}></Route>   
          <Route path = '/alb_overview' element = {<Alb_overview/>}></Route>   
          <Route path = '/nlb_info' element = {<Nlb_info/>}></Route>   
          <Route path = '/elb_block' element = {<Elb_block/>}></Route>  
          <Route path = '/glb_info' element = {<Glb_info/>}></Route>            
          <Route path = '/con_overview' element = {<Con_overview/>}></Route>  
          <Route path = '/asg_overview' element = {<Asg_overview/>}></Route>  
          <Route path = '/asg_policy' element = {<Asg_policy/>}></Route>     
          {/* ----------------------Azure Pages-----------------------------     */}
          <Route path = '/azure' element = {<Azure/>}></Route>     
          <Route path = '/vm_block' element = {<Vm_block/>}></Route> 
          <Route path = '/vm_setup' element = {<Vm_setup/>}></Route> 
          <Route path = '/vm_addisk' element = {<Vm_addisk/>}></Route>    
          <Route path = '/vm_cs' element = {<Vm_cs/>}></Route>     
          <Route path = '/vm_ci' element = {<Vm_ci/>}></Route>     
          <Route path = '/vm_ip' element = {<Vm_ip/>}></Route>     
          <Route path = '/vm_snapshot' element = {<Vm_snapshot/>}></Route>     
          <Route path = '/vm_proximity' element = {<Vm_proximity/>}></Route>     
          <Route path = '/vm_ud' element = {<Vm_ud/>}></Route>     
          <Route path = '/vm_bootd' element = {<Vm_bootd/>}></Route>     
          <Route path = '/vm_diske' element = {<Vm_diske/>}></Route>     
          <Route path = '*' element = {<Home/>}></Route>
          
      </Routes>
   </>
  )
}

export default App