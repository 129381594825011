import React from 'react'
import './Footer.css'
const Footer = () => {
  return (
    <>
        <footer className='footer'>
        <div className='footer-container'>
        <p>Developed by : Ammar Rashid</p>            
        </div>
            
        </footer>
    </>
  )
}

export default Footer