import React from 'react';
import Footer from '../../../Footer/Footer';
import IMAGES from '../../../Images/';
import Navbar from '../../../Navbar/Navbar';
import { NavLink } from 'react-router-dom';

export const Vm_bootd = () => {
  return (
    <>
      <Navbar />
      <section className="template">
        <div className="template-container container">
          <h1>Boot diagnostics</h1>
          <img src={IMAGES.AWSE498} alt="" />
          <p>
            Browse to support + Troubleshoot section of your vm, here you will
            see second option, onclicking it says boot diagnostics is not
            configured, so we have to configure it first.
          </p>
          <div className="template-container60rem">
            <img src={IMAGES.AWS499} alt="" />
            <img src={IMAGES.AWS500} alt="" />
          </div>
          <img src={IMAGES.AWS501} alt="" />
          <h2>Setting up Boot Diagnostics</h2>
          <p>We need a storage account, so that we can store logs in it.</p>
          <img src={IMAGES.AWS502} alt="" />
          <p>
            You can take a screenshot, which will store in your storage account.
          </p>
          <img src={IMAGES.AWS503} alt="" />
          <h2>Configure Serial Console</h2>
          <p>
            Serial console will not work with managed stoarge account so we have
            to change our storage account from managed to custom.
          </p>
          <img src={IMAGES.AWS504} alt="" />
          <p>
            Go back to the boot diagnostics settings. <br /> Select 2nd option.
          </p>
          <img src={IMAGES.AWS505} alt="" />
          <p>Name your Storage account.</p>
          <div className="template-container60rem">
            <img src={IMAGES.AWS506} alt="" />
          </div>

          <img src={IMAGES.AWS507} alt="" />
          <p>Go back to serial console and you will see below window.</p>
          <img src={IMAGES.AWS508} alt="" />
          <p>Press Esc + Tab.</p>
          <img src={IMAGES.AWS509} alt="" />
          <p>Cmd is now available.</p>
          <div className="template-container80rem">
            <img src={IMAGES.AWS510} alt="" />
          </div>
          <p>press esc+tab again, and then press tab.</p>
          <img src={IMAGES.AWS511} alt="" />
          <div className="template-container60rem">
            <img src={IMAGES.AWS512} alt="" />
            <img src={IMAGES.AWS513} alt="" />
          </div>
          <p>You are now logged into system.</p>
          <div className="template-container80rem">
            <img src={IMAGES.AWS514} alt="" />
            <img src={IMAGES.AWS515} alt="" />
          </div>
          <h2>Location of Logs</h2>
          <p>From your storage accounts, select your custom storage account.</p>
          <img src={IMAGES.AWS516} alt="" />
          <p>Click on containers.</p>
          <img src={IMAGES.AWS517} alt="" />
          <p>Open container.</p>
          <img src={IMAGES.AWS518} alt="" />
          <p>
            Here you will see your log file and bmp file. Download bmp file.
          </p>
          <img src={IMAGES.AWS519} alt="" />
          <p>Here is your downloaded bmp file.</p>
          <img src={IMAGES.AWS520} alt="" />
        </div>
        <div className="template-buttons container">
          <NavLink to="/vm_ud">
            <button className="template-back">Back</button>
          </NavLink>
          <NavLink to="/vm_diske">
            <button className="template-next">Next</button>
          </NavLink>
        </div>
      </section>
      <Footer />
    </>
  );
};
