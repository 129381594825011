import React from 'react'
import WithLogo from './WithLogo'
import { WithLogoData } from './WithLogoData'
import wlogo from '../03/logo1.svg'

const WithLogoIndex = () => {
  return (
    <>
    <section className='withlogo'>
            <div className='withlogo-heading container'>
                <h1>Footer Links with Logo</h1>
            </div>
            <div className='wlogo-container container'>
           
               
                {
            WithLogoData.map((items)=>(
                <WithLogo
                    wheading={items.wheading}
                    wlogo={items.wlogo}
                    tag={items.tag}
                    item1={items.item1}
                    item2={items.item2}
                    item3={items.item3}
                    item4={items.item4}
                    item5={items.item5}                    
                    key= {items.id}
                />
            ))
        }
               
       
        </div>
        </section>
        
    </>
  )
}

export default WithLogoIndex