import React from 'react'
import Footer from '../../../Footer/Footer'
import Navbar from '../../../Navbar/Navbar'
import IMAGES from '../../../Images'

export const Con_loadbalancer = () => {
  return (
    <>
    <Navbar/>
        <section className='template'>
            <div className='template-container container'>
            <h1>Load Balancer</h1>
            <h2>Application Load Balancer</h2>  
            <div className='template-container60rem' >
            <img src={IMAGES.AWS181} alt="" />          
            </div>  
            <p>Name your Load Balancer</p>                    
            <img src={IMAGES.AWS182} alt="" />  
            <p>Select your subnets</p>        
            <img src={IMAGES.AWS183} alt="" />          
            <p>Remove the default security group, and create a new security group.</p>
            <img src={IMAGES.AWS184} alt="" />                     
            <img src={IMAGES.AWS185} alt="" />  
            <p>here we have to create target groups</p>                
            <img src={IMAGES.AWS186} alt="" />          
            <h2>Create Target Groups</h2>     
            <p>To achieve listerners and routing, we have to define target group first, choose instance as target group.
</p>
            <img src={IMAGES.AWS187} alt="" />   
            <p>Name your target, and choose settings as below</p>       
            <img src={IMAGES.AWS188} alt="" /> 
            <p>Configure Health Checks</p>         
            <img src={IMAGES.AWS189} alt="" />          
            <img src={IMAGES.AWS190} alt="" />  
            <p>Register Targets</p>        
            <img src={IMAGES.AWS191} alt="" /> 
            <p>Add your two webservers.</p>         
            <img src={IMAGES.AWS192} alt="" />                  
            <img src={IMAGES.AWS193} alt="" /> 
            <h2>Back to Load Balancer</h2>   
            <p>Select your target group which you have created</p>          
            <img src={IMAGES.AWS194} alt="" />          
            <img src={IMAGES.AWS195} alt="" />          
            <img src={IMAGES.AWS196} alt="" /> 
            <p>Your Load Balancer is created.</p>         
            <img src={IMAGES.AWS197} alt="" />     
            <p>Copy the DNS address of your load balancer check in browser</p>     
            <img src={IMAGES.AWS198} alt="" />          
            <img src={IMAGES.AWS199} alt="" /> 
            <h2>Create another Target Group</h2>         
            <img src={IMAGES.AWS200} alt="" />     
            <p>Set Health check</p>     
            <img src={IMAGES.AWS201} alt="" />     
            <p>Add your remaining one webserver in it</p>     
            <img src={IMAGES.AWS202} alt="" />          
            <img src={IMAGES.AWS203} alt="" /> 
            <p>Here you can see your two target groups</p>         
            <img src={IMAGES.AWS204} alt="" />        
            <h2>Back to Load Balancer</h2>  
            <p>Add Multiple Listeners, click on listeners</p>
            <img src={IMAGES.AWS205} alt="" />     
            <p>we can add another rule on the same listener id</p>     
            <img src={IMAGES.AWS206} alt="" />  
            <p>we have defined, if path is /constant after the address, then return fixed response</p><br />
            <p>If path is /test, then forward to mysecond-target-group</p>
            <img src={IMAGES.AWS207} alt="" />  
            <h2>Testing</h2>            
            <img src={IMAGES.AWS208} alt="" />          
            <p>Testing Constant path</p>
            <img src={IMAGES.AWS209} alt="" />  
            <p>Testing /path, it went to the second-target-group, and it didn't find the url we requested on that server, because we have not defined that url on that server, so we have received not found on this server</p>        
            <img src={IMAGES.AWS210} alt="" />          
            </div>
            <div className='template-buttons container'>
            <a href="/alb_overview"><button className='template-back'>Back</button></a>
            <a href="/nlb_info"><button className='template-next'>Next</button></a>
            </div>
       </section>
       <Footer/>
    </>
    
  )
}
