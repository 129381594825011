export const AwsSolutionsData = [
    {
        id:1,
        projectImage:'assets/aws/solutions/ad01.png',
        projectName:'AWS Directory Services',
        View:'/aws_directory',
        // View:'https://ammar.tech/aws_directory',
        

    },
    {
        id:2,
        projectImage:'assets/aws/solutions/threetier.png',
        projectName:'Three Tier Application',
        View:'/aws_3tier',
        

    },
    {
        id:3,
        projectImage:'assets/aws/solutions/loadbalancer.png',
        projectName:'Load Balancer',
        View:'/aws_loadbalancer',
        

    },
    {
        id:4,
        projectImage:'assets/aws/solutions/cloudfront.png',
        projectName:'CloudFront',
        View:'/aws_cloudfront',
        

    },
    {
        id:5,
        projectImage:'assets/aws/solutions/clienttosite.png',
        projectName:'Client to Site Network',
        View:'/aws_clientvpn',
        

    },
    {
        id:6,
        projectImage:'assets/aws/solutions/sitetosite.png',
        projectName:'Site to Site Network',
        View:'/aws_sitevpn', 

    },
]