import React from 'react';
import Footer from '../../../Footer/Footer';
import IMAGES from '../../../Images/';
import Navbar from '../../../Navbar/Navbar';
import { NavLink } from 'react-router-dom';

export const Vm_ud = () => {
  return (
    <>
      <Navbar />
      <section className="template">
        <div className="template-container container">
          <h1>Unmanaged Disk</h1>
          <h2>Create VM from Unamanged Disk</h2>
          <p>Add Virtual Machine.</p>
          <img src={IMAGES.AWS478} alt="" />
          <p>Create Virtual Machine.</p>
          <img src={IMAGES.AWS479} alt="" />
          <p>
            Note : under advanced section, just uncheck the option for managed
            disk and select or create a new storage account.
          </p>
          <img src={IMAGES.AWS480} alt="" />
          <p>Rest of the settings are same as we did before.</p>
          <img src={IMAGES.AWS481} alt="" />
          <img src={IMAGES.AWS482} alt="" />
          <img src={IMAGES.AWS483} alt="" />
          <p>Review + Create.</p>
          <img src={IMAGES.AWS484} alt="" />
          <p>Your Deployment is in Progress.</p>
          <img src={IMAGES.AWS485} alt="" />
          <h2>View your Unmanaged Disk</h2>
          <p>From Storage Account, select your disk.</p>
          <img src={IMAGES.AWS486} alt="" />
          <p>Click on containers.</p>
          <img src={IMAGES.AWS487} alt="" />
          <p>Browse into vhds.</p>
          <img src={IMAGES.AWS488} alt="" />
          <p>Here you will see your unmanged disk.</p>
          <img src={IMAGES.AWS489} alt="" />
          <h2>Virtual Machine</h2>
          <p>
            We can see our VM is up and running, you can rdp into it, and it
            will work
          </p>
          <img src={IMAGES.AWS490} alt="" />
          <p>
            If you see an option, migrate to managed disks, that means you are
            using unmanaged disks.
          </p>
          <img src={IMAGES.AWS491} alt="" />
          <div className="template-container80rem">
            <img src={IMAGES.AWS492} alt="" />
          </div>
          <h2>Compare VM under Disks</h2>
          <p>
            If you want to see how managed disk options looks like under VM,
            then see below.
          </p>
          <img src={IMAGES.AWS493} alt="" />
          <h2>Migrate to Managed Disk</h2>
          <p>click on migrate to managed disk</p>
          <img src={IMAGES.AWS494} alt="" />
          <div className="template-container80rem">
            <img src={IMAGES.AWS495} alt="" />
          </div>
          <p>
            Process require downtime from client. <br />
            It will stop your VM during migration process. <br />
            Once migration complete, it will just create another managed disk
            and attach it to your vm. <br />
            It will not delete your unmanaged disk, you can still find it
            un-allocated under your storage.
          </p>
          <h2>After Migration</h2>
          <img src={IMAGES.AWS496} alt="" />
          <p>You can also view it under disks section.</p>
          <img src={IMAGES.AWS497} alt="" />
          <p>
            Your unmanaged disk is still showing under storage accounts. <br />
            Note the lease status is now set to available.
          </p>
          <img src={IMAGES.AWS498} alt="" />
        </div>
        <div className="template-buttons container">
          <NavLink to="/vm_proximity">
            <button className="template-back">Back</button>
          </NavLink>
          <NavLink to="/vm_bootd">
            <button className="template-next">Next</button>
          </NavLink>
        </div>
      </section>
      <Footer />
    </>
  );
};
