import React from 'react'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import WebCompIndex from '../WebComp/WebCompIndex'
import WebIndex from '../WebPortfolio/WebIndex'
import AwsSolutionsIndex from '../Aws/index/02/AwsSolutionsIndex'
import Wordpress from '../Wordpress/Wordpress'

const Portfolio_ = () => {
  return (
    <>
      <Navbar/>
      <AwsSolutionsIndex/>
      <WebCompIndex/>
      <WebIndex/>    
      <Wordpress/>

   
      <Footer/>
    </>
  )
}

export default Portfolio_