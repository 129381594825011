import React from 'react'
import './Hero03.css'

const Hero03 = () => {
  return (
   <>
       <div className='hero03-heading'>
            <h1>CTA with Annotation</h1>
       </div>
       <section class="hero03 container">       
        <div class="hero03-container">
            <h1><p>Make Money From</p><span className='hero3-span'>Your Audience</span></h1>
            <p>Lorem, ipsum dolor sit amet consectetur Animi, nesciunt?Lorem, ipsum dolor sit amet consectetur Animi, nesciunt?Lorem, ipsum dolor sit amet consectetur Animi, nesciunt?</p>
                      
           <a href="">
           <button className='btn-hero3'>Get Started</button> 
           </a> 
           <a href="">Learn More</a>
            
        </div>        
    </section>
   </>
  )
}

export default Hero03