import React from 'react'
import AwsSolutionsIndex from './Aws/index/02/AwsSolutionsIndex'
import Contact from './Contact/Contact'
import Footer from './Footer/Footer'
import Header from './Header/Header'
import Navbar from './Navbar/Navbar'
import SysadminIndex from './Skills/01/SysadminIndex'
import SkillsIndex from './Skills/SkillsIndex'
import WebCompIndex from './WebComp/WebCompIndex'
import WebIndex from './WebPortfolio/WebIndex'
import Wordpress from './Wordpress/Wordpress'

const Home = () => {
  return (
    <>
      <Navbar/>      
      <Header/>
      <SysadminIndex/>
      <AwsSolutionsIndex/>
      <SkillsIndex/>
      <WebIndex/>   
      <WebCompIndex/>
      <Wordpress/>
      <Contact/>
      <Footer/>
    
    </>
  )
}

export default Home