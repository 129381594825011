import React from 'react';
import "../02/Contact02.css"
import contact from '../02/contact02.png'
import { NavLink } from 'react-router-dom';

const Contact02 = () => {
  return (
      <>
          <section className='sub02-contact'>
            <div className='sub02-contact-container container'>
                <div className='sub02-contact-text'>
                <h1>Contact Us</h1>
                <h2>+61 0416466039<br />
                ammarr@msn.com</h2>
                <p>
                Thank you for your interest in Turner Photography Studio. We would love to hear from you!Our hours of operation are <span>Tuesday - Friday 10am to 6pm,</span> appointments preferred. Please fill out the contact form below and you will hear from us shortly!</p>
                </div>
                <div className='sub02-contact-image'>
                <img src={contact} alt="" />
                </div>
                <div className='sub02-contact-input'>
                <input className='sub02-input-area' type="text" placeholder='Enter your Name' />
                <input className='sub02-input-area' type="email" placeholder='Enter a Valid Email Address' />
                <textarea className='sub02-input-area' name="" id="" cols="30" rows="10" placeholder='Enter your Message'></textarea>
                <button className='sub02-btn-contact'><NavLink to="/contacts">Submit</NavLink></button>
                </div>
            </div>
          </section>
      </>
  )
};

export default Contact02;
