import React from 'react'
import { NavLink } from 'react-router-dom'
import Footer from '../../../Footer/Footer'
import IMAGES from '../../../Images/'
import Navbar from '../../../Navbar/Navbar'

export const Iam_billing = () => {
  return (
    <>
    <Navbar/>
        <section className='template'>
            <div className='template-container container'>
            <h1>AWS Billing</h1>
            <h2>Create Budget</h2>
            <p>From the billing console, create budget</p>
            <div className='template-container60rem' >
            <img src={IMAGES.AWS87} alt=""/>     
            </div>            
            <p>Choose Budget type as cost budget.</p>     
            <img src={IMAGES.AWS88} alt="" /> 
            <p>Name your Budget and configure settings as per your need.</p>         
            <img src={IMAGES.AWS89} alt="" />          
            <img src={IMAGES.AWS90} alt="" />    
            <p>Set Alert</p>      
            <img src={IMAGES.AWS91} alt="" />          
            <img src={IMAGES.AWS92} alt="" /> 
            <p>Click on budget to create your budget</p>         
            <img src={IMAGES.AWS93} alt="" />  
            <p>Your budget is created as shown below</p>       
            <img src={IMAGES.AWS94} alt="" />          
            </div>
            <div className='template-buttons container'>

  <NavLink to="/iam_sdk"><button className='template-back'>Back</button></NavLink>
  <NavLink to="/aws"><button className='template-next'>Home</button></NavLink>            
   
   
            </div>
       </section>
       <Footer/>
    </>
    
  )
}
