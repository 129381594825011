import React from 'react'
import { NavLink } from 'react-router-dom'


export const Vm_block = () => {
  return (
    <>
    
        <section className='template'>
            <div className='template-container template-block container'>
            <h3>Azure Virtual Machine</h3>
            <ul>
            <li><NavLink to="/vm_setup">Virtual Machine Setup</NavLink></li> 
            <li><NavLink to="/vm_addisk">Adding Disk</NavLink></li>   
            <li><NavLink to="/vm_cs">Custom Script</NavLink></li>   
            <li><NavLink to="/vm_ci">Capture Image</NavLink></li>   
            <li><NavLink to="/vm_ip">Ip addressing</NavLink></li>   
            <li><NavLink to="/vm_snapshot">Snapshot</NavLink></li>   
            <li><NavLink to="/vm_proximity">Proximity Placement Group</NavLink></li>   
            <li><NavLink to="/vm_ud">Unmanaged Disk</NavLink></li>   
            <li><NavLink to="/vm_bootd">Boot Diagnostics & Serial Console</NavLink></li>   
            <li><NavLink to="/vm_diske">Azure Disk Encryptions</NavLink></li>   
            {/* <li><NavLink to="/">Azure Operations</NavLink></li>    */}
            </ul>           
            
            </div>                                  
       </section>
    
    </>
    
  )
}
