import React from 'react'
import '../01/Social.css'
import {BsFacebook} from 'react-icons/bs'
import {FcGoogle} from 'react-icons/fc'
import {BsLinkedin} from 'react-icons/bs'
import {BsMessenger} from 'react-icons/bs'

const Social = () => {
  return (
    <>
      <section className='social01'>
        <div className='social01-container container'>
        <div>
          
        </div>
          <div className='social01-buttons'>
          <h1>Social Media Buttons</h1>
          <button className='social01-facebook'><BsFacebook/> Continue with Facebook</button>
          <button className='social01-google'><FcGoogle/> Sign in with Google</button>
          <button className='social01-linkedin'><BsLinkedin/> Sign in with Linkedin</button>
          <button className='social01-messenger'><BsMessenger/> Send to Messenger</button>

          </div>
        </div>
      </section>
    </>
    
    
  )
}

export default Social