import React from 'react'
import {FaTwitter} from 'react-icons/fa'
import {FaFacebook} from 'react-icons/fa'
import {FaYoutube} from 'react-icons/fa'
import {FaWhatsapp} from 'react-icons/fa'
import {FaInstagram} from 'react-icons/fa'
import '../06/Hero06.css'

const Hero06 = () => {
  return (
   <>
   <div className='hero06-heading'>
    <h1>With Social Icons</h1>
   </div>
       <section className='hero06'>
        <div className='hero06-container'>
        <div className="hero06-center">
            <h1>Welcome to the travel website</h1>
            <h2>Book your flights, before its too late</h2>
 <a href="" className="explore_button">Explore Flights</a>
        </div>

        <div className="hero06-social">
            <div className="hero06-icons">
            <a href="">
                <i><FaFacebook/></i>
            </a>
        </div>
        <div className="hero06-icons">
            <a href="">
                <i><FaTwitter/></i>
            </a>
        </div>
        <div className="hero06-icons">
            <a href="">
                <i><FaYoutube/></i>
            </a>
        </div>
        <div className="hero06-icons">
            <a href="">
                <i><FaInstagram/></i>
            </a>
        </div>
        <div className="hero06-icons">
            <a href="">
                <i><FaWhatsapp/></i>
            </a>
        </div>
        </div>
        </div>
       
       </section>
   </>
  )
}

export default Hero06