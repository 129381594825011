import React from 'react'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'
import FooterCompIndex from '../subComponents/footerComp/01/FooterCompIndex'
import WithLogoIndex from '../subComponents/footerComp/03/WithLogoIndex'
import CenterIndex from '../subComponents/footerComp/04/CenterIndex'
import FooterInput from '../subComponents/footerComp/06/FooterInput'



const Footer_ = () => {
  return (
    <>
         <Navbar/>   
         <FooterCompIndex/>
         <WithLogoIndex/>
         <CenterIndex/>
         <FooterInput/>
         
         <Footer/>
    </>
  )
}

export default Footer_