import React from 'react'
import '../05/Auth05.css'
import software from '../05/software.jpg'

const Auth05 = () => {
  return (
    <>
        
        <section className='auth05'>
        
       
            <div className='auth05-heading'>
            <div className='auth05-h1'>
        <h1>Split Screen with Image</h1>
        </div>
                <h1>Sign in to your account</h1>
                <p>to enjoy all of our cool <a href="">features </a>👌 </p>                
            </div>
            <div className='auth05-container container'>
 <div className='auth05-form'>
 
                <div className='auth05-input'>
                <h1>Sign in to your account</h1>
            <p className='auth05-p'>Email Address</p>
            <input className='auth05-x' type="Email"/>
            <p className='auth05-p'>Password</p>
            <input className='auth05-x' type="Password" name="" id="" />
            <div className='auth05-checkbox'>
            <input type="checkbox" name="" id="" />
            <p>Remeber me</p>
            <a href="">Forgot password ?</a>
            </div>
            <button className='auth05-btn'>
            Sign in
            </button>         

            </div>
                </div>
                <div className='auth05-image'>
                <img src={software} alt="" />
                </div>
            </div>
           
        </section>
    </>
  )
}

export default Auth05