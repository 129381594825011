import React from 'react'
import Footer from '../../../Footer/Footer'
import IMAGES from '../../../Images/'
import Navbar from '../../../Navbar/Navbar'

export const Con_overview = () => {
  return (
    <>
    <Navbar/>
        <section className='template'>
            <div className='template-container container'>
            <h1>High Availability & Scalibility</h1>
            <h2>SubHeading</h2>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque aperiam eos tempore ab aliquam veniam impedit, repellendus voluptatum aspernatur iure amet aliquid aut quos, ipsa sed inventore? Neque, magnam sit.</p>
            <img src={IMAGES.AWS19} alt="" />          
            </div>
            <div className='template-buttons container'>
            {/* <a href="https://ammar.tech/"><button className='template-back'>Back</button></a>
            <a href="https://ammar.tech/"><button className='template-next'>Next</button></a> */}
            </div>
       </section>
       <Footer/>
    </>
    
  )
}
