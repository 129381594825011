import React from 'react'
import { NavLink } from 'react-router-dom'
import Footer from '../../../Footer/Footer'
import IMAGES from '../../../Images/'
import Navbar from '../../../Navbar/Navbar'

export const Iam_metaData = () => {
  return (
    <>
    <Navbar/>
        <section className='template'>
            <div className='template-container container'>
            <h1>IAM Meta Data</h1>          
            
            <img src={IMAGES.AWS79} alt="" />  
            <p>It shows us the list of api call versions</p>        
            <img src={IMAGES.AWS80} alt="" />          
            <img src={IMAGES.AWS81} alt="" />          
            <img src={IMAGES.AWS82} alt="" />
            <p>Anytime there is a / that means there is more to it</p>   
            <p>For example public-keys/</p>       
            <img src={IMAGES.AWS83} alt="" />          
            <img src={IMAGES.AWS84} alt="" />    
            <p>Behind the scenes Ec2 instance look for all those api queries</p>

            </div>
            <div className='template-buttons container'>

    <NavLink to="/iam_sectools"><button className='template-back'>Back</button></NavLink>
    <NavLink to="/iam_sdk"><button className='template-next'>Next</button></NavLink>            
            </div>
       </section>
       <Footer/>
    </>
    
  )
}
