import React from 'react'
import Footer from '../../../Footer/Footer'
import IMAGES from '../../../Images/'
import Navbar from '../../../Navbar/Navbar'
import { NavLink } from 'react-router-dom'

export const Elb_info = () => {
  return (
    <>
    <Navbar/>
        <section className='template'>
            <div className='template-container container'>
            <h1>Elastic Load Balancer</h1>
            <img src={IMAGES.AWS236} alt="" />          
            <img src={IMAGES.AWS237} alt="" />          
            <img src={IMAGES.AWS238} alt="" />          
            <img src={IMAGES.AWS239} alt="" />          
            <img src={IMAGES.AWS240} alt="" />          
            <img src={IMAGES.AWS241} alt="" /> 
            <h2>Sticky Session</h2>         
            <img src={IMAGES.AWS242} alt="" />          
            <img src={IMAGES.AWS243} alt="" /> 
            <h2>Cross Zone Load Balancing</h2>
            <img src={IMAGES.AWS244} alt="" />   
            <div className='template-container80rem' >
            <img src={IMAGES.AWS245} alt="" />  
            </div>                   
            <h2>Default Settings</h2>        
            <p>CLB disabled by default</p>
            <img src={IMAGES.AWS246} alt="" />  
            <div className='template-container80rem' >
            <img src={IMAGES.AWS247} alt="" />  
            </div>         
            <p>ALB, always enabled by default, so there is no setting to change</p>
            <img src={IMAGES.AWS248} alt="" />         
            <p>NLB disabled by default</p> 
            <img src={IMAGES.AWS249} alt="" />          
            <img src={IMAGES.AWS250} alt="" />   
            <p>there is a cost if you enable it</p>       
            <img src={IMAGES.AWS251} alt="" />       
            <h2>Connection Draining</h2>   
            <img src={IMAGES.AWS252} alt="" />          
            </div>
            <div className='template-buttons container'>
            <NavLink to="/elb_overview"><button className='template-back'>Back</button></NavLink>
            <NavLink to="/clb_info"><button className='template-next'>Next</button></NavLink>
            </div>
       </section>
       <Footer/>
    </>
    
  )
}
