import React from 'react'
import { NavLink } from 'react-router-dom'
import './AwsSolutions.css'

const AwsSolutions = ({projectName, projectImage, View}) => {
  return (
    <>
        
                <div className='aws-solutions-items'>
                <div className='aws-solutions-image'>
                <img src={projectImage} alt="" />
                </div>
                    
                    <h1>{projectName}</h1>
                    <div className='btn-cards'>
                    
                    <NavLink to={View}><button className='btn-alpha'>View</button></NavLink>

                    {/* <a href={View}><button className='btn-alpha'>View</button></a>                    */}
                 
                    </div>
                    
                </div>
            
    </>
  )
}

export default AwsSolutions