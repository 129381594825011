import React from 'react'

import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import Contact01 from '../subComponents/Contacts/01/Contact01'
import Contact02 from '../subComponents/Contacts/02/Contact02'
import Contact03 from '../subComponents/Contacts/03/Contact03'
import Contact04 from '../subComponents/Contacts/04/Contact04'
import Contact05 from '../subComponents/Contacts/05/Contact05'
import Contact06 from '../subComponents/Contacts/06/Contact06'

const Contact_ = () => {
  return (
   <>
     <Navbar/>
        <Contact01/>     
        <Contact02/>
        <Contact03/>
        <Contact04/>
        <Contact05/>
        <Contact06/>
      <Footer/>
   </>
  )
}

export default Contact_