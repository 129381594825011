import React from 'react'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'
import Pdetails from '../subComponents/ProductDetails/Pdetails'



const Pricing_ = () => {
  return (
    <>
         <Navbar/>   
         <Pdetails/>
         <Footer/>
    </>
  )
}

export default Pricing_