export const Pricedata = [
    {
        id:'0',
        price_heading : 'Hobby',
        price_package:'$79 /month',
        price_item1:'Unlimited Build Minutes',
        price_item2:'5tb Bandwidth',
        price_item3:'Lightning Fast Speed',
        price_item4:'7tb Bandwidth',
        price_item5:'10 Lorem, ipsum dolor.',
    },
    {
        id:'1',
        price_heading : 'Growth',
        price_package:'$149 /month',
        price_item1:'Unlimited Build Minutes',
        price_item2:'5tb Bandwidth',
        price_item3:'Lightning Fast Speed',
        price_item4:'7tb Bandwidth',
        price_item5:'10 Lorem, ipsum dolor.',
    },
    {
        id:'2',
        price_heading : 'Scale',
        price_package:'$349 /month',
        price_item1:'Unlimited Build Minutes',
        price_item2:'5tb Bandwidth',
        price_item3:'Lightning Fast Speed',
        price_item4:'7tb Bandwidth',
        price_item5:'10 Lorem, ipsum dolor.',
    },

]