import React from 'react'
import Testi01 from './Testi01'
import { Testi01Data } from './Testi01Data'

const Testi01index = () => {
  return (
    <>
    <section className='testi01'>
        <div className='testi01-heading'>
        <h1>What our Customers Say</h1>
        </div>
        <div className='testi01-container container'>
        {
        Testi01Data.map((items)=>(
        <Testi01
        testi01Para={items.testi01Para}
        testi01Image={items.testi01Image}
        testi01Name={items.testi01Name}
        key={items.id}
    />
))
        }
        </div>
       </section>
    </>
  )
}

export default Testi01index