import React from 'react'
import Footer from '../../../Footer/Footer'
import IMAGES from '../../../Images/'
import Navbar from '../../../Navbar/Navbar'
import { NavLink } from 'react-router-dom'

export const Vm_ci = () => {
  return (
    <>
    <Navbar/>
        <section className='template'>
            <div className='template-container container'>
            <h1>Capture Image</h1>  
            
            <img src={IMAGES.AWS389} alt="" />   
            <h2>Setting VM</h2>       
            <p>Start your VM</p>
            <img src={IMAGES.AWS390} alt="" /> 
            <h2>Installing Roles</h2>        
            <p>Install webserver and print server.</p>
            <img src={IMAGES.AWS391} alt="" />  
            <p>Create another user.</p>        
            <img src={IMAGES.AWS392} alt="" />   
            <p>Create a new folder / file.</p>       
            <img src={IMAGES.AWS393} alt="" /> 
            <h2>Run Sysprep</h2>
            <p>open cmd and type sysprep, a new window will open, press ok to start.</p>
            <img src={IMAGES.AWS394} alt="" />     
            <p>Sysprep is working.</p>     
            <img src={IMAGES.AWS395} alt="" /> 
            <p>After the sysprep, it will automatically shutdown, you can see the status in Azure portal, where it says stopped.</p>         
            <img src={IMAGES.AWS396} alt="" /> 
            <h2>Capture Image</h2>   
            <p>Click on capture button to start.</p>      
            <img src={IMAGES.AWS397} alt="" />  
            <p>Create an image dialouge box will open, use the below settings.</p>        
            <img src={IMAGES.AWS398} alt="" /> 
            <p>Enter Tags.</p>    
            <div className='template-container80rem' >
            <img src={IMAGES.AWS399} alt="" />  
            </div>                   
            <p>Review + create.</p>  
            <div className='template-container80rem' >
            <img src={IMAGES.AWS400} alt="" /> 
            </div>                  
            <p>It will now de-allocating (stopping) virtual machine.</p> 
            <div className='template-container80rem' >
            <img src={IMAGES.AWS401} alt="" />          
            </div>    
            <p>Deployment is in progress.</p>                      
            <img src={IMAGES.AWS402} alt="" />  
            <p>Your deployment is complete.</p>        
            <img src={IMAGES.AWS403} alt="" />    
            <div className='template-container80rem' >
            <img src={IMAGES.AWS404} alt="" />          
            </div>                      
            <p>Note under Virtual machine we can only see 1 VM which was previously created, and server 2019 is not showing.</p>         
            <img src={IMAGES.AWS405} alt="" />  
            <h2>Create VM from Captured Image</h2>   
            <p>Our captured image will show under All services - Images</p>        
            <img src={IMAGES.AWS406} alt="" />          
            <p>Click on your image, here we can see an option for create VM, now from this image, we can create multiple VMs.</p>
            <img src={IMAGES.AWS407} alt="" /> 
            <p>Creating VM process is same as before.</p>         
            <img src={IMAGES.AWS408} alt="" />          
            <img src={IMAGES.AWS409} alt="" />          
            <img src={IMAGES.AWS410} alt="" />          
            <img src={IMAGES.AWS411} alt="" />          
            <img src={IMAGES.AWS412} alt="" />          
            <img src={IMAGES.AWS413} alt="" />          
            <p>Your deployment is complete.</p>
            <img src={IMAGES.AWS414} alt="" />   
            <p>Here we can see our Vm with apps, created from image.</p>
            <img src={IMAGES.AWS415} alt="" />      
            <p>if you see under virtual machines, its source is image.</p>    
            <img src={IMAGES.AWS416} alt="" />   
            <h2>Testing</h2>
            <p>Login via Remote desktop</p>       
            <img src={IMAGES.AWS417} alt="" />  
            <p>We can verify our data is present.</p>        
            <img src={IMAGES.AWS418} alt="" />   
            <p>Our servers are present.</p>       
            <img src={IMAGES.AWS419} alt="" />  
            <p>And the user we created is also there.</p>        
            <img src={IMAGES.AWS420} alt="" />          
            </div>
            <div className='template-buttons container'>
            <NavLink to="/vm_cs"><button className='template-back'>Back</button></NavLink>
            <NavLink to="/vm_ip"><button className='template-next'>Next</button></NavLink>
            </div>
       </section>
       <Footer/>
    </>
    
  )
}
