import React from 'react'
import Footer from '../../../Footer/Footer'
import IMAGES from '../../../Images/'
import Navbar from '../../../Navbar/Navbar'

export const AccessKeys = () => {
  return (
    <>
    <Navbar/>
        <section className='template'>
            <div className='template-container container'>
            <h1>Access Keys & Passowrd Policy</h1>
            <h2>Password Policy</h2>
            <img src={IMAGES.AWS28} alt="" />          
            <img src={IMAGES.AWS29} alt="" />          
            <img src={IMAGES.AWS30} alt="" />          
            <img src={IMAGES.AWS31} alt="" />          
            <img src={IMAGES.AWS32} alt="" />   
            <h2>MFA - Root Account</h2>       
            <img src={IMAGES.AWS33} alt="" /> 
            <p>here you can setup MFA, for this, im not going to setup, as this is just the lab environment
            </p>      
            <h2>Access Keys</h2>   
            <p>User access - different Methods</p>
            <div className='template-container60rem' >
            <img src={IMAGES.AWS34} alt="" />          
            </div>                        
            <img src={IMAGES.AWS35} alt="" />        
            
            <img src={IMAGES.AWS37} alt="" />   
            <h2>Installing AWS Cli</h2>      
            <img src={IMAGES.AWS36} alt="" />  
            <p>https://docs.aws.amazon.com/cli/latest/userguide/getting-started-install.html
            </p>  
                   
            <img src={IMAGES.AWS38} alt="" />          
            <img src={IMAGES.AWS39} alt="" />    
            <h2>Setting Access Keys</h2>
            <p>if you don't have access key already setup, then use the button create access keys</p>
            <p>secondly use IAM account to create access keys, don’t use root account</p>
                        
            <img src={IMAGES.AWS40} alt="" />    
            <p>we will be using these in cli</p>      
            <img src={IMAGES.AWS41} alt="" />   
            <h2>Configure AWS Cli</h2>       
            <img src={IMAGES.AWS42} alt="" />   
            <p>aws iam list-users to list users</p>      
            <img src={IMAGES.AWS43} alt="" />      
            <img src={IMAGES.AWS44} alt="" />     
            <h2>AWS Cloud Shell</h2>   
            <p>Cloud shell is simply your cli in cloud instead of windows</p> 
            
            <p>you can create files in cloud shell and then you can download them via control panel</p>     
            <img src={IMAGES.AWS45} alt="" />          
            <img src={IMAGES.AWS46} alt="" />          
            <img src={IMAGES.AWS47} alt="" />          
            
            </div>
            <div className='template-buttons container'>
            <a href="https://ammar.tech/signinurl"><button className='template-back'>Back</button></a>
            <a href="https://ammar.tech/iam_roles"><button className='template-next'>Next</button></a>
            </div>
       </section>
       <Footer/>
    </>
    
  )
}
