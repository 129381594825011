import React from 'react'
import { NavLink } from 'react-router-dom'
import './LogoCenter.css'

const LogoCenter = ({cheading,item1, item2, item3, item4,}) => {
  return (
    <>
        
            <div className='clogo-items'>
            <div className='clogo-heading'>
                <h1>{cheading}</h1>                
                </div>                             
                <div className='clogo-list'>
                    <ul>
    <NavLink to="">
    <li>{item1}</li>
    </NavLink>
    <NavLink to="">
    <li>{item2}</li>
    </NavLink>
    <NavLink to="">
    <li>{item3}</li>
    </NavLink>
    <NavLink to="">
    <li>{item4}</li>
    </NavLink>      
    </ul>                             
    
    
</div>


</div>


</>
)
}

export default LogoCenter




    