import React from 'react'
import '../01/News01.css'
import {MdUnsubscribe} from 'react-icons/md'

const News01 = () => {
  return (
    <>
        <section className='news01'>
            <div className='news01-container container'>
                <div className='news01-part01'>
                    <MdUnsubscribe className='news01-icon'/>
                    <h1>Subscribe</h1>
                    <p>Subscribe to our newsletter & stay up to date</p>
                </div>
                <div className='news01-part02'>
                    <input className='news01-input' type="text" placeholder='Enter your Email' />
                    <button className='btn-news01'>Subscribe</button>
                </div>
            </div>
        </section>
    </>
  )
}

export default News01